// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Mutable, Class, Equals} from "@swim/util";
import {Model, Trait} from "@swim/model";
import type {Geographic} from "../geographic/Geographic";
import type {LocationTraitObserver} from "./LocationTraitObserver";

/** @public */
export class LocationTrait extends Trait {
  constructor() {
    super();
    this.minZoom = -Infinity;
    this.maxZoom = Infinity;
    this.geographic = null;
  }

  override readonly observerType!: Class<LocationTraitObserver>;

  readonly minZoom: number;

  readonly maxZoom: number;

  setZoomRange(minZoom: number, maxZoom: number): void {
    if (this.minZoom !== minZoom || this.maxZoom !== maxZoom) {
      this.willSetZoomRange(minZoom, maxZoom);
      (this as Mutable<this>).minZoom = minZoom;
      (this as Mutable<this>).maxZoom = maxZoom;
      this.onSetZoomRange(minZoom, maxZoom);
      this.didSetZoomRange(minZoom, maxZoom);
    }
  }

  protected willSetZoomRange(minZoom: number, maxZoom: number): void {
    this.callObservers("locationWillSetZoomRange", minZoom, maxZoom, this);
  }

  protected onSetZoomRange(minZoom: number, maxZoom: number): void {
    if (this.model !== null) {
      this.requireUpdate(Model.NeedsValidate);
    }
  }

  protected didSetZoomRange(minZoom: number, maxZoom: number): void {
    this.callObservers("locationDidSetZoomRange", minZoom, maxZoom, this);
  }

  readonly geographic: Geographic | null;

  setGeographic(newGeographic: Geographic | null): void {
    const oldGeographic = this.geographic;
    if (!Equals(newGeographic, oldGeographic)) {
      this.willSetGeographic(newGeographic, oldGeographic);
      (this as Mutable<this>).geographic = newGeographic;
      this.onSetGeographic(newGeographic, oldGeographic);
      this.didSetGeographic(newGeographic, oldGeographic);
    }
  }

  protected willSetGeographic(newGeographic: Geographic | null, oldGeographic: Geographic | null): void {
    this.callObservers("locationWillSetGeographic", newGeographic, oldGeographic, this);
  }

  protected onSetGeographic(newGeographic: Geographic | null, oldGeographic: Geographic | null): void {
    if (this.model !== null) {
      this.requireUpdate(Model.NeedsValidate);
    }
  }

  protected didSetGeographic(newGeographic: Geographic | null, oldGeographic: Geographic | null): void {
    this.callObservers("locationDidSetGeographic", newGeographic, oldGeographic, this);
  }
}
