// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {ModelContextType, ModelFlags, Model} from "@swim/model";
import {StatusVector} from "./StatusVector";
import {StatusTrait} from "./StatusTrait";

/** @public */
export class StatusGroup extends Model {
  /** @internal */
  protected aggregateStatus(statusVector: StatusVector): void {
    // hook
  }

  protected override needsAnalyze(analyzeFlags: ModelFlags, modelContext: ModelContextType<this>): ModelFlags {
    if ((this.flags & Model.NeedsAggregate) === 0) {
      analyzeFlags &= ~Model.NeedsAggregate;
    }
    return analyzeFlags;
  }

  protected override analyzeChildren(analyzeFlags: ModelFlags, modelContext: ModelContextType<this>,
                                     analyzeChild: (this: this, child: Model, analyzeFlags: ModelFlags,
                                                    modelContext: ModelContextType<this>) => void): void {
    if ((analyzeFlags & Model.NeedsAggregate) !== 0) {
      this.aggregateChildStatuses(analyzeFlags, modelContext, analyzeChild);
    } else {
      super.analyzeChildren(analyzeFlags, modelContext, analyzeChild);
    }
  }

  protected aggregateChildStatuses(analyzeFlags: ModelFlags, modelContext: ModelContextType<this>,
                                   analyzeChild: (this: this, child: Model, analyzeFlags: ModelFlags,
                                                  modelContext: ModelContextType<this>) => void): void {
    // Accumulate status vectors when aggregating child models.
    let statusVector = StatusVector.empty();
    let statusCount = 0;

    type self = this;
    function aggregateChildStatus(this: self, child: Model, analyzeFlags: ModelFlags,
                                  modelContext: ModelContextType<self>): void {
      analyzeChild.call(this, child, analyzeFlags, modelContext);
      const childStatusTrait = child.getTrait(StatusTrait);
      if (childStatusTrait !== null) {
        const childStatus = childStatusTrait.statusVector;
        if (childStatus.isDefined()) {
          statusVector = statusVector.plus(childStatus);
          statusCount += 1;
        }
      }
    }
    super.analyzeChildren(analyzeFlags, modelContext, aggregateChildStatus);

    if (statusCount !== 0) {
      statusVector = statusVector.times(1 / statusCount);
    }
    this.aggregateStatus(statusVector);
  }

  static override MountFlags: ModelFlags = Model.MountFlags | Model.NeedsAggregate;
  static override InsertChildFlags: ModelFlags = Model.InsertChildFlags | Model.NeedsAggregate;
  static override RemoveChildFlags: ModelFlags = Model.RemoveChildFlags | Model.NeedsAggregate;
}
