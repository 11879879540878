// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Uri} from "@swim/uri";
import {Model, ModelRef, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {MetaMeshEntity} from "./MetaMeshEntity";
import {MetaEdgeEntity} from "./MetaEdgeEntity";

/** @public */
export class FabricGroup extends EntityGroup {
  constructor() {
    super();
    this.isSorted(false);
    this.initGroup();
  }

  protected initGroup(): void {
    this.mesh.insertModel();
    this.edge.insertModel();
  }

  @ModelRef<FabricGroup, Model>({
    key: true,
    type: Model,
    binds: true,
    createModel(): Model {
      const metaMeshUri = Uri.parse("swim:meta:mesh");
      const meshModel = new Model();

      const entityTrait = new MetaMeshEntity(metaMeshUri);
      entityTrait.title.setValue("Partitions");
      meshModel.setTrait("entity", entityTrait);

      meshModel.setTrait("selectable", new SelectableTrait());
      meshModel.setTrait("status", new StatusTrait());
      meshModel.setTrait("indicated", new IndicatedTrait());
      meshModel.setTrait("pulse", new PulseTrait(metaMeshUri));

      return meshModel;
    },
  })
  readonly mesh!: ModelRef<this, Model>;

  @ModelRef<FabricGroup, Model>({
    key: true,
    type: Model,
    binds: true,
    createModel(): Model {
      const metaEdgeUri = Uri.parse("swim:meta:edge");
      const edgeModel = new Model();

      const entityTrait = new MetaEdgeEntity(metaEdgeUri);
      entityTrait.title.setValue("Connections");
      edgeModel.setTrait("entity", entityTrait);

      edgeModel.setTrait("selectable", new SelectableTrait());
      edgeModel.setTrait("status", new StatusTrait());
      edgeModel.setTrait("indicated", new IndicatedTrait());
      edgeModel.setTrait("pulse", new PulseTrait(metaEdgeUri));

      return edgeModel;
    },
  })
  readonly edge!: ModelRef<this, Model>;
}
