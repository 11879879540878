// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {MemberFastenerClass} from "@swim/component";
import {Trait, TraitRef} from "@swim/model";
import {Feel} from "@swim/theme";
import {TraitViewRef} from "@swim/controller";
import {IconCellView, IconCellTrait, IconCellController} from "@swim/table";
import {Status, StatusVector, StatusTrait} from "@swim/domain";

/** @public */
export class TableGadgetIconCellController extends IconCellController {
  @TraitViewRef<TableGadgetIconCellController, IconCellTrait, IconCellView>({
    extends: true,
    didAttachTrait(cellTrait: IconCellTrait, targetTrait: Trait | null): void {
      this.owner.status.setTrait(cellTrait.getTrait(StatusTrait));
      IconCellController.cell.prototype.didAttachTrait.call(this, cellTrait, targetTrait);
    },
    willDetachTrait(cellTrait: IconCellTrait): void {
      IconCellController.cell.prototype.willDetachTrait.call(this, cellTrait);
      this.owner.status.setTrait(null);
    },
    initView(cellView: IconCellView): void {
      IconCellController.cell.prototype.initView.call(this, cellView);
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        cellView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, cellView);
      }
    },
  })
  override readonly cell!: TraitViewRef<this, IconCellTrait, IconCellView>;
  static override readonly cell: MemberFastenerClass<TableGadgetIconCellController, "cell">;

  @TraitRef<TableGadgetIconCellController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      const cellView = this.owner.cell.view;
      if (cellView !== null) {
        cellView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, cellView);
      }
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      const cellView = this.owner.cell.view;
      if (cellView !== null) {
        this.owner.applyStatusVector(newStatusVector, cellView);
      }
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;
  static readonly status: MemberFastenerClass<TableGadgetIconCellController, "status">;

  protected applyStatusVector(statusVector: StatusVector, cellView: IconCellView): void {
    const alert = statusVector.get(Status.alert) || 0;
    const warning = statusVector.get(Status.warning) || 0;
    const inactive = statusVector.get(Status.inactive) || 0;
    if (alert !== 0 && warning !== 0) {
      cellView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
    } else if (alert !== 0) {
      cellView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
    } else if (warning !== 0) {
      cellView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
    } else {
      cellView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
    }
    if (inactive !== 0) {
      cellView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
    } else {
      cellView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
    }
  }
}
