// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Affinity} from "@swim/component";
import {
  SelectionOptions,
  SelectionService,
  SelectionServiceObserver,
  Model,
  ModelRef,
  Trait,
  TraitRef,
} from "@swim/model";
import {Feel} from "@swim/theme";
import {View, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Graphics, VectorIcon, HtmlIconView} from "@swim/graphics";
import {Controller, ControllerRef} from "@swim/controller";
import {DrawerView} from "@swim/window";
import {Status, StatusVector, StatusTrait, EntityTrait, SessionModel} from "@swim/domain";
import {RefractorController} from "../refractor/RefractorController";
import {MagnifierHandle} from "./MagnifierHandle";

/** @public */
export class MagnifierController extends Controller {
  constructor() {
    super();
    this.onClickCollapse = this.onClickCollapse.bind(this);
    this.refractor.insertController();
  }

  @ViewRef<MagnifierController, DrawerView>({
    type: DrawerView,
    willAttachView(panelView: DrawerView): void {
      this.owner.handle.insertView(panelView);
      this.owner.fixtures.insertView(panelView);
    },
    didDetachView(panelView: DrawerView): void {
      panelView.removeChildren();
    },
  })
  readonly panel!: ViewRef<this, DrawerView>;

  @ViewRef<MagnifierController, MagnifierHandle>({
    type: MagnifierHandle,
    initView(handleView: MagnifierHandle): void {
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        this.owner.applyStatus(statusTrait.statusVector);
      } else {
        this.owner.applyStatus(StatusVector.empty());
      }
    },
    createView(): MagnifierHandle {
      const handleView = MagnifierHandle.create();
      handleView.flexShrink.setState(0, Affinity.Intrinsic);

      const collapseButton = HtmlIconView.create();
      collapseButton.graphics.setState(MagnifierController.collapseIcon, Affinity.Intrinsic);
      collapseButton.cursor.setState("pointer", Affinity.Intrinsic);
      collapseButton.on("click", this.owner.onClickCollapse);
      handleView.accessory.setView(collapseButton);
      handleView.accessory.insertView();

      handleView.modifyMood(Feel.default, [[Feel.primary, 1]]);

      return handleView;
    },
  })
  readonly handle!: ViewRef<this, MagnifierHandle>;

  @ViewRef<MagnifierController, HtmlView>({
    type: HtmlView,
    initView(fixturesView: HtmlView): void {
      const refractorController = this.owner.refractor.controller;
      if (refractorController !== null) {
        refractorController.fixtures.setView(fixturesView);
      }
    },
    createView(): HtmlView {
      const fixturesView = HtmlView.create();
      fixturesView.addClass("fixtures");
      fixturesView.position.setState("relative", Affinity.Intrinsic);
      fixturesView.overflowX.setState("hidden", Affinity.Intrinsic);
      fixturesView.overflowY.setState("auto", Affinity.Intrinsic);
      fixturesView.pointerEvents.setState("auto", Affinity.Intrinsic);
      fixturesView.on("scroll", function (event: Event): void {
        fixturesView.requireUpdate(View.NeedsScroll);
      });
      return fixturesView;
    },
  })
  readonly fixtures!: ViewRef<this, HtmlView>;

  @ControllerRef<MagnifierController, RefractorController>({
    key: true,
    type: RefractorController,
    binds: true,
    initController(refractorController: RefractorController): void {
      const fixturesView = this.owner.fixtures.view;
      if (fixturesView !== null) {
        refractorController.fixtures.setView(fixturesView);
      }
    },
  })
  readonly refractor!: ControllerRef<this, RefractorController>;

  @TraitRef<MagnifierController, EntityTrait>({
    type: EntityTrait,
    observes: true,
    didAttachTrait(entityTrait: EntityTrait): void {
      this.owner.status.setTrait(entityTrait.getTrait(StatusTrait));
      const handleView = this.owner.handle.view;
      if (handleView !== null) {
        handleView.label.setView(entityTrait.title.value);
        handleView.icon.setView(entityTrait.icon.value);
      }
      const refractorController = this.owner.refractor.controller;
      if (refractorController !== null) {
        refractorController.entity.setTrait(entityTrait);
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      const refractorController = this.owner.refractor.controller;
      if (refractorController !== null) {
        refractorController.entity.setTrait(null);
      }
      const handleView = this.owner.handle.view;
      if (handleView !== null) {
        handleView.label.setView(null);
        handleView.icon.setView(null);
      }
      this.owner.status.setTrait(null);
    },
    entityDidSetTitle(title: string | undefined): void {
      const handleView = this.owner.handle.view;
      if (handleView !== null) {
        handleView.label.setView(title);
      }
    },
    entityDidSetIcon(icon: Graphics | null): void {
      const handleView = this.owner.handle.view;
      if (handleView !== null) {
        handleView.icon.setView(icon);
      }
    },
    traitDidInsertTrait(memberTrait: Trait, targetTrait: Trait | null): void {
      if (memberTrait instanceof StatusTrait) {
        this.owner.status.setTrait(memberTrait);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait>;

  @TraitRef<MagnifierController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    didAttachTrait(statusTrait: StatusTrait): void {
      this.owner.applyStatus(statusTrait.statusVector);
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      this.owner.applyStatus(newStatusVector);
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;

  protected applyStatus(statusVector: StatusVector): void {
    const handleView = this.handle.view;
    if (handleView !== null) {
      const alert = statusVector.get(Status.alert) || 0;
      const warning = statusVector.get(Status.warning) || 0;
      const inactive = statusVector.get(Status.inactive) || 0;
      if (alert !== 0 && warning !== 0) {
        handleView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
      } else if (alert !== 0) {
        handleView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
      } else if (warning !== 0) {
        handleView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
      } else {
        handleView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
      }
      if (inactive !== 0) {
        handleView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
      } else {
        handleView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
      }
    }
  }

  @ModelRef<MagnifierController, SessionModel, SelectionServiceObserver>({
    implements: true,
    type: SessionModel,
    didAttachModel(sessionModel: SessionModel): void {
      const selectionService = sessionModel.selectionProvider.service;
      if (selectionService !== null) {
        selectionService.observe(this as SelectionServiceObserver);
      }
    },
    willDetachModel(sessionModel: SessionModel): void {
      const selectionService = sessionModel.selectionProvider.service;
      if (selectionService !== null) {
        selectionService.unobserve(this as SelectionServiceObserver);
      }
    },
    serviceDidSelect(model: Model, index: number, options: SelectionOptions | null): void {
      const entityTrait = model.getTrait(EntityTrait);
      if (entityTrait !== null) {
        this.owner.entity.setTrait(entityTrait);
        const panelView = this.owner.panel.view;
        if (panelView !== null) {
          panelView.present();
        }
      }
    },
    serviceWillUnselect(model: Model, selectionService: SelectionService): void {
      const selections = selectionService.selections;
      if (selections.length > 1) {
        this.owner.entity.setTrait(selections[selections.length - 1]!.getTrait(EntityTrait));
      } else {
        const panelView = this.owner.panel.view;
        if (panelView !== null) {
          panelView.dismiss();
        }
        this.owner.entity.setTrait(null);
        this.owner.applyStatus(StatusVector.empty());
      }
    },
  })
  readonly session!: ModelRef<this, SessionModel>;

  protected onClickCollapse(event: MouseEvent): void {
    event.stopPropagation();
    //const panelView = this.panel.view;
    //if (panelView !== null) {
    //  panelView.hide();
    //}
    const sessionModel = this.session.model;
    if (sessionModel !== null) {
      sessionModel.selectionProvider.unselectAll();
    }
  }

  @Lazy
  static get collapseIcon(): Graphics {
    return VectorIcon.create(24, 24, "M16.6,8.6L12,13.2L7.4,8.6L6,10L12,16L18,10Z");
  }
}
