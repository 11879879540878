// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Model, ModelRef, TraitRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {Controller, TraitControllerSet} from "@swim/controller";
import {GeoView} from "@swim/map";
import {EntityTrait, EntityGroup} from "@swim/domain";
import {AtlasEntityLocation} from "./AtlasEntityLocation";

/** @public */
export class AtlasEntityDistrict extends Controller {
  protected onInsertLocation(locationController: AtlasEntityLocation): void {
    const layerView = this.layer.view;
    if (layerView !== null) {
      locationController.layer.setView(layerView);
    }
  }

  @ViewRef<AtlasEntityDistrict, GeoView>({
    type: GeoView,
    initView(layerView: GeoView): void {
      let child = this.owner.firstChild;
      while (child !== null) {
        if (child instanceof AtlasEntityLocation) {
          this.owner.onInsertLocation(child);
        }
        child = child.nextSibling;
      }
    },
    createView(): GeoView {
      return GeoView.create();
    },
  })
  readonly layer!: ViewRef<this, GeoView>;

  @TraitControllerSet<AtlasEntityDistrict, EntityTrait, AtlasEntityLocation>({
    type: AtlasEntityLocation,
    binds: true,
    getTraitRef(locationController: AtlasEntityLocation): TraitRef<unknown, EntityTrait> {
      return locationController.entity;
    },
    didAttachController(locationController: AtlasEntityLocation): void {
      this.owner.onInsertLocation(locationController);
    },
    willDetachController(locationController: AtlasEntityLocation): void {
      locationController.geographic.deleteView();
    },
    createController(): AtlasEntityLocation {
      return new AtlasEntityLocation();
    },
  })
  readonly locations!: TraitControllerSet<this, EntityTrait, AtlasEntityLocation>;

  @ModelRef<AtlasEntityDistrict, EntityGroup>({
    type: EntityGroup,
    observes: true,
    initModel(entityGroup: EntityGroup): void {
      let entityModel = entityGroup.firstChild;
      while (entityModel !== null) {
        const entityTrait = entityModel.getTrait(EntityTrait);
        if (entityTrait !== null) {
          this.owner.locations.addTraitController(entityTrait);
        }
        entityModel = entityModel.nextSibling;
      }
    },
    modelDidInsertChild(child: Model, target: Model | null): void {
      const childEntityTrait = child.getTrait(EntityTrait);
      if (childEntityTrait !== null) {
        const targetEntityTrait = target !== null ? target.getTrait(EntityTrait) : null;
        this.owner.locations.addTraitController(childEntityTrait, targetEntityTrait);
      }
    },
    modelWillRemoveChild(child: Model): void {
      const childEntityTrait = child.getTrait(EntityTrait);
      if (childEntityTrait !== null) {
        this.owner.locations.deleteTraitController(childEntityTrait);
      }
    },
  })
  readonly entities!: ModelRef<this, EntityGroup>;

  protected override onUnmount(): void {
    super.onUnmount();
    this.layer.setView(null);
  }
}
