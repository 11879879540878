// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Values} from "@swim/util";
import {ModelContext, Model, ModelRef} from "@swim/model";
import {Feel} from "@swim/theme";
import {View, ViewRef} from "@swim/view";
import type {ViewNode} from "@swim/dom";
import {Controller, ControllerRef} from "@swim/controller";
import {ColLayout, TableLayout, RowView, ColView, HeaderView, TableView} from "@swim/table";
import {IndicatorType, EntityTrait, EntityGroup} from "@swim/domain";
import {CatalogEntityLimb} from "./CatalogEntityLimb";

/** @public */
export class CatalogEntityTree extends Controller {
  protected createLayout(indicatorTypes?: ReadonlyArray<IndicatorType>): TableLayout {
    const cols = new Array<ColLayout>();
    cols.push(ColLayout.create("icon", 0, 0, 64));
    cols.push(ColLayout.create("title", 1, 1, 200));
    if (indicatorTypes !== void 0) {
      for (let i = 0; i < indicatorTypes.length; i += 1) {
        const indicatorType = indicatorTypes[i]!;
        const indicatorRoot = this.createIndicatorRoot(indicatorType);
        if (indicatorRoot !== null) {
          cols.push(indicatorRoot);
        }
      }
    }
    cols.push(ColLayout.create("disclose", 0, 0, 40));
    return TableLayout.create(cols);
  }

  protected createIndicatorRoot(indicatorType: IndicatorType): ColLayout | null {
    return ColLayout.create(indicatorType.key, 0, 0, 100, true);
  }

  protected updateHeader(headerView: HeaderView, indicatorTypes?: ReadonlyArray<IndicatorType>): void {
    let nameColView = headerView.getChild("title") as ColView | null;
    if (nameColView === null) {
      nameColView = this.createNameColView();
      if (nameColView !== null) {
        headerView.appendChild(nameColView, "title");
      }
    }

    if (indicatorTypes !== void 0) {
      for (let i = 0; i < indicatorTypes.length; i += 1) {
        const indicatorType = indicatorTypes[i]!;
        let indicatorColView = headerView.getChild(indicatorType.key) as ColView | null;
        if (indicatorColView === null) {
          indicatorColView = this.createIndicatorColView(indicatorType);
          if (indicatorColView !== null) {
            headerView.appendChild(indicatorColView, indicatorType.key);
          }
        }
      }
    }
  }

  protected createNameColView(): ColView | null {
    return ColView.create().label("Name");
  }

  protected createIndicatorColView(indicatorType: IndicatorType): ColView | null {
    return ColView.create().label(indicatorType.name);
  }

  protected onInsertEntity(childEntity: EntityTrait, targetEntity: EntityTrait | null): void {
    const id = childEntity.uri.toString();
    let controllerRef = this.getFastener(id, ControllerRef) as ControllerRef<this, CatalogEntityLimb> | null;
    if (controllerRef === null) {
      controllerRef = CatalogEntityTree.BranchRef.create(this) as ControllerRef<this, CatalogEntityLimb>;
      Object.defineProperty(controllerRef, "key", {
        value: id,
        enumerable: true,
        configurable: true,
      })
      this.setFastener(id, controllerRef);
      const branchController = new CatalogEntityLimb();
      branchController.entity.setTrait(childEntity);
      controllerRef.setController(branchController);
      const targetController = targetEntity !== null ? this.getChild(targetEntity.uri.toString()) : null;
      this.insertChild(branchController, targetController, id);
    }
  }

  protected onRemoveEntity(childEntity: EntityTrait): void {
    const id = childEntity.uri.toString();
    const controllerRef = this.getFastener(id, ControllerRef) as ControllerRef<this, CatalogEntityLimb> | null;
    if (controllerRef !== null) {
      controllerRef.deleteController();
      this.setFastener(id, null);
    }
  }

  protected onInsertBranch(branchController: CatalogEntityLimb): void {
    const treeView = this.tree.view;
    if (treeView !== null) {
      const id = branchController.entity.trait!.uri.toString();
      let rowView: RowView | null;
      if (this.entities.model!.isSorted()) {
        let index = this.lookupBranch(treeView, id);
        if (index >= 0) {
          rowView = (treeView.node.childNodes[index] as ViewNode).view as RowView;
        } else {
          index = -(index + 1);
          rowView = branchController.row.createView();
          if (rowView !== null) {
            const targetNode = treeView.node.childNodes[index] as ViewNode | undefined;
            const targetView = targetNode !== void 0 ? targetNode.view : void 0;
            treeView.insertChild(rowView, targetView !== void 0 ? targetView : null, id);
          }
        }
        branchController.row.setView(rowView);
      } else {
        rowView = branchController.row.createView();
        if (rowView !== null) {
          const targetBranch = branchController.nextSibling;
          const targetView = targetBranch instanceof CatalogEntityLimb ? targetBranch.row.view : null;
          treeView.insertChild(rowView, targetView, id);
          branchController.row.setView(rowView);
        }
      }
    }
  }

  protected onRemoveBranch(branchController: CatalogEntityLimb): void {
    const rowView = branchController.row.view;
    if (rowView !== null) {
      rowView.remove();
    }
  }

  protected lookupBranch(treeView: TableView, key: unknown): number {
    const childNodes = treeView.node.childNodes;
    let lo = 0;
    let hi = childNodes.length - 1;
    while (lo <= hi && !((childNodes[lo] as ViewNode).view instanceof RowView)) {
      lo += 1;
    }
    while (hi >= lo && !((childNodes[hi] as ViewNode).view instanceof RowView)) {
      hi -= 1;
    }
    while (lo <= hi) {
      const mid = (lo + hi) >>> 1;
      const order = Values.compare(key, (childNodes[mid] as ViewNode).view!.key);
      if (order > 0) {
        lo = mid + 1;
      } else if (order < 0) {
        hi = mid - 1;
      } else {
        return mid;
      }
    }
    return -(lo + 1);
  }

  protected onReconcileEntities(entityGroup: EntityGroup): void {
    const treeView = this.tree.view;
    if (treeView !== null) {
      const indicatorTypes = entityGroup.indicatorMap.indicatorTypes;
      const oldLayout = treeView.layout.value;
      const newLayout = this.createLayout(indicatorTypes);
      if (oldLayout === null || !oldLayout.equivalentTo(newLayout)) {
        treeView.layout.setValue(newLayout);
        const headerView = treeView.header.view;
        if (headerView !== null) {
          this.updateHeader(headerView, indicatorTypes);
        }
        treeView.requireUpdate(View.NeedsResize | View.NeedsLayout);
      }
    }
  }

  @ViewRef<CatalogEntityTree, TableView>({
    key: true,
    type: TableView,
    initView(treeView: TableView): void {
      let child = this.owner.firstChild;
      while (child !== null) {
        if (child instanceof CatalogEntityLimb) {
          this.owner.onInsertBranch(child);
        }
        child = child.nextSibling;
      }

      const entityGroup = this.owner.entities.model;
      if (entityGroup !== null) {
        this.owner.onReconcileEntities(entityGroup);
      }
    },
    createView(): TableView {
      const treeView = TableView.create();
      treeView.modifyTheme(Feel.default, [[Feel.primary, 1]], false);
      treeView.rowHeight(58)
              .rowSpacing(2)
              .layout(this.owner.createLayout());

      const headerView = HeaderView.create();
      treeView.header.setView(headerView);
      this.owner.updateHeader(headerView);

      return treeView;
    },
  })
  readonly tree!: ViewRef<this, TableView>;

  static BranchRef = ControllerRef.define<CatalogEntityTree, CatalogEntityLimb>("BranchRef", {
    type: CatalogEntityLimb,
    didAttachController(branch: CatalogEntityLimb): void {
      this.owner.onInsertBranch(branch);
    },
    willDetachController(branch: CatalogEntityLimb): void {
      this.owner.onRemoveBranch(branch);
    },
  });

  @ModelRef<CatalogEntityTree, EntityGroup>({
    type: EntityGroup,
    observes: true,
    didAttachModel(entityGroup: EntityGroup): void {
      let entityModel = entityGroup.firstChild;
      while (entityModel !== null) {
        const entityTrait = entityModel.getTrait(EntityTrait);
        if (entityTrait !== null) {
          this.owner.onInsertEntity(entityTrait, null);
        }
        entityModel = entityModel.nextSibling;
      }
      this.owner.onReconcileEntities(entityGroup);
    },
    modelDidInsertChild(child: Model, target: Model | null): void {
      const childEntityTrait = child.getTrait(EntityTrait);
      if (childEntityTrait !== null) {
        const targetEntityTrait = target !== null ? target.getTrait(EntityTrait) : null;
        this.owner.onInsertEntity(childEntityTrait, targetEntityTrait);
      }
    },
    modelWillRemoveChild(child: Model): void {
      const childEntityTrait = child.getTrait(EntityTrait);
      if (childEntityTrait !== null) {
        this.owner.onRemoveEntity(childEntityTrait);
      }
    },
    modelDidReconcile(modelContext: ModelContext, entityGroup: EntityGroup): void {
      this.owner.onReconcileEntities(entityGroup);
    },
  })
  readonly entities!: ModelRef<this, EntityGroup>;

  /** @internal */
  protected override bindChildFasteners(child: Controller, target: Controller | null): void {
    // nop
  }

  /** @internal */
  protected override unbindChildFasteners(child: Controller): void {
    // nop
  }
}
