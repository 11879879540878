// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Timing} from "@swim/util";
import {Format} from "@swim/codec";
import {Affinity, Provider} from "@swim/component";
import {Length} from "@swim/math";
import {GeoPoint} from "@swim/geo";
import {Look, MoodVector, ThemeMatrix} from "@swim/theme";
import {ViewContext, View, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {HistoryState, HistoryService, HistoryProvider} from "@swim/controller";
import {MapboxView} from "@swim/mapbox";
import {SurfaceView} from "@swim/prism";
import {AtlasMap} from "./AtlasMap";

/** @public */
export class AtlasMapbox extends AtlasMap<MapboxView> {
  constructor() {
    super();
    this.logoControl = null;
    this.navigationControl = null;
    this.onMapLoad = this.onMapLoad.bind(this);
  }

  /** @internal */
  logoControl: HTMLElement | null;

  /** @internal */
  navigationControl: mapboxgl.NavigationControl | null;

  get darkMapStyle(): mapboxgl.Style | string {
    return "mapbox://styles/mapbox/dark-v10";
  }

  get lightMapStyle(): mapboxgl.Style | string {
    return "mapbox://styles/mapbox/light-v10";
  }

  get mapStyle(): mapboxgl.Style | string {
    const surfaceView = this.surface.view;
    if (surfaceView !== null) {
      const backgroundColor = surfaceView.getLook(Look.backgroundColor);
      if (backgroundColor !== void 0 && backgroundColor.lightness >= 0.5) {
        return this.lightMapStyle;
      }
    }
    return this.darkMapStyle;
  }

  protected onMapMove(mapCenter: GeoPoint, mapZoom: number, mapView: MapboxView): void {
    const lng = Format.decimal(mapCenter.lng, 5);
    const lat = Format.decimal(mapCenter.lat, 5);
    const zoom = Format.decimal(mapZoom, 5);
    this.historyProvider.replaceHistory({
      permanent: {lng, lat, zoom},
    });
    const storageService = this.storageProvider.service;
    storageService.set("atlas.lng", lng);
    storageService.set("atlas.lat", lat);
    storageService.set("atlas.zoom", zoom);
  }

  protected resizeSurface(surfaceView: SurfaceView, viewContext: ViewContext): void {
    const mapView = this.base.view;
    const map = mapView !== null ? mapView.map : null;
    if (map !== null) {
      map.resize();
    }
  }

  protected layoutSurface(surfaceView: SurfaceView, viewContext: ViewContext): void {
    const containerView = this.container.view;
    if (containerView !== null) {
      const edgeInsets = surfaceView.edgeInsets.value;
      let left: Length | number | null = surfaceView.paddingLeft.state;
      left = left instanceof Length ? left.pxValue() : 0;
      left =  Math.max(left, edgeInsets !== null ? edgeInsets.insetLeft : 0);
      let right: Length | number | null = surfaceView.paddingRight.state;
      right = right instanceof Length ? right.pxValue() : 0;
      right =  Math.max(right, edgeInsets !== null ? edgeInsets.insetRight : 0);
      const controlContainer = HtmlView.fromNode(containerView.node.querySelector(".mapboxgl-control-container") as HTMLDivElement);

      const topLeftControls = HtmlView.fromNode(controlContainer.node.querySelector(".mapboxgl-ctrl-top-left") as HTMLDivElement);
      topLeftControls.top(surfaceView.paddingTop.state);
      topLeftControls.left(left);

      const topRightControls = HtmlView.fromNode(controlContainer.node.querySelector(".mapboxgl-ctrl-top-right") as HTMLDivElement);
      topRightControls.top(surfaceView.paddingTop.state);
      topRightControls.right(right);

      const bottomLeftControls = HtmlView.fromNode(controlContainer.node.querySelector(".mapboxgl-ctrl-bottom-left") as HTMLDivElement);
      bottomLeftControls.bottom(surfaceView.paddingBottom.state);
      bottomLeftControls.left(left);

      const bottomRightControls = HtmlView.fromNode(controlContainer.node.querySelector(".mapboxgl-ctrl-bottom-right") as HTMLDivElement);
      bottomRightControls.bottom(surfaceView.paddingBottom.state);
      bottomRightControls.right(right);

      const logoControl = this.logoControl;
      if (logoControl !== null) {
        if (viewContext.viewportIdiom === "mobile") {
          logoControl.style.marginBottom = "";
          logoControl.style.marginLeft = "14px";
          if (logoControl.parentNode !== topLeftControls.node) {
            topLeftControls.appendChild(logoControl);
          }
        } else {
          logoControl.style.marginBottom = "15px";
          logoControl.style.marginLeft = "12px";
          if (logoControl.parentNode !== bottomLeftControls.node) {
            bottomLeftControls.appendChild(logoControl);
          }
        }
      }

      const navigationControl = this.navigationControl;
      const mapView = this.base.view;
      const map = mapView !== null ? mapView.map : null;
      if (navigationControl !== null && map !== null) {
        const navigationControlContainer = (navigationControl as any)._container as HTMLElement | undefined;
        if (viewContext.viewportIdiom === "mobile") {
          if (navigationControlContainer === void 0 || navigationControlContainer.parentNode !== topRightControls.node) {
            if (navigationControlContainer !== void 0 && navigationControlContainer.parentNode !== null) {
              map.removeControl(navigationControl);
            }
            map.addControl(navigationControl, "top-right");
          }
        } else {
          if (navigationControlContainer === void 0 || navigationControlContainer.parentNode !== bottomLeftControls.node) {
            if (navigationControlContainer !== void 0 && navigationControlContainer.parentNode !== null) {
              map.removeControl(navigationControl);
            }
            map.addControl(navigationControl, "bottom-left");
          }
        }
      }
    }
  }

  themeSurface(theme: ThemeMatrix, mood: MoodVector, timing: Timing | boolean, surfaceView: SurfaceView): void {
    const mapView = this.base.view;
    const map = mapView !== null ? mapView.map : null;
    if (map !== null) {
      map.setStyle(this.mapStyle);
    }
  }

  @ViewRef<AtlasMapbox, MapboxView>({
    observes: true,
    initView(mapView: MapboxView): void {
      mapView.container.setView(this.owner.container.view);
      const canvasView = mapView.canvas.view;
      if (canvasView !== null) {
        canvasView.mouseEventsEnabled(true)
                  .pointerEventsEnabled(true)
                  .touchEventsEnabled(true);
      }
    },
    createView(): MapboxView {
      const historyState = this.owner.historyProvider.historyState;
      let lng: string | number | undefined = historyState.permanent.lng;
      let lat: string | number | undefined = historyState.permanent.lat;
      let zoom: string | number | undefined = historyState.permanent.zoom;
      const storageService = this.owner.storageProvider.service;
      if (lng === void 0) {
        lng = storageService.get("atlas.lng");
      }
      if (lat === void 0) {
        lat = storageService.get("atlas.lat");
      }
      if (zoom === void 0) {
        zoom = storageService.get("atlas.zoom");
      }
      if (lng !== void 0) {
        lng = parseFloat(lng);
        if (!isFinite(lng)) {
          lng = void 0;
        }
      }
      if (lat !== void 0) {
        lat = parseFloat(lat);
        if (!isFinite(lat)) {
          lat = void 0;
        }
      }
      if (zoom !== void 0) {
        zoom = parseFloat(zoom);
        if (!isFinite(zoom)) {
          zoom = void 0;
        }
      }
      if (lng === void 0 || lat === void 0) {
        lng = -98.58;
        lat = 39.83;
      }
      if (zoom === void 0) {
        zoom = 4;
      }
      const containerView = this.owner.container.view!;
      const map = new mapboxgl.Map({
        container: containerView.node,
        style: this.owner.mapStyle,
        center: {lng, lat},
        pitch: 0,
        zoom: zoom,
        logoPosition: "top-left",
        attributionControl: false,
      });
      this.owner.logoControl = containerView.node.querySelector(".mapboxgl-control-container .mapboxgl-ctrl-top-left .mapboxgl-ctrl");
      this.owner.navigationControl = new mapboxgl.NavigationControl();
      map.addControl(this.owner.navigationControl, "bottom-left");
      map.on("load", this.owner.onMapLoad);
      return new MapboxView(map);
    },
    viewDidMoveMap(mapView: MapboxView): void {
      const geoViewport = mapView.geoViewport;
      this.owner.onMapMove(geoViewport.geoCenter, geoViewport.zoom, mapView);
    },
  })
  override readonly base!: ViewRef<this, MapboxView>;

  @ViewRef<AtlasMapbox, HtmlView>({
    type: HtmlView,
    initView(containerView: HtmlView): void {
      containerView.position("absolute").top(0).right(0).bottom(0).left(0);

      let mapView = this.owner.base.view;
      if (mapView === null) {
        mapView = this.owner.base.createView();
        this.owner.base.setView(mapView);
      }

      const controlContainer = HtmlView.fromNode(containerView.node.querySelector(".mapboxgl-control-container") as HTMLDivElement);
      controlContainer.userSelect.setState("none", Affinity.Intrinsic);
    },
  })
  readonly container!: ViewRef<this, HtmlView>;

  @ViewRef<AtlasMapbox, SurfaceView>({
    type: SurfaceView,
    observes: true,
    didAttachView(surfaceView: SurfaceView): void {
      this.owner.container.insertView(surfaceView);
    },
    willDetachView(surfaceView: SurfaceView): void {
      surfaceView.removeChildren();
    },
    viewWillResize(viewContext: ViewContext, surfaceView: SurfaceView): void {
      this.owner.resizeSurface(surfaceView, viewContext);
    },
    viewWillLayout(viewContext: ViewContext, surfaceView: SurfaceView): void {
      this.owner.layoutSurface(surfaceView, viewContext);
    },
    viewDidApplyTheme(theme: ThemeMatrix, mood: MoodVector, timing: Timing | boolean, surfaceView: SurfaceView): void {
      this.owner.themeSurface(theme, mood, timing, surfaceView);
    },
  })
  override readonly surface!: ViewRef<this, SurfaceView>;

  protected onMapLoad(event: mapboxgl.MapDataEvent): void {
    const surfaceView = this.surface.view;
    if (surfaceView !== null) {
      surfaceView.requireUpdate(View.NeedsLayout);
    }
  }

  @Provider<AtlasMapbox, HistoryService>({
    extends: HistoryProvider,
    type: HistoryService,
    observes: true,
    service: HistoryService.global(),
    serviceDidPopHistory(historyState: HistoryState): void {
      this.owner.updateHistoryState(historyState);
    },
  })
  override readonly historyProvider!: HistoryProvider<this>;

  protected updateHistoryState(historyState: HistoryState): void {
    const mapView = this.base.view;
    if (mapView !== null && !mapView.map.isZooming()) {
      let lng: string | number | undefined = historyState.permanent.lng;
      let lat: string | number | undefined = historyState.permanent.lat;
      let geoCenter: GeoPoint | undefined;
      if (lng !== void 0 && lat !== void 0) {
        lng = parseFloat(lng);
        lat = parseFloat(lat);
        if (isFinite(lng) && isFinite(lat)) {
          geoCenter = new GeoPoint(lng, lat);
        }
      }
      let zoom: string | number | undefined = historyState.permanent.zoom;
      if (zoom !== void 0) {
        zoom = parseFloat(zoom);
        if (!isFinite(zoom)) {
          zoom = void 0;
        }
      }
      if (geoCenter !== void 0 || zoom !== void 0) {
        mapView.moveTo({geoCenter, zoom});
      }
    }
  }
}
