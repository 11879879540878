// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Mutable} from "@swim/util";
import type {StatusVector} from "../status/StatusVector";
import {StatusFactor} from "../status/StatusFactor";
import {StatusTrait} from "../status/StatusTrait";
import {IndicatedGroup} from "../indicator/IndicatedGroup";

/** @public */
export class EntityGroup extends IndicatedGroup {
  constructor() {
    super();
    this.sorted = true;
  }

  /** @internal */
  readonly sorted: boolean;

  isSorted(): boolean;
  isSorted(isSorted: boolean): this;
  isSorted(isSorted?: boolean): boolean | this {
    if (isSorted === void 0) {
      return this.sorted;
    } else {
      (this as Mutable<this>).sorted = isSorted;
      return this;
    }
  }

  /** @internal */
  protected override aggregateStatus(statusVector: StatusVector): void {
    super.aggregateStatus(statusVector);
    const statusTrait = this.getTrait(StatusTrait);
    if (statusTrait !== null) {
      const oldFactor = statusTrait.getStatusFactor("subentities");
      if (!statusVector.isDefined()) {
        statusTrait.setStatusFactor("subentities", null);
      } else if (oldFactor === null || !statusVector.equals(oldFactor.vector)) {
        statusTrait.setStatusFactor("subentities", StatusFactor.create("Subentities", statusVector));
      }
    }
  }

  protected override onStopConsuming(): void {
    super.onStopConsuming();
    const statusTrait = this.getTrait(StatusTrait);
    if (statusTrait !== null) {
      statusTrait.setStatusFactor("subentities", null);
    }
  }
}
