// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {ModelRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {ControllerRef} from "@swim/controller";
import {DrawerView} from "@swim/window";
import {DomainGroup} from "@swim/domain";
import {SurfaceView, MirrorController} from "@swim/prism";
import {AtlasEntityDistrict} from "./AtlasEntityDistrict";
import {AtlasMap} from "./AtlasMap";
import {AtlasLayers} from "./AtlasLayers";

/** @public */
export class AtlasController<M extends AtlasMap = AtlasMap> extends MirrorController {
  override isFullBleed(): boolean {
    return true;
  }

  @ControllerRef<AtlasController, AtlasEntityDistrict>({
    key: true,
    type: AtlasEntityDistrict,
    binds: true,
    initController(entityDistrict: AtlasEntityDistrict): void {
      this.owner.layers.insertController();
    },
  })
  readonly root!: ControllerRef<this, AtlasEntityDistrict>;

  @ControllerRef<AtlasController, AtlasLayers>({
    type: AtlasLayers,
    initController(layersController: AtlasLayers): void {
      const toolbarView = this.owner.toolbar.view;
      if (toolbarView !== null) {
        layersController.toolbar.setView(toolbarView);
      }
      const drawerView = this.owner.drawer.view;
      if (drawerView !== null) {
        layersController.drawer.setView(drawerView);
      }
    },
  })
  readonly layers!: ControllerRef<this, AtlasLayers>;

  @ControllerRef<AtlasController, AtlasMap>({
    type: AtlasMap,
  })
  readonly map!: ControllerRef<this, M>;

  @ViewRef<AtlasController, SurfaceView>({
    type: SurfaceView,
    didAttachView(surfaceView: SurfaceView): void {
      const mapController = this.owner.map.insertController();
      if (mapController !== null) {
        mapController.surface.setView(surfaceView);
        const mapView = mapController.base.view;
        if (mapView !== null) {
          const domainGroup = this.owner.domains.model;
          if (domainGroup !== null) {
            const entityDistrict = this.owner.root.insertController();
            if (entityDistrict !== null) {
              entityDistrict.layer.insertView(mapView);
              entityDistrict.entities.setModel(domainGroup);
            }
          }
        }
      }
    },
    willDetachView(surfaceView: SurfaceView): void {
      const mapController = this.owner.map.insertController();
      if (mapController !== null) {
        mapController.surface.setView(null);
      }
      this.owner.historyProvider.replaceHistory({
        permanent: {
          lng: void 0,
          lat: void 0,
          zoom: void 0,
        },
      });
    },
  })
  override readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<AtlasController, HtmlView>({
    type: HtmlView,
    didAttachView(toolbarView: HtmlView): void {
      const layersController = this.owner.layers.controller;
      if (layersController !== null) {
        layersController.toolbar.setView(toolbarView);
      }
    },
    willDetachView(toolbarView: HtmlView): void {
      const layersController = this.owner.layers.controller;
      if (layersController !== null) {
        layersController.toolbar.setView(null);
      }
    },
  })
  override readonly toolbar!: ViewRef<this, HtmlView>;

  @ViewRef<AtlasController, DrawerView>({
    type: DrawerView,
    didAttachView(drawerView: DrawerView): void {
      const layersController = this.owner.layers.controller;
      if (layersController !== null) {
        layersController.drawer.setView(drawerView);
      }
    },
    willDetachView(drawerView: DrawerView): void {
      const layersController = this.owner.layers.controller;
      if (layersController !== null) {
        layersController.drawer.setView(null);
      }
    },
  })
  override readonly drawer!: ViewRef<this, DrawerView>;

  @ModelRef<AtlasController, DomainGroup>({
    type: DomainGroup,
    initModel(domainGroup: DomainGroup): void {
      const mapController = this.owner.map.controller;
      if (mapController !== null) {
        const mapView = mapController.base.view;
        if (mapView !== null) {
          const entityDistrict = this.owner.root.insertController();
          if (entityDistrict !== null) {
            entityDistrict.layer.insertView(mapView);
            entityDistrict.entities.setModel(domainGroup);
          }
        }
      }
    },
  })
  override readonly domains!: ModelRef<this, DomainGroup>;
}
