// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {MemberFastenerClass} from "@swim/component";
import {Uri, UriPath} from "@swim/uri";
import {ModelRef, TraitModelType} from "@swim/model";
import {StatusTrait, EntityTrait, EntityGroup} from "@swim/domain";
import {MetaNodeGroup} from "./MetaNodeGroup";

/** @public */
export class MetaHostEntity extends EntityTrait {
  constructor(uri: Uri) {
    super(uri);
  }

  protected override onAttachModel(model: TraitModelType<this>): void {
    super.onAttachModel(model);
    this.subentities.insertModel();
  }

  @ModelRef<MetaHostEntity, EntityGroup>({
    extends: true,
    createModel(): EntityGroup {
      const entityGroup = new MetaNodeGroup(Uri.path(UriPath.slash()), this.owner.uri);
      entityGroup.setTrait("status", new StatusTrait());
      return entityGroup;
    },
  })
  override readonly subentities!: ModelRef<this, EntityGroup>;
  static override readonly subentities: MemberFastenerClass<MetaHostEntity, "subentities">;
}
