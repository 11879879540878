// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Model, ModelRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {DrawerView} from "@swim/window";
import {DomainTrait, DomainGroup} from "@swim/domain";
import {SurfaceView, MirrorController} from "@swim/prism";

/** @public */
export class DiagramController extends MirrorController {
  override isFullBleed(): boolean {
    return true;
  }

  @ViewRef<DiagramController, SurfaceView>({
    type: SurfaceView,
  })
  override readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<DiagramController, HtmlView>({
    type: HtmlView,
  })
  override readonly toolbar!: ViewRef<this, HtmlView>;

  @ViewRef<DiagramController, DrawerView>({
    type: DrawerView,
  })
  override readonly drawer!: ViewRef<this, DrawerView>;

  protected onInsertDomain(childDomain: DomainTrait, targetDomain: DomainTrait | null): void {
    // hook
  }

  protected onRemoveDomain(childDomain: DomainTrait): void {
    // hook
  }

  @ModelRef<DiagramController, DomainGroup>({
    type: DomainGroup,
    observes: true,
    didAttachModel(domainGroup: DomainGroup): void {
      let domainModel = domainGroup.firstChild;
      while (domainModel !== null) {
        const domainTrait = domainModel.getTrait(DomainTrait);
        if (domainTrait !== null) {
          this.owner.onInsertDomain(domainTrait, null);
        }
        domainModel = domainModel.nextSibling;
      }
    },
    modelDidInsertChild(child: Model, target: Model | null): void {
      const childDomainTrait = child.getTrait(DomainTrait);
      if (childDomainTrait !== null) {
        const targetDomainTrait = target !== null ? target.getTrait(DomainTrait) : null;
        this.owner.onInsertDomain(childDomainTrait, targetDomainTrait);
      }
    },
    modelWillRemoveChild(child: Model): void {
      const childDomainTrait = child.getTrait(DomainTrait);
      if (childDomainTrait !== null) {
        this.owner.onRemoveDomain(childDomainTrait);
      }
    },
  })
  override readonly domains!: ModelRef<this, DomainGroup>;
}
