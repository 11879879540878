// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {MemberFastenerClass} from "@swim/component";
import {ModelContextType, ModelFlags, Model, ModelRef} from "@swim/model";
import type {StatusVector} from "../status/StatusVector";
import {StatusFactor} from "../status/StatusFactor";
import {StatusTrait} from "../status/StatusTrait";
import {StatusGroup} from "../status/StatusGroup";
import {DomainGroup} from "../domain/DomainGroup";

/** @public */
export class SessionModel extends StatusGroup {
  constructor() {
    super();
    this.domains.insertModel();
  }

  @ModelRef<SessionModel, DomainGroup>({
    key: true,
    type: DomainGroup,
    binds: true,
    createModel(): DomainGroup {
      const domainGroup = new DomainGroup();
      domainGroup.setTrait("status", new StatusTrait());
      return domainGroup;
    },
  })
  readonly domains!: ModelRef<this, DomainGroup>;
  static readonly domains: MemberFastenerClass<SessionModel, "domains">;

  /** @internal */
  protected override aggregateStatus(statusVector: StatusVector): void {
    super.aggregateStatus(statusVector);
    const statusTrait = this.getTrait(StatusTrait);
    if (statusTrait !== null) {
      const oldFactor = statusTrait.getStatusFactor("domains");
      if (!statusVector.isDefined()) {
        statusTrait.setStatusFactor("domains", null);
      } else if (oldFactor === null || !statusVector.equals(oldFactor.vector)) {
        statusTrait.setStatusFactor("domains", StatusFactor.create("Domains", statusVector));
      }
    }
  }

  protected override needsUpdate(updateFlags: ModelFlags, immediate: boolean): ModelFlags {
    updateFlags = super.needsUpdate(updateFlags, immediate);
    const propagateFlags = updateFlags & (Model.NeedsAggregate | Model.NeedsReconcile);
    if (propagateFlags !== 0) {
      this.setFlags(this.flags | propagateFlags);
    }
    return updateFlags;
  }

  protected override needsAnalyze(analyzeFlags: ModelFlags, modelContext: ModelContextType<this>): ModelFlags {
    if ((this.flags & Model.NeedsAggregate) !== 0) {
      analyzeFlags &= ~Model.NeedsAggregate;
    }
    return analyzeFlags;
  }
}
