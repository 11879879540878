// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Class, Lazy} from "@swim/util";
import {Service} from "@swim/component";
import type {Controller} from "@swim/controller";
import type {PrismPlugin} from "./PrismPlugin";
import type {PrismServiceObserver} from "./PrismServiceObserver";

/** @public */
export class PrismService<C extends Controller = Controller> extends Service<C> {
  constructor() {
    super();
    this.plugins = [];
  }

  override readonly observerType?: Class<PrismServiceObserver<C>>;

  readonly plugins: ReadonlyArray<PrismPlugin>;

  insertPlugin(plugin: PrismPlugin, index?: number): void {
    const plugins = this.plugins as PrismPlugin[];
    if (plugins.indexOf(plugin) >= 0) {
      return;
    }
    if (index === void 0) {
      index = plugins.length;
    } else {
      if (index < 0) {
        index = plugins.length + 1 + index;
      }
      index = Math.min(Math.max(0, index, plugins.length));
    }
    this.willInsertPlugin(plugin, index);
    plugins.splice(index, 0, plugin);
    this.onInsertPlugin(plugin, index);
    this.didInsertPlugin(plugin, index);
  }

  protected willInsertPlugin(plugin: PrismPlugin, index: number): void {
    const observers = this.observers;
    for (let i = 0, n = observers.length; i < n; i += 1) {
      const observer = observers[i]!;
      if (observer.serviceWillInsertPlugin !== void 0) {
        observer.serviceWillInsertPlugin(plugin, index, this);
      }
    }
  }

  protected onInsertPlugin(plugin: PrismPlugin, index: number): void {
    // hook
  }

  protected didInsertPlugin(plugin: PrismPlugin, index: number): void {
    const observers = this.observers;
    for (let i = 0, n = observers.length; i < n; i += 1) {
      const observer = observers[i]!;
      if (observer.serviceDidInsertPlugin !== void 0) {
        observer.serviceDidInsertPlugin(plugin, index, this);
      }
    }
  }

  removePlugin(plugin: PrismPlugin): void {
    const plugins = this.plugins as PrismPlugin[];
    const index = plugins.indexOf(plugin);
    if (index >= 0) {
      this.willRemovePlugin(plugin);
      plugins.splice(index, 1);
      this.onRemovePlugin(plugin);
      this.didRemovePlugin(plugin);
    }
  }

  protected willRemovePlugin(plugin: PrismPlugin): void {
    const observers = this.observers;
    for (let i = 0, n = observers.length; i < n; i += 1) {
      const observer = observers[i]!;
      if (observer.serviceWillRemovePlugin !== void 0) {
        observer.serviceWillRemovePlugin(plugin, this);
      }
    }
  }

  protected onRemovePlugin(plugin: PrismPlugin): void {
    // hook
  }

  protected didRemovePlugin(plugin: PrismPlugin): void {
    const observers = this.observers;
    for (let i = 0, n = observers.length; i < n; i += 1) {
      const observer = observers[i]!;
      if (observer.serviceDidRemovePlugin !== void 0) {
        observer.serviceDidRemovePlugin(plugin, this);
      }
    }
  }

  @Lazy
  static global<C extends Controller>(): PrismService<C> {
    return new PrismService();
  }

  static get plugins(): ReadonlyArray<PrismPlugin> {
    return PrismService.global().plugins;
  }

  static insertPlugin(plugin: PrismPlugin, index?: number): void {
    PrismService.global().insertPlugin(plugin, index);
  }

  static removePlugin(plugin: PrismPlugin): void {
    PrismService.global().removePlugin(plugin);
  }
}
