// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {AnyValue, Value} from "@swim/structure";
import {Uri, UriPath} from "@swim/uri";
import {MapDownlinkFastener} from "@swim/client";
import {Model, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {MetaPartEntity} from "./MetaPartEntity";

/** @public */
export class MetaPartGroup extends EntityGroup {
  constructor(metaMeshUri: Uri) {
    super();
    this.metaMeshUri = metaMeshUri;
  }

  readonly metaMeshUri: Uri;

  protected createPart(partKey: string, metaPartUri: Uri): Model | null {
    const partModel = new Model();

    const entityTrait = new MetaPartEntity(metaPartUri);
    if (partKey === "") {
      entityTrait.title.setValue("Default Part");
    } else {
      entityTrait.title.setValue(partKey);
    }
    partModel.setTrait("entity", entityTrait);

    partModel.setTrait("selectable", new SelectableTrait());
    partModel.setTrait("status", new StatusTrait());
    partModel.setTrait("indicated", new IndicatedTrait());
    partModel.setTrait("pulse", new PulseTrait(metaPartUri));

    return partModel;
  }

  protected didUpdatePart(key: Value, value: Value): void {
    const partKey = key.stringValue("");
    let partModel = this.getChild(partKey);
    if (partModel === null) {
      const metaMeshUri = this.metaMeshUri;
      const pathBuilder = UriPath.builder();
      if (metaMeshUri.path.toString() === "meta:mesh") {
        pathBuilder.addSegment("meta:part");
      } else {
        pathBuilder.addPath(metaMeshUri.path);
        pathBuilder.addSegment("part");
      }
      if (partKey !== "") {
        pathBuilder.addSegment(partKey);
      }
      const metaPartUri = Uri.create(metaMeshUri.scheme, metaMeshUri.authority, pathBuilder.bind());
      partModel = this.createPart(partKey, metaPartUri);
      if (partModel !== null) {
        this.appendChild(partModel, partKey);
      }
    }
  }

  protected didRemovePart(key: Value): void {
    const partKey = key.stringValue("");
    this.removeChild(partKey);
  }

  @MapDownlinkFastener<MetaPartGroup, Value, Value, AnyValue, AnyValue>({
    consumed: true,
    nodeUri(): Uri {
      return this.owner.metaMeshUri;
    },
    laneUri: "parts",
    didUpdate(key: Value, value: Value): void {
      this.owner.didUpdatePart(key, value);
    },
    didRemove(key: Value): void {
      this.owner.didRemovePart(key);
    },
  })
  readonly parts!: MapDownlinkFastener<this, Value, Value, AnyValue, AnyValue>;
}
