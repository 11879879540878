// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Graphics, VectorIcon} from "@swim/graphics";
import {HistoryService} from "@swim/controller";
import {MirrorController, MirrorPlugin} from "@swim/prism";
import type {AtlasMapType, AtlasMap} from "./AtlasMap";
import {AtlasWorldMap} from "./AtlasWorldMap";
import {AtlasMapbox} from "./AtlasMapbox";
import {AtlasController} from "./AtlasController";

/** @public */
export class AtlasPlugin extends MirrorPlugin {
  constructor(mapType?: AtlasMapType) {
    super();
    this.mapType = AtlasPlugin.getMapType(mapType);
  }

  override get id(): string {
    return "atlas";
  }

  override get title(): string {
    return "Atlas";
  }

  override get icon(): Graphics {
    return AtlasPlugin.icon;
  }

  readonly mapType: AtlasMapType;

  protected createMap(): AtlasMap<any> {
    const mapType = this.mapType;
    if (mapType === "equirectangular") {
      return new AtlasWorldMap();
    } else if (mapType === "mapbox") {
      return new AtlasMapbox();
    } else {
      throw new Error("unknown map type: " + mapType);
    }
  }

  override createController(): MirrorController {
    const atlasMap = this.createMap();
    const atlasController = new AtlasController();
    atlasController.map.setController(atlasMap);
    return atlasController;
  }

  @Lazy
  static get icon(): Graphics {
    return VectorIcon.create(24, 24, "M20.5,3L20.3,3L15,5.1L9,3L3.4,4.9C3.1,5,3,5.2,3,5.4L3,20.5C3,20.8,3.2,21,3.5,21L3.7,21L9,18.9L15,21L20.6,19.1C20.9,19,21,18.9,21,18.6L21,3.5C21,3.2,20.8,3,20.5,3ZM15,19L9,16.9L9,5L15,7.1L15,19Z");
  }

  static getMapType(mapType?: AtlasMapType): AtlasMapType {
    const historyState = HistoryService.global().historyState;
    if (historyState.permanent.map !== void 0) {
      mapType = historyState.permanent.map as AtlasMapType;
    } else if (mapType === void 0) {
      mapType = "mapbox";
    }
    return mapType;
  }
}
