// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {MemberFastenerClass} from "@swim/component";
import {ModelFlags, Model, ModelRef, TraitContextType, Trait} from "@swim/model";
import {StatusTrait} from "../status/StatusTrait";
import {IndicatorMap} from "./IndicatorMap";
import {IndicatorTrait} from "./IndicatorTrait";
import {IndicatorGroup} from "./IndicatorGroup";

/** @public */
export class IndicatedTrait extends Trait {
  get indicatorMap(): IndicatorMap {
    const indicators = this.indicators.model;
    return indicators !== null ? indicators.indicatorMap : IndicatorMap.empty();
  }

  @ModelRef<IndicatedTrait, IndicatorGroup>({
    key: true,
    type: IndicatorGroup,
    binds: true,
    observes: true,
    didAttachModel(indicatorGroup: IndicatorGroup): void {
      this.owner.requireUpdate(Model.NeedsAggregate | Model.NeedsReconcile);
    },
    willDetachModel(indicatorGroup: IndicatorGroup): void {
      this.owner.requireUpdate(Model.NeedsAggregate | Model.NeedsReconcile);
    },
    modelDidInsertChild(child: Model, target: Model | null): void {
      const childIndicator = child.getTrait(IndicatorTrait);
      if (childIndicator !== null) {
        this.owner.requireUpdate(Model.NeedsAggregate | Model.NeedsReconcile);
      }
    },
    modelWillRemoveChild(child: Model): void {
      const childIndicator = child.getTrait(IndicatorTrait);
      if (childIndicator !== null) {
        this.owner.requireUpdate(Model.NeedsAggregate | Model.NeedsReconcile);
      }
    },
    modelDidStartConsuming(): void {
      this.owner.requireUpdate(Model.NeedsAggregate | Model.NeedsReconcile);
    },
    modelWillStopConsuming(): void {
      this.owner.requireUpdate(Model.NeedsAggregate | Model.NeedsReconcile);
    },
    createModel(): IndicatorGroup {
      const indicatorGroup = new IndicatorGroup();
      indicatorGroup.setTrait("status", new StatusTrait());
      return indicatorGroup;
    },
  })
  readonly indicators!: ModelRef<this, IndicatorGroup>;
  static readonly indicators: MemberFastenerClass<IndicatedTrait, "indicators">;

  /** @protected */
  override needsUpdate(updateFlags: ModelFlags, immediate: boolean): ModelFlags {
    updateFlags = super.needsUpdate(updateFlags, immediate);
    const propagateFlags = updateFlags & (Model.NeedsAggregate | Model.NeedsReconcile);
    if (propagateFlags !== 0) {
      this.setModelFlags(this.modelFlags | propagateFlags);
    }
    return updateFlags;
  }

  /** @protected */
  override needsAnalyze(analyzeFlags: ModelFlags, modelContext: TraitContextType<this>): ModelFlags {
    if ((this.modelFlags & Model.NeedsAggregate) === 0) {
      analyzeFlags &= ~Model.NeedsAggregate;
    }
    return analyzeFlags;
  }

  /** @protected */
  override needsRefresh(refreshFlags: ModelFlags, modelContext: TraitContextType<this>): ModelFlags {
    if ((this.modelFlags & Model.NeedsReconcile) === 0) {
      refreshFlags &= ~Model.NeedsReconcile;
    }
    return refreshFlags;
  }

  static override MountFlags: ModelFlags = Trait.MountFlags | Model.NeedsAggregate | Model.NeedsReconcile;
}
