// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity} from "@swim/component";
import {TraitRef} from "@swim/model";
import {Look, Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import {Controller} from "@swim/controller";
import {TextCellView} from "@swim/table";
import {
  Status,
  StatusVector,
  StatusTrait,
  IndicatorTrait,
  ValueIndicatorTrait,
  ValueIndicatorTraitObserver,
} from "@swim/domain";

/** @public */
export class CatalogIndicatorCell extends Controller {
  /** @internal */
  protected startConsumingIndicator(): void {
    const indicatorTrait = this.indicator.trait;
    if (indicatorTrait !== null) {
      indicatorTrait.consume(this);
      const indicatorModel = indicatorTrait.model;
      if (indicatorModel !== null) {
        indicatorModel.consume(this);
      }
    }
  }

  /** @internal */
  protected stopConsumingIndicator(): void {
    const indicatorTrait = this.indicator.trait;
    if (indicatorTrait !== null) {
      indicatorTrait.unconsume(this);
      const indicatorModel = indicatorTrait.model;
      if (indicatorModel !== null) {
        indicatorModel.unconsume(this);
      }
    }
  }

  @ViewRef<CatalogIndicatorCell, TextCellView>({
    type: TextCellView,
    observes: true,
    initView(treeCell: TextCellView): void {
      treeCell.color.setLook(Look.statusColor, Affinity.Intrinsic);
      const indicatorTrait = this.owner.indicator.trait;
      if (indicatorTrait instanceof ValueIndicatorTrait) {
        treeCell.content(indicatorTrait.formattedValue);
        const statusTrait = indicatorTrait.getTrait(StatusTrait);
        if (statusTrait !== null) {
          this.owner.applyStatus(statusTrait.statusVector);
        }
      }
    },
    viewDidMount(treeCell: TextCellView): void {
      if (!treeCell.culled) {
        this.owner.startConsumingIndicator();
      }
    },
    viewWillUnmount(treeCell: TextCellView): void {
      this.owner.stopConsumingIndicator();
    },
    viewDidCull(treeCell: TextCellView): void {
      this.owner.stopConsumingIndicator();
    },
    viewWillUncull(treeCell: TextCellView): void {
      this.owner.startConsumingIndicator();
    },
  })
  readonly cell!: ViewRef<this, TextCellView>;

  @TraitRef<CatalogIndicatorCell, IndicatorTrait, ValueIndicatorTraitObserver<unknown>>({
    implements: true,
    type: IndicatorTrait,
    observes: true,
    initTrait(indicatorTrait: IndicatorTrait): void {
      const treeCell = this.owner.cell.view;
      if (treeCell !== null && indicatorTrait instanceof ValueIndicatorTrait) {
        treeCell.content(indicatorTrait.formattedValue);
      }
      const statusTrait = indicatorTrait.getTrait(StatusTrait);
      if (statusTrait !== null) {
        this.owner.status.setTrait(statusTrait);
      }
    },
    indicatorDidSetValue(newValue: unknown, oldValue: unknown, indicatorTrait: IndicatorTrait): void {
      const treeCell = this.owner.cell.view;
      if (treeCell !== null && indicatorTrait instanceof ValueIndicatorTrait) {
        treeCell.content(indicatorTrait.formattedValue);
      }
    },
  })
  readonly indicator!: TraitRef<this, IndicatorTrait>;

  @TraitRef<CatalogIndicatorCell, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      this.owner.applyStatus(statusTrait.statusVector);
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      this.owner.applyStatus(newStatusVector);
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;

  protected applyStatus(statusVector: StatusVector): void {
    const treeCell = this.cell.view;
    if (treeCell !== null) {
      const alert = statusVector.get(Status.alert) || 0;
      const warning = statusVector.get(Status.warning) || 0;
      const inactive = statusVector.get(Status.inactive) || 0;
      if (alert !== 0 && warning !== 0) {
        treeCell.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
      } else if (alert !== 0) {
        treeCell.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
      } else if (warning !== 0) {
        treeCell.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, 0]]);
      } else {
        treeCell.modifyMood(Feel.default, [[Feel.warning, 0], [Feel.alert, 0]]);
      }
      if (inactive !== 0) {
        treeCell.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
      } else {
        treeCell.modifyMood(Feel.default, [[Feel.inactive, 0]]);
      }
    }
  }
}
