// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Affinity, Property, Provider} from "@swim/component";
import {Model, ModelRef, TraitRef} from "@swim/model";
import {Look, Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import {Graphics, VectorIcon} from "@swim/graphics";
import {Controller, TraitControllerSet} from "@swim/controller";
import {InputTokenView} from "@swim/token";
import {TableView} from "@swim/table";
import {PopoverView} from "@swim/window";
import {EntityTrait, DomainTrait, DomainGroup} from "@swim/domain";
import {PrismService, PrismProvider, DomainPlugin} from "@swim/prism";
import {SuggestionController} from "./SuggestionController";
import {CollectionController} from "./CollectionController";

/** @public */
export class CollectorController extends Controller {
  get searchIcon(): Graphics {
    return CollectorController.searchIcon;
  }

  get plusIcon(): Graphics {
    return CollectorController.plusIcon;
  }

  protected setFullBleed(fullBleed: boolean): void {
    const searchView = this.search.view;
    if (searchView !== null) {
      searchView.modifyMood(Feel.default, [[Feel.translucent, fullBleed ? 1 : 0]]);
    }
  }

  @Property<CollectorController, boolean>({
    type: Boolean,
    inherits: true,
    didSetValue(fullBleed: boolean): void {
      this.owner.setFullBleed(fullBleed);
    },
  })
  readonly fullBleed!: Property<this, boolean>;

  createDomain(query: string): DomainTrait | null {
    const plugins = this.prismService.plugins;
    for (let i = 0, n = plugins.length; i < n; i += 1) {
      const plugin = plugins[i];
      if (plugin instanceof DomainPlugin) {
        const domain = plugin.queryDomain(query);
        if (domain !== null) {
          return domain;
        }
      }
    }
    return null;
  }

  @ViewRef<CollectorController, InputTokenView>({
    type: InputTokenView,
    observes: true,
    initView(searchView: InputTokenView): void {
      searchView.icon.setValue(this.owner.searchIcon);
      searchView.icon.embossed = false;

      const inputView = searchView.label.view;
      if (inputView !== null) {
        inputView.width.setState(200, Affinity.Intrinsic);
        inputView.placeholder.setState("Search or enter a warp URL", Affinity.Intrinsic);
      }

      searchView.accessory.setValue(this.owner.plusIcon);
      searchView.accessory.embossed = false;

      this.owner.setFullBleed(this.owner.fullBleed.value);
    },
    tokenDidUpdateInput(inputView: HtmlView, searchView: InputTokenView): void {
      const node = inputView.node as HTMLInputElement;
      const query = node.value;
      let dropdownView = this.owner.searchDropdown.view;
      if (query.length !== 0) {
        if (dropdownView === null) {
          dropdownView = this.owner.searchDropdown.createView()!;
          this.owner.searchDropdown.setView(dropdownView);
        }
        dropdownView.setSource(searchView);
        //searchView.modalProvider.presentModal(dropdownView);

        //const suggestedEntity = this.owner.createDomain(query);
        //if (suggestedEntity !== null) {
        //  const entityTrait = suggestedEntity.getTrait(EntityTrait);
        //  if (entityTrait !== null) {
        //    this.owner.suggestions.addTrait(entityTrait, null);
        //  }
        //}
      } else if (dropdownView !== null) {
        searchView.modalProvider.dismissModal(dropdownView);
      }
    },
    tokenDidAcceptInput(inputView: HtmlView, searchView: InputTokenView): void {
      const node = inputView.node as HTMLInputElement;
      const query = node.value;
      if (query.length !== 0) {
        const domainGroup = this.owner.domains.model;
        if (domainGroup !== null) {
          const domainTrait = this.owner.createDomain(query);
          if (domainTrait !== null) {
            domainGroup.prependChild(domainTrait.model!);
            const inputView = searchView.label.view;
            if (inputView !== null) {
              const inputNode = inputView.node as HTMLInputElement;
              inputNode.value = "";
              inputNode.blur();
              searchView.collapse();
            }
            const dropdownView = this.owner.searchDropdown.view;
            if (dropdownView !== null) {
              searchView.modalProvider.dismissModal(dropdownView);
            }
          }
        }
      }
    },
  })
  readonly search!: ViewRef<this, InputTokenView>;

  @ViewRef<CollectorController, PopoverView>({
    type: PopoverView,
    initView(dropdownView: PopoverView): void {
      dropdownView.addClass("search-dropdown")
                  .dropdown(true)
                  .placementGap(2)
                  .minHeight(64)
                  .maxHeight(320)
                  .borderRadius(14)
                  .boxSizing("content-box")
                  .overflowY("auto");

      dropdownView.modifyMood(Feel.default, [[Feel.translucent, 1]]);
      dropdownView.backgroundColor.setLook(Look.accentColor);

      this.owner.suggestionsList.insertView(dropdownView);
    },
  })
  readonly searchDropdown!: ViewRef<this, PopoverView>;

  protected insertSuggestionRow(suggestionController: SuggestionController): void {
    const suggestionsList = this.suggestionsList.view;
    if (suggestionsList !== null) {
      const targetSuggestion = suggestionController.nextSibling;
      const targetView = targetSuggestion instanceof SuggestionController ? targetSuggestion.row.view : null;
      suggestionController.row.insertView(suggestionsList, void 0, targetView);
    }
  }

  protected removeSuggestionRow(suggestionController: SuggestionController): void {
    suggestionController.row.removeView();
  }

  @ViewRef<CollectorController, TableView>({
    type: TableView,
    initView(suggestionsList: TableView): void {
      const suggestionControllers = this.owner.suggestions.controllers;
      for (const controllerId in suggestionControllers) {
        const suggestionController = suggestionControllers[controllerId]!;
        this.owner.insertSuggestionRow(suggestionController);
      }
    }
  })
  readonly suggestionsList!: ViewRef<this, TableView>;

  @TraitControllerSet<CollectorController, EntityTrait, SuggestionController>({
    type: SuggestionController,
    binds: true,
    getTraitRef(suggestionController: SuggestionController): TraitRef<unknown, EntityTrait> {
      return suggestionController.entity;
    },
    didAttachController(suggestionController: SuggestionController): void {
      this.owner.insertSuggestionRow(suggestionController);
    },
    willDetachController(suggestionController: SuggestionController): void {
      this.owner.removeSuggestionRow(suggestionController);
    },
  })
  readonly suggestions!: TraitControllerSet<this, EntityTrait, SuggestionController>;

  protected insertCollectionRow(collectionController: CollectionController): void {
    const domainList = this.domainList.view;
    if (domainList !== null) {
      const targetController = collectionController.nextSibling;
      const targetView = targetController instanceof CollectionController ? targetController.row.view : null;
      collectionController.row.insertView(domainList, void 0, targetView);
    }
  }

  protected removeCollectionRow(collectionController: CollectionController): void {
    collectionController.row.removeView();
  }

  @TraitControllerSet<CollectorController, EntityTrait, CollectionController>({
    type: CollectionController,
    binds: true,
    getTraitRef(collectionController: CollectionController): TraitRef<unknown, EntityTrait> {
      return collectionController.entity;
    },
    didAttachController(collectionController: CollectionController): void {
      this.owner.insertCollectionRow(collectionController);
    },
    willDetachController(collectionController: CollectionController): void {
      this.owner.removeCollectionRow(collectionController);
    },
  })
  readonly collections!: TraitControllerSet<this, EntityTrait, CollectionController>;

  @ViewRef<CollectorController, TableView>({
    type: TableView,
    initView(tableView: TableView): void {
      const collectionControllers = this.owner.collections.controllers;
      for (const controllerId in collectionControllers) {
        const collectionController = collectionControllers[controllerId]!;
        this.owner.insertCollectionRow(collectionController);
      }
    },
  })
  readonly domainList!: ViewRef<this, TableView>;

  @ModelRef<CollectorController, DomainGroup>({
    type: DomainGroup,
    observes: true,
    initModel(domainGroup: DomainGroup): void {
      let domainModel = domainGroup.firstChild;
      while (domainModel !== null) {
        const domainTrait = domainModel.getTrait(DomainTrait);
        if (domainTrait !== null) {
          const entityTrait = domainTrait.getTrait(EntityTrait)!;
          const collectionController = this.owner.collections.addTraitController(entityTrait);
          collectionController.domain.setTrait(domainTrait);
        }
        domainModel = domainModel.nextSibling;
      }
    },
    modelDidInsertChild(child: Model, target: Model | null): void {
      const domainTrait = child.getTrait(DomainTrait);
      if (domainTrait !== null) {
        const entityTrait = domainTrait.getTrait(EntityTrait)!;
        const targetTrait = target !== null ? target.getTrait(DomainTrait) : null;
        const collectionController = this.owner.collections.addTraitController(entityTrait, targetTrait);
        collectionController.domain.setTrait(domainTrait);
      }
    },
    modelWillRemoveChild(child: Model): void {
      const domainTrait = child.getTrait(DomainTrait);
      if (domainTrait !== null) {
        const entityTrait = domainTrait.getTrait(EntityTrait)!;
        this.owner.collections.deleteTraitController(entityTrait);
      }
    },
  })
  readonly domains!: ModelRef<this, DomainGroup>;

  @Provider<CollectorController, PrismService>({
    extends: PrismProvider,
    type: PrismService,
    observes: false,
    service: PrismService.global(),
  })
  readonly prismService!: PrismProvider<this>;

  @Lazy
  static get searchIcon(): Graphics {
    return VectorIcon.create(24, 24, "M14.375,13.25 L13.7825,13.25 L13.5725,13.0475 C14.3075,12.1925 14.75,11.0825 14.75,9.875 C14.75,7.1825 12.5675,5 9.875,5 C7.1825,5 5,7.1825 5,9.875 C5,12.5675 7.1825,14.75 9.875,14.75 C11.0825,14.75 12.1925,14.3075 13.0475,13.5725 L13.25,13.7825 L13.25,14.375 L17,18.1175 L18.1175,17 L14.375,13.25 Z M9.875,13.25 C8.0075,13.25 6.5,11.7425 6.5,9.875 C6.5,8.0075 8.0075,6.5 9.875,6.5 C11.7425,6.5 13.25,8.0075 13.25,9.875 C13.25,11.7425 11.7425,13.25 9.875,13.25 Z");
  }

  @Lazy
  static get plusIcon(): Graphics {
    return VectorIcon.create(24, 24, "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z");
  }
}
