// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Value} from "@swim/structure";
import type {Uri} from "@swim/uri";
import {Model} from "@swim/model";
import type {LaneModelObserver} from "./LaneModelObserver";

/** @public */
export abstract class LaneModel extends Model {
  /** @internal */
  ownNodeUri: Uri;
  /** @internal */
  ownLaneUri: Uri;
  /** @internal */
  info: Value;

  constructor(nodeUri: Uri, laneUri: Uri, info: Value) {
    super();
    this.ownNodeUri = nodeUri;
    this.ownLaneUri = laneUri;
    this.info = info;
  }

  override readonly observerType?: Class<LaneModelObserver>;

  setInfo(info: Value): void {
    this.info = info;
  }

  protected onEvent(value: Value): void {
    this.callObservers("onLaneEvent", value, this);
  }
}
