// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Easing} from "@swim/util";
import type {GeoShape} from "@swim/geo";
import {
  ModelContext,
  Model,
  ModelRef,
  Trait,
  TraitRef,
  SelectionOptions,
  SelectableTrait,
} from "@swim/model";
import type {Color} from "@swim/style";
import {Look, Feel} from "@swim/theme";
import {GestureInput, ViewRef} from "@swim/view";
import {ControllerContextType, Controller, ControllerRef} from "@swim/controller";
import {GeoViewContext, GeoView, GeoCircleView, GeoIconView, GeoAreaView} from "@swim/map";
import {Status, StatusVector, StatusTrait, EntityTrait, EntityGroup} from "@swim/domain";
import type {Geographic} from "./geographic/Geographic";
import {GeographicView} from "./geographic/GeographicView";
import {LocationTrait} from "./location/LocationTrait";
import {DistrictTrait} from "./district/DistrictTrait";
import {AtlasEntityDistrict} from "./"; // forward import

/** @public */
export class AtlasEntityLocation extends Controller {
  protected updateLevelOfDetail(viewContext: GeoViewContext): void {
    this.requireUpdate(Controller.NeedsRevise);
  }

  protected reviseLevelOfDetail(): void {
    const layerView = this.layer.view;
    if (layerView !== null) {
      const geoViewport = layerView.geoViewport;

      const locationTrait = this.location.trait;
      if (locationTrait !== null) {
        if (locationTrait.minZoom <= geoViewport.zoom) {
          locationTrait.consume(this);
          const geographicView = this.geographic.view;
          if (geographicView !== null) {
            geographicView.setHidden(locationTrait.maxZoom < geoViewport.zoom);
          }
        } else {
          locationTrait.unconsume(this);
        }
      }

      const districtTrait = this.district.trait;
      if (districtTrait !== null) {
        let boundary = districtTrait.boundary;
        if (boundary === null) {
          const geographicView = this.geographic.view;
          if (geographicView !== null) {
            boundary = geographicView.geoBounds;
          }
        }
        if (districtTrait.minZoom <= geoViewport.zoom &&
           (boundary === null || boundary.bounds.intersects(geoViewport.geoFrame))) {
          if (geoViewport.zoom < districtTrait.maxZoom) {
            districtTrait.consume(this);
          } else {
            districtTrait.unconsume(this);
          }
          const entityGroup = this.subentities.model;
          if (entityGroup !== null) {
            entityGroup.consume(this);
          }
        } else {
          const entityGroup = this.subentities.model;
          if (entityGroup !== null) {
            entityGroup.unconsume(this);
          }
          districtTrait.unconsume(this);
        }
      }
    }
  }

  protected override onRevise(controllerContext: ControllerContextType<this>): void {
    super.onRevise(controllerContext);
    this.reviseLevelOfDetail();
  }

  @ViewRef<AtlasEntityLocation, GeoView>({
    observes: true,
    initView(layerView: GeoView): void {
      const entityGroup = this.owner.subentities.model;
      if (entityGroup !== null) {
        const entityDistrict = this.owner.subdistrict.insertController();
        if (entityDistrict !== null) {
          entityDistrict.layer.setView(layerView);
        }
      }
      this.owner.updateLevelOfDetail(layerView.viewContext);
    },
    deinitView(layerView: GeoView): void {
      this.owner.subdistrict.deleteController();
    },
    viewDidProject(viewContext: GeoViewContext): void {
      this.owner.updateLevelOfDetail(viewContext);
    },
  })
  readonly layer!: ViewRef<this, GeoView>;

  @ViewRef<AtlasEntityLocation, GeographicView>({
    observes: true,
    initView(geographicView: GeographicView): void {
      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        if (selectableTrait.selected) {
          geographicView.highlight();
        } else {
          geographicView.unhighlight();
        }
      }
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        this.owner.applyStatus(statusTrait.statusVector, StatusVector.empty());
      }
      if (geographicView.mounted) {
        this.owner.updateLevelOfDetail(geographicView.viewContext);
      }
    },
    geographicDidPress(input: GestureInput, event: Event | null, geographicView: GeographicView): void {
      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        if (!selectableTrait.selected) {
          selectableTrait.select({multi: input.shiftKey});
        } else if (input.shiftKey) {
          selectableTrait.unselect();
        } else {
          selectableTrait.unselectAll();
        }
      }
    },
    geographicDidLongPress(input: GestureInput, geographicView: GeographicView): void {
      input.preventDefault();
      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        if (!selectableTrait.selected) {
          selectableTrait.select({multi: true});
        } else {
          selectableTrait.unselect();
        }
      }
    },
  })
  readonly geographic!: ViewRef<this, GeographicView>;

  @ControllerRef<AtlasEntityLocation, AtlasEntityDistrict>({
    // avoid cyclic static reference to type: AtlasEntityDistrict
    initController(subdistrict: AtlasEntityDistrict): void {
      const layerView = this.owner.layer.view;
      if (layerView !== null) {
        subdistrict.layer.setView(layerView);
        this.owner.updateLevelOfDetail(layerView.viewContext);
      }
    },
    createController(): AtlasEntityDistrict {
      return new AtlasEntityDistrict;
    },
  })
  readonly subdistrict!: ControllerRef<this, AtlasEntityDistrict>;

  @TraitRef<AtlasEntityLocation, EntityTrait>({
    type: EntityTrait,
    observes: true,
    initTrait(entityTrait: EntityTrait): void {
      const geographicView = this.owner.geographic.view;
      if (geographicView !== null) {
        this.owner.updateLevelOfDetail(geographicView.viewContext);
      }

      this.owner.selectable.setTrait(entityTrait.getTrait(SelectableTrait));
      this.owner.status.setTrait(entityTrait.getTrait(StatusTrait));
      this.owner.location.setTrait(entityTrait.getTrait(LocationTrait));
      this.owner.district.setTrait(entityTrait.getTrait(DistrictTrait));
    },
    traitDidValidate(modelContext: ModelContext, entityTrait: EntityTrait): void {
      const layerView = this.owner.layer.view;
      if (layerView !== null) {
        this.owner.updateLevelOfDetail(layerView.viewContext);
      }
    },
    traitDidInsertTrait(memberTrait: Trait, targetTrait: Trait | null): void {
      if (memberTrait instanceof SelectableTrait) {
        this.owner.selectable.setTrait(memberTrait);
      } else if (memberTrait instanceof StatusTrait) {
        this.owner.status.setTrait(memberTrait);
      } else if (memberTrait instanceof LocationTrait) {
        this.owner.location.setTrait(memberTrait);
      } else if (memberTrait instanceof DistrictTrait) {
        this.owner.district.setTrait(memberTrait);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait>;

  @TraitRef<AtlasEntityLocation, SelectableTrait>({
    type: SelectableTrait,
    observes: true,
    initTrait(selectableTrait: SelectableTrait): void {
      const geographicView = this.owner.geographic.view;
      if (geographicView !== null) {
        if (selectableTrait.selected) {
          geographicView.highlight();
        } else {
          geographicView.unhighlight();
        }
        this.owner.updateLevelOfDetail(geographicView.viewContext);
      }
    },
    traitDidSelect(options: SelectionOptions | null, selectableTrait: SelectableTrait): void {
      const geographicView = this.owner.geographic.view;
      if (geographicView !== null) {
        geographicView.highlight();
      }
    },
    traitWillUnselect(selectableTrait: SelectableTrait): void {
      const geographicView = this.owner.geographic.view;
      if (geographicView !== null) {
        geographicView.unhighlight();
      }
    },
  })
  readonly selectable!: TraitRef<this, SelectableTrait>;

  @TraitRef<AtlasEntityLocation, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      this.owner.applyStatus(statusTrait.statusVector, StatusVector.empty());
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      this.owner.applyStatus(newStatusVector, oldStatusVector);
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;

  protected applyStatus(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
    const geographicView = this.geographic.view;
    if (geographicView !== null) {
      const alert = newStatusVector.get(Status.alert) || 0;
      const warning = newStatusVector.get(Status.warning) || 0;
      const normal = newStatusVector.get(Status.normal);
      const inactive = newStatusVector.get(Status.inactive) || 0;
      if (alert !== 0 && warning !== 0) {
        geographicView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
        if (!oldStatusVector.isEmpty()) {
          this.ripple(geographicView.getLook(Look.accentColor)!, 2, 5000);
        }
      } else if (alert !== 0) {
        geographicView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
        if (!oldStatusVector.isEmpty()) {
          this.ripple(geographicView.getLook(Look.accentColor)!, 2, 5000);
        }
      } else if (warning !== 0) {
        geographicView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
        if (!oldStatusVector.isEmpty()) {
          this.ripple(geographicView.getLook(Look.accentColor)!, 1, 2500);
        }
      } else {
        geographicView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
        if (normal !== void 0 && !oldStatusVector.isEmpty()) {
          this.ripple(geographicView.getLook(Look.accentColor)!, 1, 2500);
        }
      }
      if (inactive !== 0) {
        geographicView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
      } else {
        geographicView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
      }
    }
  }

  ripple(color: Color, width: number, timing: number): void {
    const geographicView = this.geographic.view;
    if (geographicView !== null && geographicView.mounted) {
      if (geographicView instanceof GeoCircleView ||
          geographicView instanceof GeoIconView ||
          geographicView instanceof GeoAreaView) {
        geographicView.ripple({width, color, timing});
      }
    }
  }

  @TraitRef<AtlasEntityLocation, DistrictTrait>({
    type: DistrictTrait,
    observes: true,
    initTrait(districtTrait: DistrictTrait): void {
      this.owner.subentities.setModel(districtTrait.subdistricts.model);
    },
    traitDidInsertChild(child: Model, target: Model | null, districtTrait: DistrictTrait): void {
      if (child === districtTrait.subdistricts.model) {
        this.owner.subentities.setModel(child as EntityGroup);
      }
    },
    districtDidSetZoomRange(minZoom: number, maxZoom: number, districtTrait: DistrictTrait): void {
      districtTrait.requireUpdate(Model.NeedsValidate);
    },
    districtDidSetBoundary(newBoundary: GeoShape | null, oldBoundary: GeoShape | null, districtTrait: DistrictTrait): void {
      districtTrait.requireUpdate(Model.NeedsValidate);
    },
  })
  readonly district!: TraitRef<this, DistrictTrait>;

  protected onSetGeographic(geographic: Geographic | null): void {
    if (geographic !== null) {
      let geographicView = this.geographic.view;
      if (geographicView === null) {
        geographicView = GeographicView.fromGeographic(geographic);
        geographicView.modifyTheme(Feel.default, [[Feel.primary, 1]]);
        this.geographic.setView(geographicView);
      } else {
        geographicView.setState(geographic, Easing.linear.withDuration(5000));
      }
      const layerView = this.layer.view;
      if (layerView !== null) {
        this.geographic.insertView(layerView);
        this.updateLevelOfDetail(layerView.viewContext);
      }
    } else {
      this.geographic.deleteView();
    }
  }

  @TraitRef<AtlasEntityLocation, LocationTrait>({
    type: LocationTrait,
    observes: true,
    initTrait(locationTrait: LocationTrait): void {
      this.owner.onSetGeographic(locationTrait.geographic);
    },
    locationDidSetZoomRange(minZoom: number, maxZoom: number, locationTrait: LocationTrait): void {
      locationTrait.requireUpdate(Model.NeedsValidate);
    },
    locationDidSetGeographic(geographic: Geographic | null): void {
      this.owner.onSetGeographic(geographic);
    },
    traitWillStartConsuming(): void {
      const geographicView = this.owner.geographic.view;
      if (geographicView !== null) {
        const layerView = this.owner.layer.view;
        if (layerView !== null) {
          this.owner.geographic.insertView(layerView);
        }
      }
    },
    traitDidStopConsuming(): void {
      this.owner.geographic.deleteView();
    },
  })
  readonly location!: TraitRef<this, LocationTrait>;

  @ModelRef<AtlasEntityLocation, EntityGroup>({
    type: EntityGroup,
    observes: true,
    didAttachModel(entityGroup: EntityGroup): void {
      const entityDistrict = this.owner.subdistrict.insertController();
      if (entityDistrict !== null) {
        entityDistrict.entities.setModel(entityGroup);
      }
    },
    willDetachModel(entityGroup: EntityGroup): void {
      this.owner.subdistrict.deleteController();
    },
    modelDidStopConsuming(entityGroup: EntityGroup): void {
      entityGroup.removeChildren();
    },
  })
  readonly subentities!: ModelRef<this, EntityGroup>;

  protected override onUnmount(): void {
    super.onUnmount();
    this.layer.setView(null);
    this.geographic.deleteView();
    this.subdistrict.deleteController();
  }
}
