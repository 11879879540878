// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {MemberFastenerClass} from "@swim/component";
import {Model, Trait, TraitSet} from "@swim/model";
import {WidgetTrait} from "./WidgetTrait";
import type {WidgetGroupObserver} from "./WidgetGroupObserver";

/** @public */
export class WidgetGroup extends Trait {
  override readonly observerType?: Class<WidgetGroupObserver>;

  @TraitSet<WidgetGroup, WidgetTrait>({
    type: WidgetTrait,
    binds: true,
    willAttachTrait(widgetTrait: WidgetTrait, targetTrait: Trait | null): void {
      this.owner.callObservers("traitWillAttachWidget", widgetTrait, targetTrait, this.owner);
    },
    didDetachTrait(widgetTrait: WidgetTrait): void {
      this.owner.callObservers("traitDidDetachWidget", widgetTrait, this.owner);
    },
    detectModel(model: Model): WidgetTrait | null {
      return model.getTrait(WidgetTrait);
    },
    detectTrait(trait: Trait): WidgetTrait | null {
      return null;
    },
  })
  readonly widgets!: TraitSet<this, WidgetTrait>;
  static readonly widgets: MemberFastenerClass<WidgetGroup, "widgets">;
}
