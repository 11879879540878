// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Equals} from "@swim/util";
import {AnyLength, Length} from "@swim/math";
import {AnyGeoPath, GeoPath} from "@swim/geo";
import {AnyColor, Color} from "@swim/style";
import {Geographic} from "./Geographic";

/** @public */
export type AnyGeographicLine = GeographicLine | GeographicLineInit;

/** @public */
export interface GeographicLineInit {
  geometry: AnyGeoPath;
  stroke?: AnyColor | null;
  strokeWidth?: AnyLength | null;
}

/** @public */
export class GeographicLine extends Geographic {
  constructor(geometry: GeoPath, stroke: Color | null, strokeWidth: Length | null) {
    super();
    this.geometry = geometry;
    this.stroke = stroke;
    this.strokeWidth = strokeWidth;
  }

  override readonly geometry: GeoPath;

  readonly stroke: Color | null;

  readonly strokeWidth: Length | null;

  override equals(that: unknown): boolean {
    if (this === that) {
      return true;
    } else if (that instanceof GeographicLine) {
      return this.geometry.equals(that.geometry)
          && Equals(this.stroke, that.stroke)
          && Equals(this.strokeWidth, that.strokeWidth);
    }
    return false;
  }

  static fromInit(init: GeographicLineInit): GeographicLine {
    const geometry = GeoPath.fromAny(init.geometry);
    const stroke = init.stroke !== void 0 && init.stroke !== null ? Color.fromAny(init.stroke) : null;
    const strokeWidth = init.strokeWidth !== void 0 && init.strokeWidth !== null ? Length.fromAny(init.strokeWidth) : null;
    return new GeographicLine(geometry, stroke, strokeWidth);
  }

  static fromAny(value: AnyGeographicLine): GeographicLine {
    if (value === void 0 || value === null || value instanceof GeographicLine) {
      return value;
    } else if (typeof value === "object") {
      return GeographicLine.fromInit(value);
    }
    throw new TypeError("" + value);
  }
}
