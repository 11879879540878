// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Property} from "@swim/component";
import {ViewportInsets, View} from "@swim/view";
import {HtmlView} from "@swim/dom";
import type {SurfaceViewObserver} from "./SurfaceViewObserver";

/** @public */
export class SurfaceView extends HtmlView {
  override readonly observerType?: Class<SurfaceViewObserver>;

  @Property({inherits: true, value: null})
  readonly edgeInsets!: Property<this, ViewportInsets | null>;

  @Property<SurfaceView, boolean>({
    type: Boolean,
    value: false,
    updateFlags: View.NeedsResize | View.NeedsLayout,
    willSetValue(fullBleed: boolean): void {
      this.owner.callObservers("viewWillSetFullBleed", fullBleed, this.owner);
    },
    didSetValue(fullBleed: boolean): void {
      this.owner.callObservers("viewDidSetFullBleed", fullBleed, this.owner);
    },
  })
  readonly fullBleed!: Property<this, boolean>;
}
