// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Mutable} from "@swim/util";
import {ModelContextType, ModelFlags, Model} from "@swim/model";
import type {StatusVector} from "../status/StatusVector";
import {StatusFactor} from "../status/StatusFactor";
import {StatusTrait} from "../status/StatusTrait";
import {StatusGroup} from "../status/StatusGroup";
import {IndicatorMap} from "./IndicatorMap";
import {IndicatorTrait} from "./IndicatorTrait";

/** @public */
export class IndicatorGroup extends StatusGroup {
  constructor() {
    super();
    this.indicatorMap = IndicatorMap.empty();
  }

  readonly indicatorMap: IndicatorMap;

  /** @internal */
  protected setIndicatorMap(indicatorMap: IndicatorMap): void {
    (this as Mutable<this>).indicatorMap = indicatorMap;
  }

  /** @internal */
  protected override aggregateStatus(statusVector: StatusVector): void {
    super.aggregateStatus(statusVector);
    const statusTrait = this.getTrait(StatusTrait);
    if (statusTrait !== null) {
      const oldFactor = statusTrait.getStatusFactor("indicators");
      if (!statusVector.isDefined()) {
        statusTrait.setStatusFactor("indicators", null);
      } else if (oldFactor === null || !statusVector.equals(oldFactor.vector)) {
        statusTrait.setStatusFactor("indicators", StatusFactor.create("Indicators", statusVector));
      }
    }
  }

  protected override needsUpdate(updateFlags: ModelFlags, immediate: boolean): ModelFlags {
    updateFlags = super.needsUpdate(updateFlags, immediate);
    const propagateFlags = updateFlags & (Model.NeedsAggregate | Model.NeedsReconcile);
    if (propagateFlags !== 0) {
      this.setFlags(this.flags | propagateFlags);
    }
    return updateFlags;
  }

  protected override needsRefresh(refreshFlags: ModelFlags, modelContext: ModelContextType<this>): ModelFlags {
    if ((this.flags & Model.NeedsReconcile) === 0) {
      refreshFlags &= ~Model.NeedsReconcile;
    }
    return refreshFlags;
  }

  protected override refreshChildren(refreshFlags: ModelFlags, modelContext: ModelContextType<this>,
                                     refreshChild: (this: this, child: Model, refreshFlags: ModelFlags,
                                                    modelContext: ModelContextType<this>) => void): void {
    if ((refreshFlags & Model.NeedsReconcile) !== 0) {
      this.reconcileChildIndicatorTypes(refreshFlags, modelContext, refreshChild);
    } else {
      super.refreshChildren(refreshFlags, modelContext, refreshChild);
    }
  }

  protected reconcileChildIndicatorTypes(refreshFlags: ModelFlags, modelContext: ModelContextType<this>,
                                         refreshChild: (this: this, child: Model, refreshFlags: ModelFlags,
                                                        modelContext: ModelContextType<this>) => void): void {
    // Accumulate indicator types when reconciling child models.
    let indicatorMap = IndicatorMap.empty();

    type self = this;
    function reconcileChildIndicatorType(this: self, child: Model, refreshFlags: ModelFlags,
                                         modelContext: ModelContextType<self>): void {
      refreshChild.call(this, child, refreshFlags, modelContext);
      const indicatorTrait = child.getTrait(IndicatorTrait);
      if (indicatorTrait !== null) {
        indicatorMap = indicatorMap.updated(indicatorTrait.indicatorType);
      }
    }
    super.refreshChildren(refreshFlags, modelContext, reconcileChildIndicatorType);

    this.setIndicatorMap(indicatorMap);
  }

  static override MountFlags: ModelFlags = StatusGroup.MountFlags | Model.NeedsAggregate | Model.NeedsReconcile;
  static override InsertChildFlags: ModelFlags = StatusGroup.InsertChildFlags | Model.NeedsAggregate | Model.NeedsReconcile;
  static override RemoveChildFlags: ModelFlags = StatusGroup.RemoveChildFlags | Model.NeedsAggregate | Model.NeedsReconcile;
}
