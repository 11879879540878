// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Timing} from "@swim/util";
import {Affinity, Property, Provider} from "@swim/component";
import {ConstraintProperty, ConstraintGroup} from "@swim/constraint";
import {ColorStop, LinearGradient} from "@swim/style";
import {Look, Feel, MoodVector, ThemeMatrix} from "@swim/theme";
import {
  ViewportIdiom,
  ViewportInsets,
  ViewportService,
  ViewportProvider,
  ViewContextType,
  View,
  ViewRef,
} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {HtmlIconView} from "@swim/graphics";
import {InputTokenView} from "@swim/token";
import {ScrimView, DrawerView, DrawerButton} from "@swim/window";
import {SurfaceView} from "@swim/prism";
import {ActivityWindow} from "../activity/ActivityWindow";
import {BeamView} from "../beam/BeamView";

/** @public */
export class ShellView extends HtmlView {
  constructor(node: HTMLElement) {
    super(node);
    this.masterLayout = new ConstraintGroup(this).constrain();
    this.mobileLayout = new ConstraintGroup(this);
    this.desktopLayout = new ConstraintGroup(this);

    this.onSurfaceScroll = this.onSurfaceScroll.bind(this);

    this.initShell();
    this.initMasterLayout(this.masterLayout);
    this.initMobileLayout(this.mobileLayout);
    this.initDesktopLayout(this.desktopLayout);
  }

  protected initShell(): void {
    this.beam.insertView();
    this.surface.insertView();
    this.topBar.insertView();
    this.searchBar.insertView();
    this.brand.insertView();
    this.rightPanel.insertView();
    this.scrim.insertView();
    this.leftDrawer.insertView();
    this.rightDrawer.insertView();
    this.activityWindow.insertView();
  }

  /** @internal */
  readonly masterLayout: ConstraintGroup;

  protected initMasterLayout(layout: ConstraintGroup): void {
    layout.constraint(this.beamHeight.constrain(), "ge", 4);
    layout.constraint(this.beamHeight.constrain(), "eq", this.safeAreaInsetTop, "weak");

    layout.constraint(this.topBarTop.constrain(), "eq", this.beamHeight);

    layout.constraint(this.searchBarPaddingLeft.constrain(), "eq", this.topBarPaddingLeft.plus(4));
    layout.constraint(this.searchBarPaddingRight.constrain(), "eq", this.topBarPaddingRight.plus(4));
    layout.constraint(this.searchBarPaddingBottom.constrain(), "eq", this.safeAreaInsetBottom);

    layout.constraint(this.surfacePaddingTop.constrain(), "eq", this.topBarHeight);
    layout.constraint(this.surfacePaddingBottom.constrain(), "eq", this.safeAreaInsetBottom, "weak");

    layout.constraint(this.activityHeight.constrain(), "eq", this.height.minus(this.beamHeight));
  }

  /** @internal */
  readonly mobileLayout: ConstraintGroup;

  protected initMobileLayout(layout: ConstraintGroup): void {
    layout.constraint(this.topBarLeft.constrain(), "eq", 0);
    layout.constraint(this.topBarRight.constrain(), "eq", 0);
    layout.constraint(this.topBarHeight.constrain(), "eq", 60);
    layout.constraint(this.topBarPaddingLeft.constrain(), "eq", this.safeAreaInsetLeft);
    layout.constraint(this.topBarPaddingRight.constrain(), "eq", this.safeAreaInsetRight);

    layout.constraint(this.searchBarTop.constrain(), "le", this.height.minus(this.safeAreaInsetBottom).minus(this.searchBarHeight));
    layout.constraint(this.searchBarTop.constrain(), "eq", this.visualViewportPageTop.plus(this.visualViewportHeight).minus(this.searchBarHeight), "weak");
    layout.constraint(this.searchBarLeft.constrain(), "eq", 0);
    layout.constraint(this.searchBarRight.constrain(), "eq", 0);
    layout.constraint(this.searchBarHeight.constrain(), "eq", 60);
    layout.constraint(this.searchFieldHeight.constrain(), "eq", 44);

    layout.constraint(this.surfacePaddingLeft.constrain(), "eq", 0);
    layout.constraint(this.surfacePaddingRight.constrain(), "eq", 0);

    layout.constraint(this.drawerWidth.constrain(), "eq", this.width);
  }

  /** @internal */
  readonly desktopLayout: ConstraintGroup;

  protected initDesktopLayout(layout: ConstraintGroup): void {
    layout.constraint(this.topBarLeft.constrain(), "eq", this.leftDrawerWidth);
    layout.constraint(this.topBarRight.constrain(), "eq", this.rightPanelWidth);
    layout.constraint(this.topBarHeight.constrain(), "eq", 48);
    layout.constraint(this.topBarPaddingLeft.constrain(), "eq", 0);
    layout.constraint(this.topBarPaddingRight.constrain(), "eq", 0);

    layout.constraint(this.searchBarTop.constrain(), "eq", this.beamHeight);
    layout.constraint(this.searchBarLeft.constrain(), "eq", this.leftDrawerWidth);
    layout.constraint(this.searchBarRight.constrain(), "eq", this.rightPanelWidth);
    layout.constraint(this.searchBarHeight.constrain(), "eq", 48);
    layout.constraint(this.searchFieldHeight.constrain(), "eq", 32);

    layout.constraint(this.surfacePaddingLeft.constrain(), "eq", this.leftDrawerWidth);
    layout.constraint(this.surfacePaddingRight.constrain(), "eq", this.rightPanelWidth);

    layout.constraint(this.drawerWidth.constrain(), "eq", 360);
  }

  protected updateViewportIdiom(viewportIdiom: ViewportIdiom): void {
    if (viewportIdiom === "mobile") {
      this.desktopLayout.constrain(false);
      this.mobileLayout.constrain(true);
    } else {
      this.mobileLayout.constrain(false);
      this.desktopLayout.constrain(true);
    }

    const searchBar = this.searchBar.view;
    if (searchBar !== null) {
      this.updateSearchBarBleed(searchBar);
    }

    const brandView = this.brand.view;
    if (brandView !== null) {
      if (viewportIdiom === "mobile") {
        brandView.display("block");
      } else {
        brandView.display("none");
      }
    }

    const leftDrawerView = this.leftDrawer.view;
    if (leftDrawerView !== null) {
      if (viewportIdiom === "mobile") {
        leftDrawerView.dismiss(false);
      } else {
        leftDrawerView.collapse(false);
        leftDrawerView.present(false);
      }
      const surfaceView = this.surface.view;
      const fullBleed = surfaceView !== null && surfaceView.fullBleed;
      const translucent = fullBleed && this.viewportIdiom !== "mobile";
      leftDrawerView.modifyTheme(Feel.default, [[Feel.translucent, translucent ? 1 : void 0]]);
    }

    const leftDrawerButton = this.leftDrawerButton.view;
    if (leftDrawerButton !== null) {
      if (viewportIdiom === "mobile") {
        leftDrawerButton.display("flex");
      } else {
        leftDrawerButton.display("none");
      }
    }

    const rightDrawerView = this.rightDrawer.view;
    if (rightDrawerView !== null) {
      const surfaceView = this.surface.view;
      const fullBleed = surfaceView !== null && surfaceView.fullBleed;
      const translucent = fullBleed && this.viewportIdiom !== "mobile";
      rightDrawerView.modifyTheme(Feel.default, [[Feel.translucent, translucent ? 1 : void 0]]);
    }
  }

  protected setFullBleed(fullBleed: boolean, surfaceView: SurfaceView,
                         timing?: Timing | boolean): void {
    const rightPanelView = this.rightPanel.view;
    if (rightPanelView !== null) {
      rightPanelView.pointerEvents(fullBleed ? "none" : "auto");
    }

    const topBar = this.topBar.view;
    if (topBar !== null) {
      this.updateTopBarBleed(topBar, timing);
    }

    const searchBar = this.searchBar.view;
    if (searchBar !== null) {
      this.updateSearchBarBleed(searchBar, timing);
    }

    const leftDrawerView = this.leftDrawer.view;
    if (leftDrawerView !== null) {
      const translucent = fullBleed && this.viewportIdiom !== "mobile";
      leftDrawerView.modifyTheme(Feel.default, [[Feel.translucent, translucent ? 1 : void 0]]);
    }

    const rightDrawerView = this.rightDrawer.view;
    if (rightDrawerView !== null) {
      const translucent = fullBleed && this.viewportIdiom !== "mobile";
      rightDrawerView.modifyTheme(Feel.default, [[Feel.translucent, translucent ? 1 : void 0]]);
    }
  }

  protected updateTopBarBleed(topBar: HtmlView, timing?: Timing | boolean): void {
    const surfaceView = this.surface.view;
    if (surfaceView !== null) {
      const backgroundColor = surfaceView.getLookOr(Look.backgroundColor, null);
      if (backgroundColor !== null) {
        if (timing === void 0) {
          timing = surfaceView.getLook(Look.timing);
        }
        let gradient: LinearGradient;
        if (surfaceView.fullBleed.value) {
          gradient = LinearGradient.create("bottom", ColorStop.create(backgroundColor.alpha(0), 0),
                                                     ColorStop.create(backgroundColor.alpha(0), 100, 75));
        } else {
          gradient = LinearGradient.create("bottom", ColorStop.create(backgroundColor, 0),
                                                     ColorStop.create(backgroundColor.alpha(0), 100, 75));
        }
        topBar.backgroundImage.setState(gradient, timing, Affinity.Intrinsic);
      }
    }
  }

  protected updateSearchBarBleed(searchBar: HtmlView, timing?: Timing | boolean): void {
    const surfaceView = this.surface.view;
    if (surfaceView !== null) {
      const backgroundColor = surfaceView.getLookOr(Look.backgroundColor, null);
      if (backgroundColor !== null) {
        if (timing === void 0) {
          timing = surfaceView.getLook(Look.timing);
        }
        let gradient: LinearGradient;
        if (surfaceView.fullBleed || surfaceView.viewportIdiom !== "mobile") {
          gradient = LinearGradient.create("top", ColorStop.create(backgroundColor.alpha(0), 0),
                                                  ColorStop.create(backgroundColor.alpha(0), 100, 75));
        } else {
          gradient = LinearGradient.create("top", ColorStop.create(backgroundColor, 0),
                                                  ColorStop.create(backgroundColor.alpha(0), 100, 75));
        }
        searchBar.backgroundImage.setState(gradient, timing, Affinity.Intrinsic);
      }
    }
  }

  protected override willResize(viewContext: ViewContextType<this>): void {
    super.willResize(viewContext);

    const visualViewport = viewContext.viewport.visual;
    this.visualViewportWidth.setValue(visualViewport.width);
    this.visualViewportHeight.setValue(visualViewport.height);
    this.visualViewportPageLeft.setValue(visualViewport.pageLeft);
    this.visualViewportPageTop.setValue(visualViewport.pageTop);

    const safeArea = viewContext.viewport.safeArea;
    this.safeAreaInsetTop.setValue(safeArea.insetTop);
    this.safeAreaInsetRight.setValue(safeArea.insetRight);
    this.safeAreaInsetBottom.setValue(safeArea.insetBottom);
    this.safeAreaInsetLeft.setValue(safeArea.insetLeft);
  }

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly visualViewportWidth!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly visualViewportHeight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly visualViewportPageLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly visualViewportPageTop!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly safeAreaInsetTop!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly safeAreaInsetRight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly safeAreaInsetBottom!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, value: 0, strength: "strong"})
  readonly safeAreaInsetLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty<ShellView, number>({
    type: Number,
    constrain: true,
    strength: "strong",
    updateFlags: View.NeedsLayout,
  })
  readonly beamHeight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly topBarTop!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly topBarLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly topBarRight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly topBarHeight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly topBarPaddingLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly topBarPaddingRight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarTop!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarRight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarHeight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarPaddingLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarPaddingRight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchBarPaddingBottom!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly searchFieldHeight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly surfacePaddingTop!: ConstraintProperty<this, number>;

  @ConstraintProperty<ShellView, number>({
    type: Number,
    constrain: true,
    strength: "strong",
    didSetValue(newValue: number, oldValue: number): void {
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        surfaceView.requireUpdate(View.NeedsLayout);
      }
    },
  })
  readonly surfacePaddingRight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly surfacePaddingBottom!: ConstraintProperty<this, number>;

  @ConstraintProperty<ShellView, number>({
    type: Number,
    constrain: true,
    strength: "strong",
    didSetValue(newValue: number, oldValue: number): void {
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        surfaceView.requireUpdate(View.NeedsLayout);
      }
    },
  })
  readonly surfacePaddingLeft!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly leftDrawerWidth!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly rightPanelWidth!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly drawerWidth!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly activityHeight!: ConstraintProperty<this, number>;

  @Property<ShellView, ViewportInsets>({
    type: Object,
    value: {
      insetTop: 0,
      insetRight: 0,
      insetBottom: 0,
      insetLeft: 0,
    },
  })
  readonly edgeInsets!: Property<this, ViewportInsets>;

  @ViewRef<ShellView, BeamView>({
    type: BeamView,
    binds: true,
    initView(beamView: BeamView): void {
      this.constraint(beamView.height.constrain(), "eq", this.owner.beamHeight);
      beamView.zIndex.setState(9, Affinity.Intrinsic);
    },
  })
  readonly beam!: ViewRef<this, BeamView>;

  @ViewRef<ShellView, SurfaceView>({
    type: SurfaceView,
    binds: true,
    observes: true,
    initView(surfaceView: SurfaceView): void {
      surfaceView.addClass("surface")
                 .position("absolute")
                 .left(0)
                 .right(0)
                 .top(0)
                 .bottom(0)
                 .overflowX("auto")
                 .overflowY("auto")
                 .zIndex(0);

      this.constraint(surfaceView.top.constrain(), "eq", this.owner.beamHeight);
      this.constraint(surfaceView.paddingTop.constrain(), "eq", this.owner.surfacePaddingTop);
      this.constraint(surfaceView.paddingRight.constrain(), "eq", this.owner.surfacePaddingRight);
      this.constraint(surfaceView.paddingBottom.constrain(), "eq", this.owner.surfacePaddingBottom);
      this.constraint(surfaceView.paddingLeft.constrain(), "eq", this.owner.surfacePaddingLeft);
    },
    didAttachView(surfaceView: SurfaceView): void {
      surfaceView.on("scroll", this.owner.onSurfaceScroll);
    },
    willDetachView(surfaceView: SurfaceView): void {
      surfaceView.off("scroll", this.owner.onSurfaceScroll);
    },
    viewDidSetFullBleed(fullBleed: boolean, surfaceView: SurfaceView): void {
      this.owner.setFullBleed(fullBleed, surfaceView);
    },
  })
  readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<ShellView, HtmlView>({
    type: HtmlView,
    binds: true,
    observes: true,
    initView(topBar: HtmlView): void {
      topBar.addClass("top-bar")
            .display("flex")
            .position("absolute")
            .left(0)
            .right(0)
            .overflowX("hidden")
            .overflowY("hidden")
            .pointerEvents("none");

      this.constraint(topBar.top.constrain(), "eq", this.owner.topBarTop);
      this.constraint(topBar.left.constrain(), "eq", this.owner.topBarLeft);
      this.constraint(topBar.right.constrain(), "eq", this.owner.topBarRight);
      this.constraint(topBar.height.constrain(), "eq", this.owner.topBarHeight);

      this.owner.topLeftBar.insertView(topBar);
      this.owner.topRightBar.insertView(topBar);
    },
    viewDidApplyTheme(theme: ThemeMatrix, mood: MoodVector, timing: Timing | boolean, topBar: HtmlView): void {
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        this.owner.setFullBleed(surfaceView.fullBleed.value, surfaceView, timing);
      }
    },
  })
  readonly topBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellView, HtmlView>({
    type: HtmlView,
    initView(topLeftBar: HtmlView): void {
      topLeftBar.addClass("top-left-bar")
                .display("flex")
                .flexGrow(1)
                .flexShrink(0)
                .overflowX("hidden")
                .overflowY("hidden");

      this.constraint(topLeftBar.paddingLeft.constrain(), "eq", this.owner.topBarPaddingLeft);

      this.owner.leftDrawerButton.insertView(topLeftBar);
    },
  })
  readonly topLeftBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellView, HtmlView>({
    type: HtmlView,
    initView(topRightBar: HtmlView): void {
      topRightBar.addClass("top-right-bar")
                 .display("flex")
                 .flexShrink(0)
                 .overflowX("hidden")
                 .overflowY("hidden");

      this.constraint(topRightBar.paddingRight.constrain(), "eq", this.owner.topBarPaddingRight);
    },
  })
  readonly topRightBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellView, HtmlView>({
    type: HtmlView,
    binds: true,
    initView(searchBar: HtmlView): void {
      searchBar.addClass("search-bar")
               .display("flex")
               .alignItems("center")
               .position("absolute")
               .left(0)
               .right(0)
               .overflowX("hidden")
               .overflowY("hidden")
               .pointerEvents("none");

      this.constraint(searchBar.top.constrain(), "eq", this.owner.searchBarTop);
      this.constraint(searchBar.left.constrain(), "eq", this.owner.searchBarLeft);
      this.constraint(searchBar.right.constrain(), "eq", this.owner.searchBarRight);
      this.constraint(searchBar.height.constrain(), "eq", this.owner.searchBarHeight);

      this.constraint(searchBar.paddingLeft.constrain(), "eq", this.owner.searchBarPaddingLeft);
      this.constraint(searchBar.paddingRight.constrain(), "eq", this.owner.searchBarPaddingRight);
      this.constraint(searchBar.paddingBottom.constrain(), "eq", this.owner.searchBarPaddingBottom);

      this.owner.search.insertView(searchBar);
    },
  })
  readonly searchBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellView, InputTokenView>({
    type: InputTokenView,
    initView(searchView: InputTokenView): void {
      searchView.flexShrink(0)
                .marginLeft(2)
                .marginRight(2);

      this.constraint(searchView.height.constrain(), "eq", this.owner.searchFieldHeight);

      searchView.collapse();
    },
    createView(): InputTokenView {
      const searchView = InputTokenView.create();
      Object.defineProperty(searchView, "fillLook", {
        value: Look.iconColor,
        writable: true,
        enumerable: true,
        configurable: true,
      });
      Object.defineProperty(searchView, "placeholderLook", {
        value: Look.neutralColor,
        writable: true,
        enumerable: true,
        configurable: true,
      });
      return searchView;
    },
  })
  readonly search!: ViewRef<this, InputTokenView>;

  @ViewRef<ShellView, HtmlIconView>({
    type: HtmlIconView,
    binds: true,
    initView(brandView: HtmlIconView): void {
      brandView.addClass("brand");
      brandView.position.setState("absolute", Affinity.Intrinsic);
      brandView.width.setState(70, Affinity.Intrinsic);
      brandView.height.setState(44, Affinity.Intrinsic);
      brandView.iconWidth.setState(70, Affinity.Intrinsic);
      brandView.iconHeight.setState(44, Affinity.Intrinsic);
      brandView.pointerEvents.setState("none", Affinity.Intrinsic);

      this.constraint(brandView.left.constrain(), "eq", this.owner.width.times(0.5).minus(brandView.width.times(0.5)));
      this.constraint(brandView.top.constrain(), "eq", this.owner.beamHeight);
      this.constraint(brandView.height.constrain(), "eq", this.owner.topBarHeight);
    },
  })
  readonly brand!: ViewRef<this, HtmlIconView>;

  @ViewRef<ShellView, DrawerView>({
    type: DrawerView,
    binds: true,
    initView(rightPanelView: DrawerView): void {
      rightPanelView.dismiss(false);
      rightPanelView.flexDirection("column");
      rightPanelView.top.setState(null);
      rightPanelView.userSelect.setState("none", Affinity.Intrinsic);
      rightPanelView.placement("right");

      this.constraint(rightPanelView.top.constrain(), "eq", this.owner.beamHeight);
      this.constraint(rightPanelView.expandedWidth.constrain(), "eq", this.owner.drawerWidth);
      this.constraint(this.owner.rightPanelWidth.constrain(), "eq", rightPanelView.effectiveWidth);
      this.constraint(rightPanelView.paddingBottom.constrain(), "eq", this.owner.surfacePaddingBottom);

      rightPanelView.backgroundColor.setState(null);
      rightPanelView.boxShadow.setState(null);
    },
  })
  readonly rightPanel!: ViewRef<this, DrawerView>;

  @ViewRef<ShellView, ScrimView>({
    type: ScrimView,
    binds: true,
    initView(scrimView: ScrimView): void {
      this.constraint(scrimView.top.constrain(), "eq", this.owner.beamHeight);
    },
  })
  readonly scrim!: ViewRef<this, ScrimView>;

  @ViewRef<ShellView, DrawerView>({
    type: DrawerView,
    binds: true,
    initView(leftDrawerView: DrawerView): void {
      leftDrawerView.flexDirection("column");
      leftDrawerView.top.setState(null);
      leftDrawerView.placement("left");
      leftDrawerView.collapsedWidth.setState(70, Affinity.Intrinsic);
      leftDrawerView.modifyTheme(Feel.default, [[Feel.raised, 1]], false);
      leftDrawerView.backgroundColor.setLook(Look.backgroundColor);

      this.constraint(leftDrawerView.top.constrain(), "eq", this.owner.beamHeight);
      this.constraint(this.owner.leftDrawerWidth.constrain(), "eq", leftDrawerView.effectiveWidth);

      const leftDrawerButton = this.owner.leftDrawerButton.view;
      if (leftDrawerButton !== null) {
        leftDrawerButton.setDrawerView(leftDrawerView);
      }
    },
  })
  readonly leftDrawer!: ViewRef<this, DrawerView>;

  @ViewRef<ShellView, DrawerButton>({
    type: DrawerButton,
    initView(leftDrawerButton: DrawerButton): void {
      leftDrawerButton.flexShrink.setState(0, Affinity.Intrinsic);
      leftDrawerButton.height.setState(null, Affinity.Intrinsic);
      leftDrawerButton.pointerEvents.setState("auto", Affinity.Intrinsic);
      leftDrawerButton.iconWidth.setState(24, Affinity.Intrinsic);
      leftDrawerButton.iconHeight.setState(24, Affinity.Intrinsic);
      leftDrawerButton.setDrawerView(this.owner.leftDrawer.view);

      leftDrawerButton.constraint(leftDrawerButton.width.constrain(), "eq", leftDrawerButton.height);
    },
  })
  readonly leftDrawerButton!: ViewRef<this, DrawerButton>;

  @ViewRef<ShellView, DrawerView>({
    type: DrawerView,
    binds: true,
    initView(rightDrawerView: DrawerView): void {
      rightDrawerView.dismiss(false);
      rightDrawerView.flexDirection("column");
      rightDrawerView.top.setState(null);
      rightDrawerView.userSelect.setState("none", Affinity.Intrinsic);
      rightDrawerView.placement("right");
      rightDrawerView.modifyTheme(Feel.default, [[Feel.raised, 1]], false);
      rightDrawerView.backgroundColor.setLook(Look.backgroundColor);

      this.constraint(rightDrawerView.top.constrain(), "eq", this.owner.beamHeight);
      this.constraint(rightDrawerView.expandedWidth.constrain(), "eq", this.owner.drawerWidth);
      this.constraint(rightDrawerView.paddingBottom.constrain(), "eq", this.owner.surfacePaddingBottom);
    },
  })
  readonly rightDrawer!: ViewRef<this, DrawerView>;

  @ViewRef<ShellView, ActivityWindow>({
    type: ActivityWindow,
    binds: true,
    initView(activityWindow: ActivityWindow): void {
      activityWindow.placement(["below"]);
      activityWindow.setSource(this.owner);
      activityWindow.height.setAffinity(Affinity.Extrinsic);

      this.constraint(activityWindow.height.constrain(), "eq", this.owner.activityHeight);
    },
    createView(): ActivityWindow {
      const activityWindow = ActivityWindow.create();
      activityWindow.hideModal(false);
      return activityWindow;
    },
  })
  readonly activityWindow!: ViewRef<this, ActivityWindow>;

  @Provider<ShellView, ViewportService>({
    extends: ViewportProvider,
    type: ViewportService,
    observes: true,
    service: ViewportService.global(),
    serviceDidSetViewportIdiom(newViewportIdiom: ViewportIdiom, oldViewportIdiom: ViewportIdiom): void {
      this.owner.updateViewportIdiom(newViewportIdiom);
    },
    serviceDidReorient(orientation: OrientationType): void {
      this.owner.updateViewportIdiom(this.owner.viewportIdiom);
    },
  })
  override readonly viewportProvider!: ViewportProvider<this>;

  protected override didMount(): void {
    this.updateViewportIdiom(this.viewportIdiom);
    const surfaceView = this.surface.view;
    if (surfaceView !== null) {
      this.setFullBleed(surfaceView.fullBleed.value, surfaceView);
    }
    super.didMount();
  }

  protected override onLayout(viewContext: ViewContextType<this>): void {
    super.onLayout(viewContext);

    const safeArea = viewContext.viewport.safeArea;
    this.edgeInsets.setValue({
      insetTop: 0,
      insetRight: safeArea.insetRight,
      insetBottom: safeArea.insetBottom,
      insetLeft: safeArea.insetLeft,
    }, Affinity.Intrinsic);
  }

  protected onSurfaceScroll(event: Event): void {
    const surfaceView = this.surface.view;
    if (surfaceView !== null) {
      surfaceView.requireUpdate(View.NeedsScroll);
    }
  }
}
