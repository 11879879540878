// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Mutable} from "@swim/util";
import {ModelContextType, ModelFlags, Model} from "@swim/model";
import {StatusGroup} from "../status/StatusGroup";
import {IndicatorMap} from "./IndicatorMap";
import {IndicatedTrait} from "./IndicatedTrait";

/** @public */
export class IndicatedGroup extends StatusGroup {
  constructor() {
    super();
    this.indicatorMap = IndicatorMap.empty();
  }

  readonly indicatorMap: IndicatorMap;

  /** @internal */
  protected setIndicatorMap(indicatorMap: IndicatorMap): void {
    (this as Mutable<this>).indicatorMap = indicatorMap;
  }

  protected override needsUpdate(updateFlags: ModelFlags, immediate: boolean): ModelFlags {
    updateFlags = super.needsUpdate(updateFlags, immediate);
    const propagateFlags = updateFlags & (Model.NeedsAggregate | Model.NeedsReconcile);
    if (propagateFlags !== 0) {
      this.setFlags(this.flags | propagateFlags);
    }
    return updateFlags;
  }

  protected override needsRefresh(refreshFlags: ModelFlags, modelContext: ModelContextType<this>): ModelFlags {
    if ((this.flags & Model.NeedsReconcile) === 0) {
      refreshFlags &= ~Model.NeedsReconcile;
    }
    return refreshFlags;
  }

  protected override refreshChildren(refreshFlags: ModelFlags, modelContext: ModelContextType<this>,
                                     refreshChild: (this: this, child: Model, refreshFlags: ModelFlags,
                                                    modelContext: ModelContextType<this>) => void): void {
    if ((refreshFlags & Model.NeedsReconcile) !== 0) {
      this.reconcileChildIndicatorMaps(refreshFlags, modelContext, refreshChild);
    } else {
      super.refreshChildren(refreshFlags, modelContext, refreshChild);
    }
  }

  protected reconcileChildIndicatorMaps(refreshFlags: ModelFlags, modelContext: ModelContextType<this>,
                                        refreshChild: (this: this, child: Model, refreshFlags: ModelFlags,
                                                       modelContext: ModelContextType<this>) => void): void {
    // Collect indicator types when reconciling child models.
    let indicatorMap = IndicatorMap.empty();

    type self = this;
    function reconcileChildIndicatorMap(this: self, child: Model, refreshFlags: ModelFlags,
                                        modelContext: ModelContextType<self>): void {
      refreshChild.call(this, child, refreshFlags, modelContext);
      const childIndicatedTrait = child.getTrait(IndicatedTrait);
      if (childIndicatedTrait !== null) {
        indicatorMap = indicatorMap.merged(childIndicatedTrait.indicatorMap);
      }
    }
    super.refreshChildren(refreshFlags, modelContext, reconcileChildIndicatorMap);

    this.setIndicatorMap(indicatorMap);
  }

  static override MountFlags: ModelFlags = StatusGroup.MountFlags | Model.NeedsAggregate | Model.NeedsReconcile;
  static override InsertChildFlags: ModelFlags = StatusGroup.InsertChildFlags | Model.NeedsAggregate | Model.NeedsReconcile;
  static override RemoveChildFlags: ModelFlags = StatusGroup.RemoveChildFlags | Model.NeedsAggregate | Model.NeedsReconcile;
}
