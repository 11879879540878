// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class, Initable} from "@swim/util";
import {MemberFastenerClass, Property} from "@swim/component";
import {ViewportInsets, AnyView, View, ViewRef, ViewSet} from "@swim/view";
import {HtmlViewInit, HtmlView} from "@swim/dom";
import type {WidgetViewObserver} from "./WidgetViewObserver";

/** @public */
export class WidgetView extends HtmlView {
  override readonly observerType?: Class<WidgetViewObserver>;

  @Property<WidgetView, ViewportInsets | null>({type: Object, inherits: true, value: null})
  readonly edgeInsets!: Property<this, ViewportInsets | null>;

  @ViewRef<WidgetView, HtmlView>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(headerView: HtmlView): void {
      this.owner.headerTitle.insertView(headerView);
      this.owner.headerSubtitle.insertView(headerView);
    },
    willAttachView(headerView: HtmlView): void {
      this.owner.callObservers("viewWillAttachHeader", headerView, this.owner);
    },
    didDetachView(headerView: HtmlView): void {
      this.owner.callObservers("viewDidDetachHeader", headerView, this.owner);
    },
    insertChild(parent: View, child: HtmlView, target: View | null, key: string | undefined): void {
      if (target !== null) {
        parent.insertChild(child, target, key);
      } else {
        parent.prependChild(child, key);
      }
    },
  })
  readonly header!: ViewRef<this, HtmlView>;
  static readonly header: MemberFastenerClass<WidgetView, "header">;

  protected createHeaderTitle(text?: string): HtmlView | null {
    const titleView = HtmlView.create();
    if (text !== void 0) {
      titleView.text(text);
    }
    return titleView;
  }

  @ViewRef<WidgetView, HtmlView & Initable<HtmlViewInit | string>, {createView(value?: string): HtmlView}>({
    implements: true,
    key: "title",
    type: HtmlView,
    binds: true,
    willAttachView(titleView: HtmlView): void {
      this.owner.callObservers("viewWillAttachHeaderTitle", titleView, this.owner);
    },
    didDetachView(titleView: HtmlView): void {
      this.owner.callObservers("viewDidDetachHeaderTitle", titleView, this.owner);
    },
    get parentView(): HtmlView | null {
      return this.owner.header.view;
    },
    createView(value?: string): HtmlView {
      const titleView = HtmlView.create();
      if (value !== void 0) {
        titleView.text(value);
      }
      return titleView;
    },
    fromAny(value: AnyView<HtmlView> | string): HtmlView {
      if (typeof value === "string") {
        return this.createView(value);
      } else {
        return HtmlView.fromAny(value);
      }
    },
  })
  readonly headerTitle!: ViewRef<this, HtmlView & Initable<HtmlViewInit | string>> & {create(value?: string): HtmlView};
  static readonly headerTitle: MemberFastenerClass<WidgetView, "headerTitle">;

  @ViewRef<WidgetView, HtmlView & Initable<HtmlViewInit | string>, {createView(value?: string): HtmlView}>({
    implements: true,
    key: "subtitle",
    type: HtmlView,
    binds: true,
    willAttachView(subtitleView: HtmlView): void {
      this.owner.callObservers("viewWillAttachHeaderSubtitle", subtitleView, this.owner);
    },
    didDetachView(subtitleView: HtmlView): void {
      this.owner.callObservers("viewDidDetachHeaderSubtitle", subtitleView, this.owner);
    },
    get parentView(): HtmlView | null {
      return this.owner.header.view;
    },
    createView(value?: string): HtmlView {
      const subtitleView = HtmlView.create();
      if (value !== void 0) {
        subtitleView.text(value);
      }
      return subtitleView;
    },
    fromAny(value: HtmlView | string): HtmlView {
      if (typeof value === "string") {
        return this.createView(value);
      } else {
        return HtmlView.fromAny(value);
      }
    },
  })
  readonly headerSubtitle!: ViewRef<this, HtmlView & Initable<HtmlViewInit | string>> & {create(value?: string): HtmlView};
  static readonly headerSubtitle: MemberFastenerClass<WidgetView, "headerSubtitle">;

  @ViewRef<WidgetView, HtmlView>({
    key: true,
    type: HtmlView,
    binds: true,
    willAttachView(footerView: HtmlView): void {
      this.owner.callObservers("viewWillAttachFooter", footerView, this.owner);
    },
    didDetachView(footerView: HtmlView): void {
      this.owner.callObservers("viewDidDetachFooter", footerView, this.owner);
    },
  })
  readonly footer!: ViewRef<this, HtmlView>;
  static readonly footer: MemberFastenerClass<WidgetView, "footer">;

  @ViewSet<WidgetView, HtmlView>({
    type: HtmlView,
    binds: true,
    willAttachView(gadgetView: HtmlView, targetView: View | null): void {
      this.owner.callObservers("viewWillAttachGadget", gadgetView, targetView, this.owner);
    },
    didDetachView(gadgetView: HtmlView): void {
      this.owner.callObservers("viewDidDetachGadget", gadgetView, this.owner);
    },
    detectView(gadgetView: View): HtmlView | null {
      return null;
    },
  })
  readonly gadgets!: ViewSet<this, HtmlView>;
}
