// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Uri} from "@swim/uri";
import {Model, ModelRef, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {AgentsEntity} from "./AgentsEntity";
import {FabricEntity} from "./FabricEntity";

/** @public */
export class PlaneGroup extends EntityGroup {
  constructor() {
    super();
    this.isSorted(false);
    this.initGroup();
  }

  protected initGroup(): void {
    this.agents.insertModel();
    this.fabric.insertModel();
  }

  @ModelRef<PlaneGroup, Model>({
    key: true,
    type: Model,
    binds: true,
    createModel(): Model {
      const metaAgentsUri = Uri.parse("swim:meta:mesh");
      const agentsModel = new Model();

      const entityTrait = new AgentsEntity(metaAgentsUri);
      entityTrait.title.setValue("Agents");
      agentsModel.setTrait("entity", entityTrait);

      agentsModel.setTrait("selectable", new SelectableTrait());
      agentsModel.setTrait("status", new StatusTrait());
      agentsModel.setTrait("indicated", new IndicatedTrait());
      agentsModel.setTrait("pulse", new PulseTrait(metaAgentsUri));

      return agentsModel;
    },
  })
  readonly agents!: ModelRef<this, Model>;

  @ModelRef<PlaneGroup, Model>({
    key: true,
    type: Model,
    binds: true,
    createModel(): Model {
      const metaEdgeUri = Uri.parse("swim:meta:edge");
      const fabricModel = new Model();

      const entityTrait = new FabricEntity(metaEdgeUri);
      entityTrait.title.setValue("Fabric");
      fabricModel.setTrait("entity", entityTrait);

      fabricModel.setTrait("selectable", new SelectableTrait());
      fabricModel.setTrait("status", new StatusTrait());
      fabricModel.setTrait("indicated", new IndicatedTrait());
      fabricModel.setTrait("pulse", new PulseTrait(metaEdgeUri));

      return fabricModel;
    },
  })
  readonly fabric!: ModelRef<this, Model>;
}
