// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Mutable, Class, Equals} from "@swim/util";
import type {IndicatorType} from "./IndicatorType";
import {IndicatorTrait} from "./IndicatorTrait";
import type {ValueIndicatorTraitObserver} from "./ValueIndicatorTraitObserver";

/** @public */
export class ValueIndicatorTrait<T = unknown> extends IndicatorTrait {
  constructor(indicatorType: IndicatorType) {
    super(indicatorType);
    this.value = void 0;
  }

  override readonly observerType?: Class<ValueIndicatorTraitObserver>;

  readonly value!: T | undefined;

  setValue(newValue: T | undefined): void {
    const oldValue = this.value;
    if (!Equals(oldValue, newValue)) {
      this.willSetValue(newValue, oldValue);
      (this as Mutable<this>).value = newValue;
      this.onSetValue(newValue, oldValue);
      this.didSetValue(newValue, oldValue);
    }
  }

  protected willSetValue(newValue: T | undefined, oldValue: T | undefined): void {
    this.callObservers("indicatorWillSetValue", newValue, oldValue, this);
  }

  protected onSetValue(newValue: T | undefined, oldValue: T | undefined): void {
    // hook
  }

  protected didSetValue(newValue: T | undefined, oldValue: T | undefined): void {
    this.callObservers("indicatorDidSetValue", newValue, oldValue, this);
  }

  get formattedValue(): string {
    let formatted: string | undefined;
    const value = this.value;
    const observers = this.observers;
    for (let i = 0, n = observers.length; i < n; i += 1) {
      const observer = observers[i]!;
      if (observer.formatIndicator !== void 0) {
        formatted = observer.formatIndicator(value, this) as string | undefined;
        if (formatted !== void 0) {
          break;
        }
      }
    }
    if (formatted === void 0) {
      formatted = "" + value;
    }
    return formatted;
  }
}
