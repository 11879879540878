// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Affinity, Property, Provider} from "@swim/component";
import {
  SelectionOptions,
  SelectionService,
  SelectionServiceObserver,
  Model,
  ModelRef,
  TraitRef,
} from "@swim/model";
import {ColorStop, LinearGradient} from "@swim/style";
import {Look, Feel, Mood, MoodVector, Theme} from "@swim/theme";
import {ThemeService, PositionGestureInput, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Graphics, VectorIcon, HtmlIconView} from "@swim/graphics";
import {
  ControllerFlags,
  HistoryState,
  HistoryService,
  HistoryProvider,
  Controller,
  ControllerRef,
} from "@swim/controller";
import {IconButton} from "@swim/button";
import type {InputTokenView} from "@swim/token";
import {
  ColLayout,
  TableLayout,
  TextCellView,
  IconCellView,
  LeafView,
  RowView,
  TableView,
} from "@swim/table";
import {DrawerView, DrawerButton} from "@swim/window";
import {
  Status,
  StatusVector,
  StatusTrait,
  EntityTrait,
  DomainTrait,
  DomainGroup,
  SessionModel,
} from "@swim/domain";
import {
  PrismService,
  PrismProvider,
  ActivityController,
  ActivityPlugin,
  SurfaceView,
} from "@swim/prism";
import {CollectorController} from "../collector/CollectorController";
import {ReflectorController} from "../reflector/ReflectorController";
import {MagnifierController} from "../magnifier/MagnifierController";
import {ActivityWindow} from "../activity/ActivityWindow";
import {BeamView} from "../beam/BeamView";
import {ShellView} from "./ShellView";

/** @public */
export class ShellController extends Controller {
  constructor() {
    super();
    this.initShell();
  }

  protected initShell(): void {
    this.reflector.insertController();
    this.collector.insertController();
    this.magnifier.insertController();
  }

  get brandIcon(): Graphics {
    return ShellController.brandIcon;
  }

  get settingsIcon(): Graphics {
    return ShellController.settingsIcon;
  }

  get inventoryIcon(): Graphics {
    return ShellController.inventoryIcon;
  }

  @Property<ShellController, boolean>({type: Boolean, value: false})
  readonly fullBleed!: Property<this, boolean>;

  @ViewRef<ShellController, ShellView>({
    type: ShellView,
    initView(rootView: ShellView): void {
      this.owner.beam.setView(rootView.beam.view);
      this.owner.surface.setView(rootView.surface.view);
      this.owner.topLeftBar.setView(rootView.topLeftBar.view);
      this.owner.topRightBar.setView(rootView.topRightBar.view);
      this.owner.searchBar.setView(rootView.searchBar.view);
      this.owner.search.setView(rootView.search.view);
      this.owner.brand.setView(rootView.brand.view);
      this.owner.rightPanel.setView(rootView.rightPanel.view);
      this.owner.leftDrawer.setView(rootView.leftDrawer.view);
      this.owner.leftDrawerButton.setView(rootView.leftDrawerButton.view);
      this.owner.rightDrawer.setView(rootView.rightDrawer.view);
      this.owner.activityWindow.setView(rootView.activityWindow.view);
    },
  })
  readonly root!: ViewRef<this, ShellView>;

  @ViewRef<ShellController, BeamView>({
    type: BeamView,
    initView(beamView: BeamView): void {
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        this.owner.applyStatus(statusTrait.statusVector);
      }
    },
  })
  readonly beam!: ViewRef<this, BeamView>;

  @ViewRef<ShellController, SurfaceView>({
    type: SurfaceView,
    observes: true,
    initView(surfaceView: SurfaceView): void {
      const reflectorController = this.owner.reflector.controller;
      if (reflectorController !== null) {
        reflectorController.surface.setView(surfaceView);
      }
    },
    viewDidSetFullBleed(fullBleed: boolean, surfaceView: SurfaceView): void {
      this.owner.fullBleed.setValue(fullBleed, Affinity.Intrinsic);
    },
  })
  readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<ShellController, HtmlView>({
    type: HtmlView,
  })
  readonly topLeftBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellController, HtmlView>({
    type: HtmlView,
    initView(topRightBar: HtmlView): void {
      this.owner.inventoryButton.insertView(topRightBar);

      const reflectorController = this.owner.reflector.controller;
      if (reflectorController !== null) {
        reflectorController.toolbar.setView(topRightBar);
      }
    },
  })
  readonly topRightBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellController, HtmlView>({
    type: HtmlView,
  })
  readonly searchBar!: ViewRef<this, HtmlView>;

  @ViewRef<ShellController, HtmlView>({
    type: HtmlView,
    initView(searchView: InputTokenView): void {
      const collectorController = this.owner.collector.controller;
      if (collectorController !== null) {
        collectorController.search.setView(searchView);
      }
    },
  })
  readonly search!: ViewRef<this, HtmlView>;

  @ViewRef<ShellController, HtmlIconView>({
    type: HtmlIconView,
    initView(brandView: HtmlIconView): void {
      brandView.graphics.setState(this.owner.brandIcon, Affinity.Intrinsic);
    },
  })
  readonly brand!: ViewRef<this, HtmlIconView>;

  @ViewRef<ShellController, DrawerView>({
    type: DrawerView,
    initView(rightPanelView: DrawerView): void {
      const magnifierController = this.owner.magnifier.controller;
      if (magnifierController !== null) {
        magnifierController.panel.setView(rightPanelView);
      }
    },
  })
  readonly rightPanel!: ViewRef<this, DrawerView>;

  @ViewRef<ShellController, DrawerView>({
    type: DrawerView,
    initView(leftDrawerView: DrawerView): void {
      this.owner.brandItem.insertView(leftDrawerView);
      this.owner.mirrorList.insertView(leftDrawerView);
      this.owner.domainList.insertView(leftDrawerView);
      this.owner.settingsItem.insertView(leftDrawerView);
    },
  })
  readonly leftDrawer!: ViewRef<this, DrawerView>;

  @ViewRef<ShellController, DrawerButton>({
    type: DrawerButton,
  })
  readonly leftDrawerButton!: ViewRef<this, DrawerButton>;

  @ViewRef<ShellController, DrawerView>({
    type: DrawerView,
    initView(rightDrawerView: DrawerView): void {
      const reflectorController = this.owner.reflector.controller;
      if (reflectorController !== null) {
        reflectorController.drawer.setView(rightDrawerView);
      }
    },
  })
  readonly rightDrawer!: ViewRef<this, DrawerView>;

  @ViewRef<ShellController, IconButton>({
    type: IconButton,
    observes: true,
    initView(inventoryButton: IconButton): void {
      inventoryButton.flexShrink.setState(0, Affinity.Intrinsic);
      inventoryButton.height.setState(null, Affinity.Intrinsic);
      inventoryButton.pointerEvents.setState("auto", Affinity.Intrinsic);
      inventoryButton.visibility.setState("hidden", Affinity.Intrinsic);
      inventoryButton.iconWidth.setState(32, Affinity.Intrinsic);
      inventoryButton.iconHeight.setState(32, Affinity.Intrinsic);
      inventoryButton.pushIcon(this.owner.inventoryIcon);

      inventoryButton.constraint(inventoryButton.width.constrain(), "eq", inventoryButton.height);
    },
    buttonDidPress(inventoryButton: IconButton): void {
      const sessionModel = this.owner.session.model;
      const selections = sessionModel !== null ? sessionModel.selectionProvider.service.selections : null;
      if (selections !== null && selections.length !== 0) {
        const entityTrait = selections[selections.length - 1]!.getTrait(EntityTrait);
        const activityWindow = this.owner.activityWindow.view;
        if (entityTrait !== null && activityWindow !== null) {
          const activityController = this.owner.getActivityController(entityTrait);
          if (activityController !== null) {
            this.owner.activity.setController(activityController);
            activityController.window.setView(activityWindow);
            this.owner.showActivity(activityWindow);
          }
        }
      }
    },
  })
  readonly inventoryButton!: ViewRef<this, IconButton>;

  @ViewRef<ShellController, RowView>({
    type: RowView,
    observes: true,
    initView(rowView: RowView): void {
      rowView.modifyMood(Feel.default, [[Feel.unselected, 1]], false);
      rowView.userSelect("none")
             .hovers(true)
             .rowHeight(48)
             .layout(ShellController.leftListLayout);
      rowView.getOrCreateCell("icon", IconCellView)
          .iconWidth(70, Affinity.Intrinsic)
          .iconHeight(44, Affinity.Intrinsic)
          .graphics(this.owner.brandIcon, Affinity.Intrinsic);

      const rootView = this.owner.root.view!;
      rootView.constraint(rowView.rowHeight.constrain(), "eq", rootView.topBarHeight);
    },
    viewDidPressLeaf(input: PositionGestureInput, event: Event | null,
                     leafView: LeafView, rowView: RowView): void {
      const leftDrawerView = this.owner.leftDrawer.view;
      if (leftDrawerView !== null) {
        leftDrawerView.toggle();
      }
    },
  })
  readonly brandItem!: ViewRef<this, RowView>;

  @ViewRef<ShellController, TableView>({
    type: TableView,
    initView(listView: TableView): void {
      listView.modifyMood(Feel.default, [[Feel.unselected, 1]], false);
      listView.flexGrow(1)
              .userSelect("none")
              .backgroundColor(null)
              .hovers(true)
              .rowHeight(44)
              .layout(ShellController.leftListLayout);

      const reflectorController = this.owner.reflector.controller;
      if (reflectorController !== null) {
        reflectorController.mirrorList.setView(listView);
      }
    },
  })
  readonly mirrorList!: ViewRef<this, TableView>;

  @ViewRef<ShellController, TableView>({
    type: TableView,
    initView(listView: TableView): void {
      listView.modifyMood(Feel.default, [[Feel.unselected, 1]], false);
      listView.flexGrow(0)
              .userSelect("none")
              .backgroundColor(null)
              .hovers(true)
              .rowHeight(44)
              .layout(ShellController.leftListLayout);

      const collectorController = this.owner.collector.controller;
      if (collectorController !== null) {
        collectorController.domainList.setView(listView);
      }
    },
  })
  readonly domainList!: ViewRef<this, TableView>;

  @ViewRef<ShellController, RowView>({
    type: RowView,
    initView(rowView: RowView): void {
      rowView.modifyMood(Feel.default, [[Feel.unselected, 1]], false);
      rowView.userSelect("none")
             .hovers(true)
             .rowHeight(48)
             .layout(ShellController.leftListLayout);
      rowView.getOrCreateCell("icon", IconCellView)
             .iconWidth(20, Affinity.Intrinsic)
             .iconHeight(20, Affinity.Intrinsic)
             .graphics(this.owner.settingsIcon, Affinity.Intrinsic);
      rowView.getOrCreateCell("title", TextCellView)
             .content("Settings");
    },
  })
  readonly settingsItem!: ViewRef<this, RowView>;

  protected getActivityController(entityTrait: EntityTrait): ActivityController | null {
    const plugins = this.prismProvider.plugins;
    for (let i = 0, n = plugins.length; i < n; i += 1) {
      const plugin = plugins[i];
      if (plugin instanceof ActivityPlugin) {
        const activityController = plugin.createActivity(entityTrait);
        if (activityController !== null) {
          return activityController;
        }
      }
    }
    return null;
  }

  protected showActivity(activityWindow: ActivityWindow): void {
    activityWindow.modalProvider.presentModal(activityWindow, {modal: true});
  }

  @ViewRef<ShellController, ActivityWindow>({
    type: ActivityWindow,
    observes: true,
    initView(activityWindow: ActivityWindow): void {
      activityWindow.display("none");
    },
    popoverWillShow(activityWindow: ActivityWindow): void {
      activityWindow.display("block");
    },
    popoverDidShow(activityWindow: ActivityWindow): void {
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        surfaceView.setCulled(true);
        surfaceView.visibility.setState("hidden", Affinity.Intrinsic);
      }
    },
    popoverWillHide(activityWindow: ActivityWindow): void {
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        surfaceView.visibility.setState(void 0, Affinity.Intrinsic);
        surfaceView.setCulled(false);
      }
    },
    popoverDidHide(activityWindow: ActivityWindow): void {
      activityWindow.display("none");

      const activityController = this.owner.activity.controller;
      if (activityController !== null) {
        activityController.window.setView(null);
        activityController.remove();
      }

      //const sessionModel = this.owner.session.model;
      //if (sessionModel !== null) {
      //  sessionModel.unselectAll();
      //}
    },
  })
  readonly activityWindow!: ViewRef<this, ActivityWindow>;

  @ControllerRef<ShellController, ReflectorController>({
    key: true,
    type: ReflectorController,
    binds: true,
    initController(reflectorController: ReflectorController): void {
      const domainGroup = this.owner.domains.model;
      if (domainGroup !== null) {
        reflectorController.domains.setModel(domainGroup);
      }
    },
  })
  readonly reflector!: ControllerRef<this, ReflectorController>;

  @ControllerRef<ShellController, CollectorController>({
    key: true,
    type: CollectorController,
    binds: true,
    initController(collectorController: CollectorController): void {
      const domainGroup = this.owner.domains.model;
      if (domainGroup !== null) {
        collectorController.domains.setModel(domainGroup);
      }
    },
  })
  readonly collector!: ControllerRef<this, CollectorController>;

  @ControllerRef<ShellController, MagnifierController>({
    key: true,
    type: MagnifierController,
    binds: true,
    initController(magnifierController: MagnifierController): void {
      const rightPanelView = this.owner.rightPanel.view;
      if (rightPanelView !== null) {
        magnifierController.panel.setView(rightPanelView);
      }
      const sessionModel = this.owner.session.model;
      if (sessionModel !== null) {
        magnifierController.session.setModel(sessionModel);
      }
    },
  })
  readonly magnifier!: ControllerRef<this, MagnifierController>;

  @ControllerRef<ShellController, ActivityController>({
    key: true,
    type: ActivityController,
    binds: true,
  })
  readonly activity!: ControllerRef<this, ActivityController>;

  @ModelRef<ShellController, SessionModel, SelectionServiceObserver>({
    implements: true,
    type: SessionModel,
    initModel(sessionModel: SessionModel): void {
      this.owner.domains.setModel(sessionModel.domains.model);
      const statusTrait = sessionModel.getTrait(StatusTrait);
      if (statusTrait !== null) {
        this.owner.status.setTrait(statusTrait);
      }
      const magnifierController = this.owner.magnifier.controller;
      if (magnifierController !== null) {
        magnifierController.session.setModel(sessionModel);
      }
    },
    didAttachModel(sessionModel: SessionModel): void {
      const selectionService = sessionModel.selectionProvider.service;
      if (selectionService !== null) {
        selectionService.observe(this as SelectionServiceObserver);
      }
    },
    willDetachModel(sessionModel: SessionModel): void {
      const selectionService = sessionModel.selectionProvider.service;
      if (selectionService !== null) {
        selectionService.unobserve(this as SelectionServiceObserver);
      }
    },
    serviceDidSelect(model: Model, index: number, options: SelectionOptions | null, selectionService: SelectionService): void {
      const inventoryButton = this.owner.inventoryButton.view;
      if (inventoryButton !== null) {
        inventoryButton.visibility.setState("visible", Affinity.Intrinsic);
      }
      //const activityWindow = this.owner.activityWindow.view;
      //if (activityWindow !== null) {
      //  const selections = selectionService.selections;
      //  const entityTrait = selections[selections.length - 1]!.getTrait(EntityTrait);
      //  const activityController = this.owner.getActivityController(entityTrait);
      //  if (activityController !== null) {
      //    this.owner.activity.setController(activityController);
      //    activityController.window.setView(activityWindow);
      //    this.owner.showActivity(activityWindow);
      //  }
      //}
    },
    serviceWillUnselect(model: Model, selectionService: SelectionService): void {
      if (selectionService.selections.length === 1) {
        const inventoryButton = this.owner.inventoryButton.view;
        if (inventoryButton !== null) {
          inventoryButton.visibility.setState("hidden", Affinity.Intrinsic);
        }
      }
    },
  })
  readonly session!: ModelRef<this, SessionModel>;

  @TraitRef<ShellController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      this.owner.applyStatus(statusTrait.statusVector);
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      this.owner.applyStatus(newStatusVector);
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;

  addDomain(query: string): DomainTrait | null {
    let domainTrait: DomainTrait | null = null;
    const collectorController = this.collector.controller;
    const domainGroup = this.domains.model;
    if (collectorController !== null && domainGroup !== null) {
      domainTrait = collectorController.createDomain(query);
      if (domainTrait !== null && domainTrait.model !== null) {
        domainGroup.appendChild(domainTrait.model);
      }
    }
    return domainTrait;
  }

  @ModelRef<ShellController, DomainGroup>({
    type: DomainGroup,
    initModel(domainGroup: DomainGroup): void {
      const reflectorController = this.owner.reflector.controller;
      if (reflectorController !== null) {
        reflectorController.domains.setModel(domainGroup);
      }
      const collectorController = this.owner.collector.controller;
      if (collectorController !== null) {
        collectorController.domains.setModel(domainGroup);
      }
    },
  })
  readonly domains!: ModelRef<this, DomainGroup>;

  @Provider<ShellController, PrismService>({
    extends: PrismProvider,
    type: PrismService,
    observes: false,
    service: PrismService.global(),
  })
  readonly prismProvider!: PrismProvider<this>;

  @Provider<ShellController, HistoryService>({
    extends: HistoryProvider,
    type: HistoryService,
    observes: true,
    service: HistoryService.global(),
    serviceDidPopHistory(historyState: HistoryState): void {
      this.owner.updateHistoryState(historyState);
    },
  })
  override readonly historyProvider!: HistoryProvider<this>;

  protected override onMount(): void {
    super.onMount();
    this.updateHistoryState(this.historyProvider.historyState);
  }

  protected updateHistoryState(historyState: HistoryState): void {
    const themeOverride = historyState.permanent.theme;
    if (themeOverride === "light") {
      ThemeService.global().setTheme(Theme.light);
    } else if (themeOverride !== "auto") {
      ThemeService.global().setTheme(Theme.dark);
    }
  }

  protected applyStatus(statusVector: StatusVector): void {
    const beamView = this.beam.view;
    if (beamView !== null) {
      let inactive = statusVector.get(Status.inactive) || 0;
      let normal = statusVector.get(Status.normal) || 0;
      let warning = statusVector.get(Status.warning) || 0;
      let alert = statusVector.get(Status.alert) || 0;
      let total = inactive + normal + warning + alert;
      const minWarningRatio = 0.1;
      const minAlertRatio = 0.1;
      if (0 < warning && warning / total < minWarningRatio) {
        warning = (inactive + normal + alert) * minWarningRatio / (1 - minWarningRatio);
        total = inactive + normal + warning + alert;
      }
      if (0 < alert && alert / total < minAlertRatio) {
        alert = (inactive + normal + warning) * minAlertRatio / (1 - minAlertRatio);
        total = inactive + normal + warning + alert;
      }
      if (total !== 0) {
        inactive /= total;
        normal /= total;
        warning /= total;
        alert /= total;
      }
      inactive = Math.round(100 * inactive);
      normal = Math.round(100 * normal);
      warning = Math.round(100 * warning);
      alert = Math.round(100 * alert);
      const primaryMood = MoodVector.of([Feel.primary, 1], [Feel.darker, 0.5]);
      const warningMood = MoodVector.of([Feel.warning, 1], [Feel.darker, 0.5]);
      const alertMood = MoodVector.of([Feel.alert, 1], [Feel.darker, 0.5]);
      const inactiveMood = MoodVector.of([Feel.inactive, 1], [Feel.darker, 0.5]);
      let leftStop: ColorStop;
      let inactiveStop: ColorStop;
      let normalStop: ColorStop;
      let warningStop: ColorStop;
      let alertStop: ColorStop;
      let rightStop: ColorStop;
      if (alert === 0) {
        if (warning === 0) {
          if (normal === 0) {
            if (inactive === 0) { // empty
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 50);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 50);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
            } else { // inactive
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 50);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 100);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 100);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 100);
            }
          } else {
            if (inactive === 0) { // normal
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 50);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
            } else { // inactive, normal
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, inactive + normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 100);
            }
          }
        } else {
          if (normal === 0) {
            if (inactive === 0) { // warning
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 0);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 50);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
            } else { // inactive, warning
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, inactive + warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
            }
          } else {
            if (inactive === 0) { // normal, warning
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, normal + warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
            } else { // inactive, normal, warning
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, inactive + normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, inactive + normal + warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 100);
            }
          }
        }
      } else {
        if (warning === 0) {
          if (normal === 0) {
            if (inactive === 0) { // alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 0);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 0);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 50);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            } else { // inactive, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, inactive + alert / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            }
          } else {
            if (inactive === 0) { // normal, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, normal / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, normal + alert / 2);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            } else { // inactive, normal, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, inactive + normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, inactive + normal / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, inactive + normal + alert / 2);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            }
          }
        } else {
          if (normal === 0) {
            if (inactive === 0) { // warning, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, 0);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, warning + alert / 2);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            } else { // inactive, warning, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, inactive + warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, inactive + warning + alert / 2);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            }
          } else {
            if (inactive === 0) { // normal, warning, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, 0);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, normal + warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, normal + warning + alert / 2);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            } else { // inactive, normal, warning, alert
              leftStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, 0);
              inactiveStop = ColorStop.create(beamView.getLook(Look.accentColor, inactiveMood)!, inactive / 2);
              normalStop = ColorStop.create(beamView.getLook(Look.accentColor, primaryMood)!, inactive + normal / 2);
              warningStop = ColorStop.create(beamView.getLook(Look.accentColor, warningMood)!, inactive + normal + warning / 2);
              alertStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, inactive + normal + warning + alert / 2);
              rightStop = ColorStop.create(beamView.getLook(Look.accentColor, alertMood)!, 100);
            }
          }
        }
      }
      const gradient = LinearGradient.create("right", leftStop, inactiveStop, normalStop, warningStop, alertStop, rightStop);
      beamView.backgroundImage(gradient, beamView.getLook(Look.timing, Mood.ambient));
    }
  }

  @Lazy
  static get leftListLayout(): TableLayout {
    return TableLayout.create([
      ColLayout.create("icon", 0, 0, 70, false, true),
      ColLayout.create("title", 1, 0, 0, false, false, Look.color),
    ]);
  }

  @Lazy
  static get brandIcon(): Graphics {
    return VectorIcon.create(70, 44, "M18.4,19.8C18.4,18.5,17.3,17.4,15.9,17.4L8.9,17.4C7.6,17.4,6.5,18.5,6.5,19.8L6.5,21.6C6.5,22.9,7.6,24,8.9,24L15.9,24C16.3,24,16.7,24.4,16.7,24.8L16.7,26.6C16.7,27,16.3,27.4,15.9,27.4L8.9,27.4C8.5,27.4,8.2,27,8.2,26.6L8.2,26.4L6.5,26.4L6.5,26.6C6.5,27.9,7.6,29,8.9,29L15.9,29C17.3,29,18.4,27.9,18.4,26.6L18.4,24.8C18.4,23.5,17.3,22.4,15.9,22.4L8.9,22.4C8.5,22.4,8.2,22,8.2,21.6L8.2,19.8C8.2,19.4,8.5,19,8.9,19L15.9,19C16.3,19,16.7,19.4,16.7,19.8L16.7,20L18.4,20L18.4,19.8ZM38.4,17.4L35.1,26L31.2,17.4L29,17.4L25.3,26L21.8,17.4L20,17.4L22.7,24.3C23.3,25.9,23.9,27.4,24.6,29L26,29L30.1,19.4L34.4,29L35.8,29L40.2,17.4L38.4,17.4ZM41.8,29L43.4,29L43.4,17.4L41.8,17.4L41.8,29ZM41.8,13.6L41.8,15.2L43.4,15.2L43.4,13.6L41.8,13.6ZM61.3,17.4C62.6,17.4,63.7,18.5,63.7,19.8L63.7,29L62.1,29L62.1,19.8C62.1,19.4,61.7,19,61.3,19L56.5,19C56,19,55.7,19.4,55.7,19.8L55.7,29L54,29L54,19.8C54,19.4,53.6,19,53.2,19L48.4,19C48,19,47.6,19.4,47.6,19.8L47.6,29L46,29L46,17.4L61.3,17.4Z");
  }

  @Lazy
  static get settingsIcon(): Graphics {
    return VectorIcon.create(20, 20, "M17.7,11C17.7,10.7,17.7,10.3,17.7,10C17.7,9.7,17.7,9.3,17.6,9L19.8,7.4C20,7.2,20.1,7,19.9,6.7L17.9,3.3C17.8,3,17.5,3,17.3,3L14.7,4C14.2,3.6,13.6,3.3,13,3.1L12.6,0.4C12.5,0.2,12.3,0,12.1,0L7.9,0C7.7,0,7.5,0.2,7.4,0.4L7.1,3.1C6.4,3.3,5.8,3.7,5.3,4L2.8,3C2.5,3,2.3,3,2.1,3.3L0.1,6.7C-0.1,7,0,7.2,0.2,7.4L2.4,9C2.3,9.3,2.3,9.7,2.3,10C2.3,10.3,2.3,10.7,2.4,11L0.2,12.6C0,12.8,-0.1,13.1,0.1,13.3L2.1,16.7C2.2,16.9,2.5,17,2.7,16.9L5.3,15.9C5.8,16.4,6.4,16.7,7,16.9L7.4,19.6C7.5,19.8,7.7,20,7.9,20L12.1,20C12.3,20,12.5,19.8,12.6,19.6L12.9,16.9C13.6,16.7,14.2,16.3,14.7,15.9L17.2,16.9C17.5,17,17.7,16.9,17.9,16.7L19.9,13.3C20.1,13,20,12.8,19.8,12.6L17.7,11L17.7,11ZM10,13.8C7.9,13.8,6.1,12.1,6.1,10C6.1,7.9,7.9,6.3,10,6.3C12.1,6.3,13.9,7.9,13.9,10C13.9,12.1,12.1,13.8,10,13.8Z");
  }

  @Lazy
  static get inventoryIcon(): Graphics {
    return VectorIcon.create(24, 24, "M20,2L4,2C3,2,2,2.9,2,4L2,7C2,7.7,2.4,8.3,3,8.7L3,20C3,21.1,4.1,22,5,22L19,22C19.9,22,21,21.1,21,20L21,8.7C21.6,8.3,22,7.7,22,7L22,4C22,2.9,21,2,20,2ZM15,14L9,14L9,12L15,12L15,14ZM20,7L4,7L4,4L20,4L20,7Z");
  }

  static override readonly MountFlags: ControllerFlags = Controller.MountFlags | Controller.NeedsRevise;
}
