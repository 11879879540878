// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {MemberFastenerClass} from "@swim/component";
import {Trait, TraitRef} from "@swim/model";
import {Feel} from "@swim/theme";
import {TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {
  CellTrait,
  TextCellTrait,
  IconCellTrait,
  CellView,
  CellController,
  RowView,
  RowTrait,
  RowController,
} from "@swim/table";
import {Status, StatusVector, StatusTrait} from "@swim/domain";
import {TableGadgetTextCellController} from "./TableGadgetTextCellController";
import {TableGadgetIconCellController} from "./TableGadgetIconCellController";

/** @public */
export class TableGadgetRowController extends RowController {
  @TraitViewRef<TableGadgetRowController, RowTrait, RowView>({
    extends: true,
    didAttachTrait(rowTrait: RowTrait, targetTrait: Trait | null): void {
      this.owner.status.setTrait(rowTrait.getTrait(StatusTrait));
      RowController.row.prototype.didAttachTrait.call(this, rowTrait, targetTrait);
    },
    willDetachTrait(rowTrait: RowTrait): void {
      RowController.row.prototype.willDetachTrait.call(this, rowTrait);
      this.owner.status.setTrait(null);
    },
    initView(rowView: RowView): void {
      RowController.row.prototype.initView.call(this, rowView);
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        rowView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, rowView);
      }
    },
  })
  override readonly row!: TraitViewRef<this, RowTrait, RowView>;
  static override readonly row: MemberFastenerClass<TableGadgetRowController, "row">;

  @TraitViewControllerSet<TableGadgetRowController, CellTrait, CellView, CellController>({
    extends: true,
    createController(cellTrait?: CellTrait): CellController {
      let cellController: CellController;
      if (cellTrait instanceof TextCellTrait) {
        cellController = new TableGadgetTextCellController();
      } else if (cellTrait instanceof IconCellTrait) {
        cellController = new TableGadgetIconCellController();
      } else {
        cellController = RowController.cells.prototype.createController.call(this, cellTrait);
      }
      return cellController;
    },
  })
  override readonly cells!: TraitViewControllerSet<this, CellTrait, CellView, CellController>;
  static override readonly cells: MemberFastenerClass<TableGadgetRowController, "cells">;

  @TraitRef<TableGadgetRowController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        rowView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, rowView);
      }
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        this.owner.applyStatusVector(newStatusVector, rowView);
      }
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;
  static readonly status: MemberFastenerClass<TableGadgetRowController, "status">;

  protected applyStatusVector(statusVector: StatusVector, rowView: RowView): void {
    const alert = statusVector.get(Status.alert) || 0;
    const warning = statusVector.get(Status.warning) || 0;
    const inactive = statusVector.get(Status.inactive) || 0;
    if (alert !== 0 && warning !== 0) {
      rowView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
    } else if (alert !== 0) {
      rowView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
    } else if (warning !== 0) {
      rowView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
    } else {
      rowView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
    }
    if (inactive !== 0) {
      rowView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
    } else {
      rowView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
    }
  }
}
