// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Uri, AnyUriPath, UriPath} from "@swim/uri";
import {Model, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {MetaNodeEntity} from "./MetaNodeEntity";

/** @public */
export class NodeGroup extends EntityGroup {
  constructor(metaHostUri?: Uri) {
    super();
    if (metaHostUri === void 0) {
      metaHostUri = NodeGroup.metaHostUri;
    }
    this.metaHostUri = metaHostUri;
  }

  readonly metaHostUri: Uri;

  protected initNodeModel(nodeModel: Model): void {
    // hook
  }

  protected createNodeModel(nodePath: AnyUriPath, nodeUri?: Uri, metaNodeUri?: Uri): Model {
    nodePath = UriPath.fromAny(nodePath);
    const metaHostUri = this.metaHostUri;
    if (metaNodeUri === void 0) {
      const metaHostPath = metaHostUri.path;
      const pathBuilder = UriPath.builder();
      if (metaHostPath.toString() === "meta:host" || metaHostPath.toString() === "meta:node") {
        pathBuilder.addSegment("meta:node");
      } else {
        pathBuilder.addPath(metaHostPath);
        pathBuilder.addSegment("node");
      }
      pathBuilder.addSegment(nodePath.toString());
      metaNodeUri = Uri.create(metaHostUri.scheme, metaHostUri.authority, pathBuilder.bind());
    }
    if (nodeUri === void 0) {
      nodeUri = Uri.path(nodePath);
    }

    const nodeModel = new Model();

    const entityTrait = new MetaNodeEntity(nodeUri, metaNodeUri, metaHostUri);
    entityTrait.title.setValue(nodeUri.path.foot().head());
    nodeModel.setTrait("entity", entityTrait);

    this.initNodeTraits(nodeModel, nodeUri, metaNodeUri);

    return nodeModel;
  }

  protected initNodeTraits(nodeModel: Model, nodeUri: Uri, metaNodeUri: Uri): void {
    nodeModel.setTrait("selectable", new SelectableTrait());
    nodeModel.setTrait("status", new StatusTrait());
    nodeModel.setTrait("indicated", new IndicatedTrait());
    nodeModel.setTrait("pulse", new PulseTrait(metaNodeUri));
  }

  protected getOrCreateNodeModel(nodePath: AnyUriPath): Model {
    if (typeof nodePath !== "string") {
      nodePath = UriPath.fromAny(nodePath).toString();
    }
    let nodeModel = this.getChild(nodePath);
    if (nodeModel === null) {
      nodeModel = this.createNodeModel(nodePath);
      this.initNodeModel(nodeModel);
      this.appendChild(nodeModel, nodePath);
    }
    return nodeModel;
  }

  protected removeNodeModel(nodePath: AnyUriPath): void {
    if (typeof nodePath !== "string") {
      nodePath = UriPath.fromAny(nodePath).toString();
    }
    this.removeChild(nodePath);
  }

  @Lazy
  static get metaHostUri(): Uri {
    return Uri.parse("swim:meta:node");
  }
}
