// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy, Mutable} from "@swim/util";
import type {IndicatorType} from "./IndicatorType";

/** @public */
export class IndicatorMap {
  constructor(indicatorTypeMap: {readonly [key: string]: IndicatorType | undefined},
              indicatorTypeArray: ReadonlyArray<IndicatorType> | null = null) {
    this.indicatorTypeMap = indicatorTypeMap;
    this.indicatorTypeArray = indicatorTypeArray;
  }

  /** @internal */
  readonly indicatorTypeMap!: {readonly [key: string]: IndicatorType | undefined};

  get size(): number {
    return this.indicatorTypes.length;
  }

  has(key: string): boolean {
    return this.indicatorTypeMap[key] !== void 0;
  }

  get(key: string): IndicatorType | null {
    const indicatorType = this.indicatorTypeMap[key];
    return indicatorType !== void 0 ? indicatorType : null;
  }

  updated(newIndicatorType: IndicatorType): IndicatorMap {
    const oldIndicatorTypeMap = this.indicatorTypeMap;
    const oldIndicatorType = oldIndicatorTypeMap[newIndicatorType.key];
    if (oldIndicatorType === newIndicatorType) {
      return this;
    } else {
      const newIndicatorTypeMap: {[key: string]: IndicatorType | undefined} = {};
      for (const key in oldIndicatorTypeMap) {
        newIndicatorTypeMap[key] = oldIndicatorTypeMap[key];
      }
      newIndicatorTypeMap[newIndicatorType.key] = newIndicatorType;
      return new IndicatorMap(newIndicatorTypeMap);
    }
  }

  merged(that: IndicatorMap): IndicatorMap {
    if (this.size < that.size) {
      return that.merged(this);
    } else {
      let indicatorMap: IndicatorMap = this;
      for (const key in that.indicatorTypeMap) {
        indicatorMap = indicatorMap.updated(that.indicatorTypeMap[key]!);
      }
      return indicatorMap;
    }
  }

  /** @internal */
  readonly indicatorTypeArray!: ReadonlyArray<IndicatorType> | null;

  get indicatorTypes(): ReadonlyArray<IndicatorType> {
    let indicatorTypeArray = this.indicatorTypeArray;
    if (indicatorTypeArray === null) {
      indicatorTypeArray = this.arrangedIndicatorTypes();
      (this as Mutable<this>).indicatorTypeArray = indicatorTypeArray;
    }
    return indicatorTypeArray;
  }

  protected arrangedIndicatorTypes(): ReadonlyArray<IndicatorType> {
    const indicatorTypeArray = new Array<IndicatorType>();
    const indicatorTypeMap = this.indicatorTypeMap;
    for (const key in indicatorTypeMap) {
      const indicatorType = indicatorTypeMap[key]!;
      indicatorTypeArray.push(indicatorType);
    }
    return indicatorTypeArray;
  }

  @Lazy
  static empty(): IndicatorMap {
    return new IndicatorMap({}, []);
  }
}
