// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity, MemberFastenerClass} from "@swim/component";
import {Trait, TraitRef} from "@swim/model";
import {Look, Feel} from "@swim/theme";
import {TraitViewRef} from "@swim/controller";
import {LinePlotView, LinePlotTrait, LinePlotController} from "@swim/chart";
import {Status, StatusVector, StatusTrait} from "@swim/domain";

/** @public */
export class ChartGadgetLinePlotController<X = unknown, Y = unknown> extends LinePlotController<X, Y> {
  @TraitViewRef<ChartGadgetLinePlotController<X, Y>, LinePlotTrait<X, Y>, LinePlotView<X, Y>>({
    extends: true,
    didAttachTrait(plotTrait: LinePlotTrait<X, Y>, targetTrait: Trait | null): void {
      this.owner.status.setTrait(plotTrait.getTrait(StatusTrait));
      LinePlotController.plot.prototype.didAttachTrait.call(this, plotTrait as LinePlotTrait, targetTrait);
    },
    willDetachTrait(plotTrait: LinePlotTrait<X, Y>): void {
      LinePlotController.plot.prototype.willDetachTrait.call(this, plotTrait as LinePlotTrait);
      this.owner.status.setTrait(null);
    },
    initView(plotView: LinePlotView<X, Y>): void {
      LinePlotController.plot.prototype.initView.call(this, plotView as LinePlotView);
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        plotView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, plotView);
      }
      const plotTrait = this.trait;
      if (plotTrait === null || plotTrait.stroke === null) {
        plotView.stroke.setLook(Look.accentColor, Affinity.Intrinsic);
      }
      if (plotTrait === null || plotTrait.strokeWidth === null) {
        plotView.strokeWidth.setState(2, Affinity.Intrinsic);
      }
    },
  })
  override readonly plot!: TraitViewRef<this, LinePlotTrait<X, Y>, LinePlotView<X, Y>>;
  static override readonly plot: MemberFastenerClass<ChartGadgetLinePlotController, "plot">;

  @TraitRef<ChartGadgetLinePlotController<X, Y>, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      const plotView = this.owner.plot.view;
      if (plotView !== null) {
        plotView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, plotView);
      }
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      const plotView = this.owner.plot.view;
      if (plotView !== null) {
        this.owner.applyStatusVector(newStatusVector, plotView);
      }
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;
  static readonly status: MemberFastenerClass<ChartGadgetLinePlotController, "status">;

  protected applyStatusVector(statusVector: StatusVector, plotView: LinePlotView<X, Y>): void {
    const alert = statusVector.get(Status.alert) || 0;
    const warning = statusVector.get(Status.warning) || 0;
    const inactive = statusVector.get(Status.inactive) || 0;
    if (alert !== 0 && warning !== 0) {
      plotView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
    } else if (alert !== 0) {
      plotView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
    } else if (warning !== 0) {
      plotView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
    } else {
      plotView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
    }
    if (inactive !== 0) {
      plotView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
    } else {
      plotView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
    }
  }
}
