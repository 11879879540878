// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {ModelRef} from "@swim/model";
import type {ViewRef} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import {Controller} from "@swim/controller";
import type {DrawerView} from "@swim/window";
import type {DomainGroup} from "@swim/domain";
import type {SurfaceView} from "../view/SurfaceView";

/** @public */
export abstract class MirrorController extends Controller {
  abstract isFullBleed(): boolean;

  abstract readonly surface: ViewRef<this, SurfaceView>;

  abstract readonly toolbar: ViewRef<this, HtmlView>;

  abstract readonly drawer: ViewRef<this, DrawerView>;

  abstract readonly domains: ModelRef<this, DomainGroup>;
}
