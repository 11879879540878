// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Equals} from "@swim/util";
import {AnyLength, Length} from "@swim/math";
import {AnyGeoPoint, GeoPoint} from "@swim/geo";
import {AnyColor, Color} from "@swim/style";
import type {Graphics} from "@swim/graphics";
import {Geographic} from "./Geographic";

/** @public */
export type AnyGeographicPoint = GeographicPoint | GeographicPointInit;

/** @public */
export interface GeographicPointInit {
  geometry: AnyGeoPoint;
  width?: AnyLength | null;
  height?: AnyLength | null;
  graphics?: Graphics | null;
  fill?: AnyColor | null;
}

/** @public */
export class GeographicPoint extends Geographic {
  constructor(geometry: GeoPoint, width: Length | null, height: Length | null,
              graphics: Graphics | null, fill: Color | null) {
    super();
    this.geometry = geometry;
    this.width = width;
    this.height = height;
    this.graphics = graphics;
    this.fill = fill;
  }

  override readonly geometry: GeoPoint;

  readonly width: Length | null;

  readonly height: Length | null;

  readonly graphics: Graphics | null;

  readonly fill: Color | null;

  equals(that: unknown): boolean {
    if (this === that) {
      return true;
    } else if (that instanceof GeographicPoint) {
      return this.geometry.equals(that.geometry)
          && Equals(this.width, that.width)
          && Equals(this.height, that.height)
          && Equals(this.graphics, that.graphics)
          && Equals(this.fill, that.fill);
    }
    return false;
  }

  static fromInit(init: GeographicPointInit): GeographicPoint {
    const geometry = GeoPoint.fromAny(init.geometry);
    const width = init.width !== void 0 && init.width !== null ? Length.fromAny(init.width) : null;
    const height = init.height !== void 0 && init.height !== null ? Length.fromAny(init.height) : null;
    const graphics = init.graphics !== void 0 ? init.graphics : null;
    const fill = init.fill !== void 0 && init.fill !== null ? Color.fromAny(init.fill) : null;
    return new GeographicPoint(geometry, width, height, graphics, fill);
  }

  static fromAny(value: AnyGeographicPoint): GeographicPoint {
    if (value === void 0 || value === null || value instanceof GeographicPoint) {
      return value;
    } else if (typeof value === "object") {
      return GeographicPoint.fromInit(value);
    }
    throw new TypeError("" + value);
  }
}
