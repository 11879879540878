// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Provider} from "@swim/component";
import {Trait, TraitRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Controller, TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {EntityTrait} from "@swim/domain";
import {WidgetView, WidgetTrait, WidgetGroup} from "@swim/widget";
import {PrismService, PrismProvider} from "@swim/prism";
import {RefractionController} from "./RefractionController";

/** @public */
export class RefractorController extends Controller {
  @TraitViewControllerSet<RefractorController, WidgetTrait, WidgetView, RefractionController>({
    type: RefractionController,
    binds: true,
    get parentView(): HtmlView | null {
      return this.owner.fixtures.view;
    },
    getTraitViewRef(refractionController: RefractionController): TraitViewRef<unknown, WidgetTrait, WidgetView> {
      return refractionController.widget;
    },
    initController(refractionController: RefractionController): void {
      refractionController.entity.setTrait(this.owner.entity.trait);
    },
    willDetachController(refractionController: RefractionController): void {
      refractionController.widget.removeView();
    },
  })
  readonly refractions!: TraitViewControllerSet<this, WidgetTrait, WidgetView, RefractionController>;

  @ViewRef<RefractorController, HtmlView>({
    type: HtmlView,
    initView(fixturesView: HtmlView): void {
      const refractionControllers = this.owner.refractions.controllers;
      for (const controllerId in refractionControllers) {
        const refractionController = refractionControllers[controllerId]!;
        refractionController.widget.insertView(fixturesView);
      }
    },
  })
  readonly fixtures!: ViewRef<this, HtmlView>;

  @TraitRef<RefractorController, EntityTrait>({
    type: EntityTrait,
    didAttachTrait(entityTrait: EntityTrait): void {
      const widgetGroup = entityTrait.getTrait(WidgetGroup);
      if (widgetGroup !== null) {
        this.owner.widgets.setTrait(widgetGroup);
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      this.owner.widgets.setTrait(null);
      this.owner.removeChildren();
      const fixturesView = this.owner.fixtures.view;
      if (fixturesView !== null) {
        fixturesView.removeChildren();
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait>;

  @TraitRef<RefractorController, WidgetGroup>({
    type: WidgetGroup,
    observes: true,
    initTrait(widgetGroup: WidgetGroup): void {
      const widgetTraits = widgetGroup.widgets.traits;
      for (const traitId in widgetTraits) {
        const widgetTrait = widgetTraits[traitId]!;
        this.owner.refractions.addTraitController(widgetTrait, null);
      }
    },
    traitWillAttachWidget(widgetTrait: WidgetTrait, targetTrait: Trait | null): void {
      this.owner.refractions.addTraitController(widgetTrait, targetTrait);
    },
    traitDidDetachWidget(widgetTrait: WidgetTrait): void {
      this.owner.refractions.deleteTraitController(widgetTrait);
    },
  })
  readonly widgets!: TraitRef<this, WidgetGroup>;

  @Provider<RefractorController, PrismService>({
    extends: PrismProvider,
    type: PrismService,
    observes: false,
    service: PrismService.global(),
  })
  readonly prismProvider!: PrismProvider<this>;

  protected override willUnmount(): void {
    super.willUnmount();
    this.entity.setTrait(null);
  }
}
