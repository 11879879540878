// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {TraitRef} from "@swim/model";
import type {ViewRef} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import {Controller} from "@swim/controller";
import type {EntityTrait} from "@swim/domain";

/** @public */
export abstract class ActivityController extends Controller {
  abstract readonly window: ViewRef<this, HtmlView>;

  abstract readonly entity: TraitRef<this, EntityTrait>;
}
