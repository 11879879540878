// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import {Angle, Length} from "@swim/math";
import {Trait} from "@swim/model";
import {Look} from "@swim/theme";
import {View, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {GraphicsView, CanvasView} from "@swim/graphics";
import {TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {DialView, DialTrait, DialController, GaugeView, GaugeTrait, GaugeControllerDialExt, GaugeController} from "@swim/gauge";
import type {GadgetController} from "../gadget/GadgetController";
import {GaugeGadgetDialController} from "./GaugeGadgetDialController";
import type {GaugeGadgetControllerObserver} from "./GaugeGadgetControllerObserver";

/** @public */
export class GaugeGadgetController extends GaugeController implements GadgetController {
  override readonly observerType?: Class<GaugeGadgetControllerObserver>;

  protected layoutGauge(hasLegend?: boolean): void {
    const gaugeView = this.gauge.view;
    if (gaugeView !== null) {
      const dialCount = gaugeView.dials.viewCount;
      if (hasLegend === void 0) {
        hasLegend = false;
        const dialViews = gaugeView.dials.views;
        for (const viewId in dialViews) {
          const dialView = dialViews[viewId]!;
          if (dialView.legend.view !== null) {
            hasLegend = true;
            break;
          }
        }
      }

      const hasTitle = this.title.view !== null;

      const outerRadius = hasLegend ? 35 : 45;
      gaugeView.outerRadius.setState(Length.pct(outerRadius), Affinity.Intrinsic);
      if (dialCount === 1) {
        gaugeView.innerRadius.setState(Length.pct(outerRadius - 12.5), Affinity.Intrinsic);
      } else if (hasTitle) {
        gaugeView.innerRadius.setState(Length.pct(20), Affinity.Intrinsic);
      } else {
        gaugeView.innerRadius.setState(Length.pct(outerRadius - 25), Affinity.Intrinsic);
      }
    }
  }

  @TraitViewRef<GaugeGadgetController, GaugeTrait, GaugeView>({
    extends: true,
    initView(gaugeView: GaugeView): void {
      GaugeController.gauge.prototype.initView.call(this, gaugeView);
      gaugeView.startAngle.setState(Angle.rad(Math.PI * 5 / 8), Affinity.Intrinsic);
      gaugeView.sweepAngle.setState(Angle.rad(Math.PI * 11 / 8), Affinity.Intrinsic);
      gaugeView.cornerRadius.setState(Length.zero(), Affinity.Intrinsic);
      gaugeView.tickAlign.setState(1, Affinity.Intrinsic);
      gaugeView.tickRadius.setState(Length.pct(40), Affinity.Intrinsic);
      gaugeView.font.setLook(Look.font, Affinity.Intrinsic);
      this.owner.layoutGauge();
    },
  })
  override readonly gauge!: TraitViewRef<this, GaugeTrait, GaugeView>;
  static override readonly gauge: MemberFastenerClass<GaugeGadgetController, "gauge">;

  @ViewRef<GaugeGadgetController, GraphicsView>({
    extends: true,
    didAttachView(titleView: GraphicsView, targetView: View | null): void {
      GaugeController.title.prototype.didAttachView.call(this, titleView, targetView);
      this.owner.layoutGauge();
    },
    willDetachView(titleView: GraphicsView): void {
      GaugeController.title.prototype.willDetachView.call(this, titleView);
      this.owner.layoutGauge();
    },
  })
  override readonly title!: ViewRef<this, GraphicsView>;
  static override readonly title: MemberFastenerClass<GaugeController, "title">;

  @TraitViewControllerSet<GaugeGadgetController, DialTrait, DialView, DialController, GaugeControllerDialExt>({
    extends: true,
    implements: true,
    type: GaugeGadgetDialController,
    attachDialView(dialView: DialView, dialController: DialController): void {
      GaugeController.dials.prototype.attachDialView.call(this, dialView, dialController);
      this.owner.layoutGauge();
    },
    detachDialView(dialView: DialView, dialController: DialController): void {
      GaugeController.dials.prototype.detachDialView.call(this, dialView, dialController);
      this.owner.layoutGauge();
    },
    attachDialLegendView(legendView: GraphicsView, dialController: DialController): void {
      GaugeController.dials.prototype.attachDialLegendView.call(this, legendView, dialController);
      this.owner.layoutGauge(true);
    },
    detachDialLegendView(legendView: GraphicsView, dialController: DialController): void {
      GaugeController.dials.prototype.detachDialLegendView.call(this, legendView, dialController);
      this.owner.layoutGauge();
    },
  })
  override readonly dials!: TraitViewControllerSet<this, DialTrait, DialView, DialController> & GaugeControllerDialExt;
  static override readonly dials: MemberFastenerClass<GaugeGadgetController, "dials">;

  @ViewRef<GaugeGadgetController, CanvasView>({
    type: CanvasView,
    didAttachView(canvasView: CanvasView): void {
      this.owner.gauge.insertView(canvasView);
    },
    willDetachView(canvasView: CanvasView): void {
      this.owner.gauge.removeView();
    },
  })
  readonly canvas!: ViewRef<this, CanvasView>;
  static readonly canvas: MemberFastenerClass<GaugeGadgetController, "canvas">;

  @TraitViewRef<GaugeGadgetController, Trait, HtmlView>({
    traitType: Trait,
    willAttachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerWillAttachGadgetTrait", gadgetTrait, this.owner);
    },
    didAttachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof GaugeTrait) {
        this.owner.gauge.setTrait(gadgetTrait);
      }
    },
    willDetachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof GaugeTrait) {
        this.owner.gauge.setTrait(null);
      }
    },
    didDetachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerDidDetachGadgetTrait", gadgetTrait, this.owner);
    },
    viewType: HtmlView,
    initView(gadgetView: HtmlView): void {
      gadgetView.height.setState(140, Affinity.Intrinsic);
      gadgetView.marginLeft.setState(18, Affinity.Intrinsic);
      gadgetView.marginRight.setState(18, Affinity.Intrinsic);
    },
    willAttachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachGadgetView", gadgetView, this.owner);
    },
    didAttachView(gadgetView: HtmlView): void {
      this.owner.canvas.insertView(gadgetView);
    },
    willDetachView(gadgetView: HtmlView): void {
      this.owner.canvas.removeView();
    },
    didDetachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachGadgetView", gadgetView, this.owner);
    },
  })
  readonly gadget!: TraitViewRef<this, Trait, HtmlView>;
  static readonly gadget: MemberFastenerClass<GaugeGadgetController, "gadget">;
}
