// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {MemberFastenerClass} from "@swim/component";
import {Uri, UriPath} from "@swim/uri";
import {ModelRef} from "@swim/model";
import {StatusTrait, EntityTrait, EntityGroup} from "@swim/domain";
import {MetaNodeGroup} from "./MetaNodeGroup";

/** @public */
export class MetaNodeEntity extends EntityTrait {
  constructor(nodeUri: Uri, metaNodeUri: Uri, metaHostUri: Uri) {
    super(nodeUri);
    this.metaNodeUri = metaNodeUri;
    this.metaHostUri = metaHostUri;
  }

  get nodeUri(): Uri {
    return this.uri;
  }

  get nodePath(): UriPath {
    return this.uri.path;
  }

  readonly metaNodeUri: Uri;

  readonly metaHostUri: Uri;

  @ModelRef<MetaNodeEntity, EntityGroup>({
    extends: true,
    createModel(): EntityGroup {
      let nodePath = this.owner.nodePath;
      if (!nodePath.foot().isAbsolute()) {
        nodePath = nodePath.appendedSlash();
      }
      const nodeUri = Uri.path(nodePath);
      const entityGroup = new MetaNodeGroup(nodeUri, this.owner.metaHostUri);
      entityGroup.setTrait("status", new StatusTrait());
      return entityGroup;
    },
  })
  override readonly subentities!: ModelRef<this, EntityGroup>;
  static override readonly subentities: MemberFastenerClass<MetaNodeEntity, "subentities">;
}
