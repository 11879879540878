// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Mutable, Class, Equals} from "@swim/util";
import {ModelFlags, Model, TraitContextType, Trait} from "@swim/model";
import {StatusVector} from "./StatusVector";
import type {StatusFactor} from "./StatusFactor";
import type {StatusTraitObserver} from "./StatusTraitObserver";

/** @public */
export class StatusTrait extends Trait {
  constructor() {
    super();
    this.statusFactors = null;
    this.statusVector = StatusVector.empty();
  }

  override readonly observerType?: Class<StatusTraitObserver>;

  /** @internal */
  readonly statusFactors: {[statusName: string]: StatusFactor | undefined} | null;

  getStatusFactor(statusName: string): StatusFactor | null {
    const statusFactors = this.statusFactors;
    if (statusFactors !== null) {
      const statusFactor = statusFactors[statusName];
      if (statusFactor !== void 0) {
        return statusFactor;
      }
    }
    return null;
  }

  setStatusFactor(statusName: string, newStatusFactor: StatusFactor | null): void {
    let statusFactors = this.statusFactors;
    if (statusFactors === null && newStatusFactor !== null) {
      statusFactors = {};
      (this as Mutable<this>).statusFactors = statusFactors;
    }
    let oldStatusFactor: StatusFactor | null | undefined;
    if (statusFactors !== null) {
      oldStatusFactor = statusFactors[statusName];
    }
    if (oldStatusFactor === void 0) {
      oldStatusFactor = null;
    }
    if (!Equals(oldStatusFactor, newStatusFactor)) {
      this.willSetStatusFactor(statusName, newStatusFactor, oldStatusFactor);
      if (newStatusFactor !== null) {
        statusFactors![statusName] = newStatusFactor;
      } else {
        delete statusFactors![statusName];
      }
      this.onSetStatusFactor(statusName, newStatusFactor, oldStatusFactor);
      this.didSetStatusFactor(statusName, newStatusFactor, oldStatusFactor);
    }
  }

  protected willSetStatusFactor(statusName: string, newStatusFactor: StatusFactor | null,
                                oldStatusFactor: StatusFactor | null): void {
    this.callObservers("traitWillSetStatusFactor", statusName, newStatusFactor, oldStatusFactor, this);
  }

  protected onSetStatusFactor(statusName: string, newStatusFactor: StatusFactor | null,
                              oldStatusFactor: StatusFactor | null): void {
    this.requireUpdate(Model.NeedsAggregate);
  }

  protected didSetStatusFactor(statusName: string, newStatusFactor: StatusFactor | null,
                               oldStatusFactor: StatusFactor | null): void {
    this.callObservers("traitDidSetStatusFactor", statusName, newStatusFactor, oldStatusFactor, this);
  }

  readonly statusVector: StatusVector;

  setStatusVector(newStatusVector: StatusVector): void {
    const oldStatusVector = this.statusVector;
    if (!oldStatusVector.equals(newStatusVector)) {
      this.willSetStatusVector(newStatusVector, oldStatusVector);
      (this as Mutable<this>).statusVector = newStatusVector;
      this.onSetStatusVector(newStatusVector, oldStatusVector);
      this.didSetStatusVector(newStatusVector, oldStatusVector);
    }
  }

  protected willSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
    this.callObservers("traitWillSetStatusVector", newStatusVector, oldStatusVector, this);
  }

  protected onSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
    // hook
  }

  protected didSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
    this.callObservers("traitDidSetStatusVector", newStatusVector, oldStatusVector, this);
  }

  /** @internal */
  combinedStatus(): StatusVector {
    const statusFactors = this.statusFactors;
    let statusVector = StatusVector.empty();
    let statusTotal = 0;
    if (statusFactors !== null) {
      for (const statusName in statusFactors) {
        const statusFactor = statusFactors[statusName]!;
        statusVector = statusVector.plus(statusFactor.vector.times(statusFactor.weight));
        statusTotal += statusFactor.weight;
      }
    }
    if (statusTotal !== 0) {
      statusVector = statusVector.times(1 / statusTotal);
    }
    return statusVector;
  }

  /** @internal */
  aggregateStatus(): void {
    this.setStatusVector(this.combinedStatus());
  }

  /** @protected */
  override needsUpdate(updateFlags: ModelFlags, immediate: boolean): ModelFlags {
    updateFlags = super.needsUpdate(updateFlags, immediate);
    if ((updateFlags & Model.NeedsAggregate) !== 0) {
      this.setModelFlags(this.modelFlags | Model.NeedsAggregate);
    }
    return updateFlags;
  }

  /** @protected */
  override needsAnalyze(analyzeFlags: ModelFlags, modelContext: TraitContextType<this>): ModelFlags {
    if ((this.modelFlags & Model.NeedsAggregate) === 0) {
      analyzeFlags &= ~Model.NeedsAggregate;
    }
    return analyzeFlags;
  }

  /** @protected */
  override didAggregate(modelContext: TraitContextType<this>): void {
    this.aggregateStatus();
    super.didAggregate(modelContext);
  }

  static override readonly MountFlags: ModelFlags = Trait.MountFlags | Model.NeedsAggregate;
}
