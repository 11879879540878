// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Affinity} from "@swim/component";
import {Look, Feel, MoodMatrix} from "@swim/theme";
import {ViewContext, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Graphics, VectorIcon, EnclosedIcon} from "@swim/graphics";
import {Controller} from "@swim/controller";
import {IconButton} from "@swim/button";
import {ColLayout, TableLayout, TextCellView, IconCellView, RowView, TableView} from "@swim/table";
import {DrawerView} from "@swim/window";
import {DeckCard, DeckView, TitleDeckBar, TitleDeckView} from "@swim/deck";

/** @public */
export class AtlasLayers extends Controller {
  get layersIcon(): Graphics {
    return AtlasLayers.layersIcon;
  }

  protected onPressLayersButton(layersButton: IconButton): void {
    const drawerView = this.drawer.view;
    if (drawerView !== null) {
      if (drawerView.slide.dismissed) {
        drawerView.present();
      } else {
        drawerView.dismiss();
      }
    }
  }

  @ViewRef<AtlasLayers, HtmlView>({
    type: HtmlView,
    didAttachView(toolbarView: HtmlView): void {
      this.owner.layersButton.insertView(toolbarView);
    },
    willDetachView(toolbarView: HtmlView): void {
      this.owner.layersButton.removeView();
    },
  })
  readonly toolbar!: ViewRef<this, HtmlView>;

  @ViewRef<AtlasLayers, IconButton>({
    type: IconButton,
    observes: true,
    initView(layersButton: IconButton): void {
      layersButton.flexShrink.setState(0, Affinity.Intrinsic);
      layersButton.height.setState(null, Affinity.Intrinsic);
      layersButton.pointerEvents.setState("auto", Affinity.Intrinsic);
      layersButton.iconWidth.setState(32, Affinity.Intrinsic);
      layersButton.iconHeight.setState(32, Affinity.Intrinsic);
      layersButton.pushIcon(this.owner.layersIcon);

      layersButton.constraint(layersButton.width.constrain(), "eq", layersButton.height);
    },
    buttonDidPress(layersButton: IconButton): void {
      this.owner.onPressLayersButton(layersButton);
    },
  })
  readonly layersButton!: ViewRef<this, IconButton>;

  @ViewRef<AtlasLayers, DrawerView>({
    type: DrawerView,
    didAttachView(drawerView: DrawerView): void {
      this.owner.deck.insertView(drawerView);
    },
    willDetachView(drawerView: DrawerView): void {
      drawerView.removeChildren();
      drawerView.dismiss();
    },
  })
  readonly drawer!: ViewRef<this, DrawerView>;

  @ViewRef<AtlasLayers, DeckView>({
    type: DeckView,
    observes: true,
    initView(deckView: DeckView): void {
      deckView.flexGrow.setState(1, Affinity.Intrinsic);
    },
    viewDidResize(viewContext: ViewContext, deckView: DeckView) {
      const barView = deckView.bar.view;
      if (barView !== null) {
        if (viewContext.viewportIdiom === "mobile") {
          barView.barHeight.setValue(60, Affinity.Intrinsic);
        } else {
          barView.barHeight.setValue(48, Affinity.Intrinsic);
        }
      }
    },
    deckDidPressCloseButton(event: Event | null, deckView: DeckView): void {
      const drawerView = this.owner.drawer.view;
      if (drawerView !== null) {
        drawerView.dismiss();
      }
    },
    createView(): DeckView {
      const deckView = TitleDeckView.create();
      deckView.outAlign.setValue(1, Affinity.Intrinsic);
      const barView = deckView.bar.view;
      if (barView instanceof TitleDeckBar) {
        barView.backgroundColor.setAffinity(Affinity.Extrinsic);
        const backButton = barView.backButton.view;
        if (backButton !== null) {
          backButton.closeIcon.setView(barView.createCloseIcon());
          backButton.closeIcon.insertView();
        }
      }
      const cardView = this.owner.card.createView();
      this.owner.card.setView(cardView);
      deckView.pushCard(cardView);
      return deckView;
    },
  })
  readonly deck!: ViewRef<this, DeckView>;

  @ViewRef<AtlasLayers, DeckCard>({
    type: DeckCard,
    initView(cardView: DeckCard): void {
      this.owner.layerList.insertView(cardView);
    },
    createView(): DeckCard {
      const cardView = DeckCard.create();
      cardView.backgroundColor.setAffinity(Affinity.Extrinsic);
      cardView.cardTitle.setValue("Layers");
      return cardView;
    },
  })
  readonly card!: ViewRef<this, DeckCard>;

  @ViewRef<AtlasLayers, TableView>({
    type: TableView,
    initView(layerListView: TableView): void {
      layerListView.modifyMood(Feel.default, [[Feel.transparent, 1], [Feel.unselected, 1]], false);
      layerListView.flexGrow(1)
                   .userSelect("none")
                   .backgroundColor(null)
                   .rowHeight(44)
                   .layout(AtlasLayers.layersListLayout);

      const layer1 = layerListView.appendChild(RowView)
                   .backgroundColor(null);
      layer1.leaf.insertView()!.backgroundColor(null);
      layer1.getOrCreateCell("icon", IconCellView)
        .iconWidth(24)
        .iconHeight(24)
        .graphics(this.owner.layersIcon);
      layer1.getOrCreateCell("title", TextCellView)
        .content("Layer 1");
      layer1.on("click", (): void => {
        const deckView = this.owner.deck.view;
        if (deckView !== null) {
          const layerCard = DeckCard.create();
          layerCard.backgroundColor.setAffinity(Affinity.Extrinsic);
          layerCard.cardTitle.setValue("Layer 1");
          deckView.pushCard(layerCard);
        }
      });

      const layer2 = layerListView.appendChild(RowView);
      layer2.leaf.insertView()!.backgroundColor(null);
      layer2.getOrCreateCell("icon", IconCellView)
        .iconWidth(24)
        .iconHeight(24)
        .graphics(this.owner.layersIcon);
      layer2.getOrCreateCell("title", TextCellView)
        .content("Layer 2");
      layer2.on("click", (): void => {
        const deckView = this.owner.deck.view;
        if (deckView !== null) {
          const layerCard = DeckCard.create();
          layerCard.backgroundColor.setAffinity(Affinity.Extrinsic);
          layerCard.cardTitle.setValue("Layer 2");
          deckView.pushCard(layerCard);
        }
      });

      const layer3 = layerListView.appendChild(RowView);
      layer3.leaf.insertView()!.backgroundColor(null);
      layer3.getOrCreateCell("icon", IconCellView)
        .iconWidth(24)
        .iconHeight(24)
        .graphics(this.owner.layersIcon);
      layer3.getOrCreateCell("title", TextCellView)
        .content("Layer 3");
      layer3.on("click", (): void => {
        const deckView = this.owner.deck.view;
        if (deckView !== null) {
          const layerCard = DeckCard.create();
          layerCard.backgroundColor.setAffinity(Affinity.Extrinsic);
          layerCard.cardTitle.setValue("Layer 3");
          deckView.pushCard(layerCard);
        }
      });
    },
  })
  readonly layerList!: ViewRef<this, TableView>;

  @Lazy
  static get layersListLayout(): TableLayout {
    return TableLayout.create([
      ColLayout.create("icon", 0, 0, 60, false, true),
      ColLayout.create("title", 1, 0, 0, false, false, Look.color),
    ]);
  }

  @Lazy
  static get layersIcon(): Graphics {
    const outer = VectorIcon.create(24, 24, "M12,16L19.36,10.27,21,9,12,2,3,9,4.63,10.27Z");
    const inner = VectorIcon.create(24, 24, "M11.99,18.54L4.62,12.81,3,14.07,12,21.07,21,14.07,19.37,12.8Z");
    const innerMoodModifier = MoodMatrix.empty().updatedCol(Feel.default, [[Feel.translucent, 2]], true);
    return EnclosedIcon.create(outer, inner).withInnerMoodModifier(innerMoodModifier);
  }
}
