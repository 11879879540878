// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity} from "@swim/component";
import {HtmlView} from "@swim/dom";

/** @public */
export class BeamView extends HtmlView {
  constructor(node: HTMLElement) {
    super(node);
    this.initBeam();
  }

  protected initBeam(): void {
    this.addClass("beam");
    this.position.setState("absolute", Affinity.Intrinsic);
    this.top.setState(0, Affinity.Intrinsic);
    this.right.setState(0, Affinity.Intrinsic);
    this.left.setState(0, Affinity.Intrinsic);
    this.height.setState(4, Affinity.Intrinsic);
  }
}
