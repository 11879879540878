// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity, MemberFastenerClass} from "@swim/component";
import {TraitRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {VectorIcon} from "@swim/graphics";
import {Controller} from "@swim/controller";
import {TextCellView, IconCellView, RowView} from "@swim/table";
import {EntityTrait} from "@swim/domain";

/** @public */
export class SuggestionController extends Controller {
  @ViewRef<SuggestionController, RowView>({
    type: RowView,
    initView(rowView: RowView): void {
      rowView.rowHeight(32);

      const iconCellView = rowView.getOrCreateCell("icon", IconCellView);
      iconCellView.iconWidth.setState(24, Affinity.Intrinsic);
      iconCellView.iconHeight.setState(24, Affinity.Intrinsic);
      const entityIcon = VectorIcon.create(24, 24, "M8,8 L19,8 L16,16 L5,16 Z");
      iconCellView.graphics.setState(entityIcon, Affinity.Intrinsic);

      const titleCellView = rowView.getOrCreateCell("title", TextCellView);
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        let entityTitle = entityTrait.title.value;
        if (entityTitle === void 0) {
          entityTitle = "";
        }
        titleCellView.content(entityTitle);
      }
    },
  })
  readonly row!: ViewRef<this, RowView>;
  static readonly row: MemberFastenerClass<SuggestionController, "row">;

  @TraitRef<SuggestionController, EntityTrait>({
    type: EntityTrait,
    observes: true,
    didAttachTrait(entityTrait: EntityTrait): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        const titleCellView = rowView.getOrCreateCell("title", TextCellView);
        let entityTitle = entityTrait.title.value;
        if (entityTitle === void 0) {
          entityTitle = "";
        }
        titleCellView.content(entityTitle);
      }
    },
    entityDidSetTitle(title: string | undefined, entityTrait: EntityTrait): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        const titleCellView = rowView.getOrCreateCell("title", TextCellView);
        titleCellView.content(title !== void 0 ? title : "");
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait>;
  static readonly entity: MemberFastenerClass<SuggestionController, "entity">;
}
