// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity} from "@swim/component";
import {ModelRef} from "@swim/model";
import {PositionGestureInput, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Controller, ControllerRef} from "@swim/controller";
import {TextCellView, IconCellView, LeafView, RowView} from "@swim/table";
import {DrawerView} from "@swim/window";
import {DomainGroup} from "@swim/domain";
import {SurfaceView, MirrorController, MirrorPlugin} from "@swim/prism";
import type {ReflectionControllerObserver} from "./ReflectionControllerObserver";

/** @public */
export class ReflectionController extends Controller {
  constructor(plugin: MirrorPlugin) {
    super();
    this.plugin = plugin;
    this.mirror.insertController();
  }

  override readonly observerType?: Class<ReflectionControllerObserver>;

  readonly plugin: MirrorPlugin;

  activate(): void {
    this.willActivate();
    this.onActivate();
    this.didActivate();
  }

  protected willActivate(): void {
    this.callObservers("controllerWillActivateReflection", this);
  }

  protected onActivate(): void {
    // hook
  }

  protected didActivate(): void {
    this.callObservers("controllerDidActivateReflection", this);
  }

  @ControllerRef<ReflectionController, MirrorController>({
    key: true,
    type: MirrorController,
    binds: true,
    initController(mirrorController: MirrorController): void {
      const domainGroup = this.owner.domains.model;
      if (domainGroup !== null) {
        mirrorController.domains.setModel(domainGroup);
      }
    },
    createController(): MirrorController {
      return this.owner.plugin.createController();
    },
  })
  readonly mirror!: ControllerRef<this, MirrorController>;

  @ViewRef<ReflectionController, RowView>({
    type: RowView,
    observes: true,
    initView(rowView: RowView): void {
      const iconCellView = rowView.getOrCreateCell("icon", IconCellView);
      iconCellView.iconWidth.setState(24, Affinity.Intrinsic);
      iconCellView.iconHeight.setState(24, Affinity.Intrinsic);
      const titleCellView = rowView.getOrCreateCell("title", TextCellView);

      iconCellView.graphics.setState(this.owner.plugin.icon);
      titleCellView.content.setView(this.owner.plugin.title);
    },
    viewDidPressLeaf(input: PositionGestureInput, event: Event | null,
                     leafView: LeafView, rowView: RowView): void {
      rowView.modalProvider.dismissModals();
      this.owner.activate();
    },
  })
  readonly row!: ViewRef<this, RowView>;

  @ViewRef<ReflectionController, SurfaceView>({
    type: SurfaceView,
    didAttachView(surfaceView: SurfaceView): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        surfaceView.fullBleed.setValue(mirrorController.isFullBleed());
        mirrorController.surface.setView(surfaceView);
      }
    },
    willDetachView(surfaceView: SurfaceView): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        mirrorController.surface.setView(null);
      }
    },
  })
  readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<ReflectionController, HtmlView>({
    type: HtmlView,
    didAttachView(toolbarView: HtmlView): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        mirrorController.toolbar.setView(toolbarView);
      }
    },
    willDetachView(toolbarView: HtmlView): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        mirrorController.toolbar.setView(null);
      }
    },
  })
  readonly toolbar!: ViewRef<this, HtmlView>;

  @ViewRef<ReflectionController, DrawerView>({
    type: DrawerView,
    didAttachView(drawerView: DrawerView): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        mirrorController.drawer.setView(drawerView);
      }
    },
    willDetachView(drawerView: DrawerView): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        mirrorController.drawer.setView(null);
      }
    },
  })
  readonly drawer!: ViewRef<this, DrawerView>;

  @ModelRef<ReflectionController, DomainGroup>({
    type: DomainGroup,
    initModel(domainGroup: DomainGroup): void {
      const mirrorController = this.owner.mirror.controller;
      if (mirrorController !== null) {
        mirrorController.domains.setModel(domainGroup);
      }
    },
  })
  readonly domains!: ModelRef<this, DomainGroup>;
}
