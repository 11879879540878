// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Equals} from "@swim/util";
import {Length, R2Path} from "@swim/math";
import {
  GeoShape,
  GeoPath,
  GeoJson,
  GeoJsonGeometry,
  GeoJsonPoint,
  GeoJsonMultiPoint,
  GeoJsonLineString,
  GeoJsonMultiLineString,
  GeoJsonPolygon,
  GeoJsonMultiPolygon,
  GeoJsonGeometryCollection,
  GeoJsonFeature,
  GeoJsonFeatureCollection,
  GeoJsonProperties,
} from "@swim/geo";
import {Color} from "@swim/style";
import {Graphics, VectorIcon} from "@swim/graphics";
import {GeographicPoint} from "../"; // forward import
import {GeographicLine} from "../"; // forward import
import {GeographicArea} from "../"; // forward import
import {GeographicGroup} from "../"; // forward import

/** @public */
export abstract class Geographic implements Equals {
  abstract readonly geometry: GeoShape;

  abstract equals(that: unknown): boolean;

  static fromGeoJson(object: GeoJson): Geographic | null {
    if (object.type === "Feature") {
      return this.fromGeoJsonFeature(object);
    } else if (object.type === "FeatureCollection") {
      return this.fromGeoJsonFeatureCollection(object);
    } else {
      return this.fromGeoJsonGeometry(object);
    }
  }

  static fromGeoJsonGeometry(object: GeoJsonGeometry, properties?: GeoJsonProperties): Geographic {
    if (object.type === "Point") {
      return this.fromGeoJsonPoint(object, properties);
    } else if (object.type === "MultiPoint") {
      return this.fromGeoJsonMultiPoint(object, properties);
    } else if (object.type === "LineString") {
      return this.fromGeoJsonLineString(object, properties);
    } else if (object.type === "MultiLineString") {
      return this.fromGeoJsonMultiLineString(object, properties);
    } else if (object.type === "Polygon") {
      return this.fromGeoJsonPolygon(object, properties);
    } else if (object.type === "MultiPolygon") {
      return this.fromGeoJsonMultiPolygon(object, properties);
    } else if (object.type === "GeometryCollection") {
      return this.fromGeoJsonGeometryCollection(object, properties);
    } else {
      throw new TypeError("" + object);
    }
  }

  /** @internal */
  static fromGeoJsonPoint(object: GeoJsonPoint, properties?: GeoJsonProperties): GeographicPoint {
    let width: Length | null = null;
    let height: Length | null = null;
    let graphics: Graphics | null = null;
    let fill: Color | null = null;
    if (properties !== void 0) {
      if (properties.width !== void 0 || properties.height !== void 0) {
        if (typeof properties.width === "number" || typeof properties.width === "string") {
          try {
            width = Length.fromAny(properties.width);
          } catch (e) {
            // swallow
          }
        }
        if (typeof properties.height === "number" || typeof properties.height === "string") {
          try {
            height = Length.fromAny(properties.height);
          } catch (e) {
            // swallow
          }
        }
      } else if (typeof properties.radius === "number" || typeof properties.radius === "string") {
        try {
          height = width = Length.fromAny(properties.radius);
        } catch (e) {
          // swallow
        }
      }
      if (width !== null && height !== null && typeof properties.icon === "string") {
        try {
          const path = R2Path.parse(properties.icon);
          graphics = VectorIcon.create(width.pxValue(), height.pxValue(), path);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.fill === "string") {
        try {
          fill = Color.parse(properties.fill);
        } catch (e) {
          // swallow
        }
      }
    }
    const geometry = GeoJsonPoint.toShape(object);
    return new GeographicPoint(geometry, width, height, graphics, fill);
  }

  /** @internal */
  static fromGeoJsonMultiPoint(object: GeoJsonMultiPoint, properties?: GeoJsonProperties): GeographicGroup<GeographicPoint> {
    let width: Length | null = null;
    let height: Length | null = null;
    let graphics: Graphics | null = null;
    let fill: Color | null = null;
    if (properties !== void 0) {
      if (properties.width !== void 0 || properties.height !== void 0) {
        if (typeof properties.width === "number" || typeof properties.width === "string") {
          try {
            width = Length.fromAny(properties.width);
          } catch (e) {
            // swallow
          }
        }
        if (typeof properties.height === "number" || typeof properties.height === "string") {
          try {
            height = Length.fromAny(properties.height);
          } catch (e) {
            // swallow
          }
        }
      } else if (typeof properties.radius === "number" || typeof properties.radius === "string") {
        try {
          height = width = Length.fromAny(properties.radius);
        } catch (e) {
          // swallow
        }
      }
      if (width !== null && height !== null && typeof properties.icon === "string") {
        try {
          const path = R2Path.parse(properties.icon);
          graphics = VectorIcon.create(width.pxValue(), height.pxValue(), path);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.fill === "string") {
        try {
          fill = Color.parse(properties.fill);
        } catch (e) {
          // swallow
        }
      }
    }
    const geometry = GeoJsonMultiPoint.toShape(object);
    const shapes = geometry.shapes;
    const n = shapes.length;
    const geographics = new Array<GeographicPoint>(n);
    for (let i = 0; i < n; i += 1) {
      geographics[i] = new GeographicPoint(shapes[i]!, width, height, graphics, fill);
    }
    return new GeographicGroup(geographics, geometry);
  }

  /** @internal */
  static fromGeoJsonLineString(object: GeoJsonLineString, properties?: GeoJsonProperties): GeographicLine {
    let stroke: Color | null = null;
    let strokeWidth: Length | null = null;
    if (properties !== void 0) {
      if (typeof properties.stroke === "string") {
        try {
          stroke = Color.parse(properties.stroke);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.strokeWidth === "number" || typeof properties.strokeWidth === "string") {
        try {
          strokeWidth = Length.fromAny(properties.strokeWidth);
        } catch (e) {
          // swallow
        }
      }
    }
    const geometry = GeoPath.of(GeoJsonLineString.toShape(object));
    return new GeographicLine(geometry, stroke, strokeWidth);
  }

  /** @internal */
  static fromGeoJsonMultiLineString(object: GeoJsonMultiLineString, properties?: GeoJsonProperties): GeographicGroup<GeographicLine> {
    let stroke: Color | null = null;
    let strokeWidth: Length | null = null;
    if (properties !== void 0) {
      if (typeof properties.stroke === "string") {
        try {
          stroke = Color.parse(properties.stroke);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.strokeWidth === "number" || typeof properties.strokeWidth === "string") {
        try {
          strokeWidth = Length.fromAny(properties.strokeWidth);
        } catch (e) {
          // swallow
        }
      }
    }
    const geometry = GeoJsonMultiLineString.toShape(object);
    const shapes = geometry.shapes;
    const n = shapes.length;
    const geographics = new Array<GeographicLine>(n);
    for (let i = 0; i < n; i += 1) {
      geographics[i] = new GeographicLine(GeoPath.of(shapes[i]!), stroke, strokeWidth);
    }
    return new GeographicGroup(geographics, geometry);
  }

  /** @internal */
  static fromGeoJsonPolygon(object: GeoJsonPolygon, properties?: GeoJsonProperties): GeographicArea {
    let fill: Color | null = null;
    let stroke: Color | null = null;
    let strokeWidth: Length | null = null;
    if (properties !== void 0) {
      if (typeof properties.fill === "string") {
        try {
          fill = Color.parse(properties.fill);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.stroke === "string") {
        try {
          stroke = Color.parse(properties.stroke);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.strokeWidth === "number" || typeof properties.strokeWidth === "string") {
        try {
          strokeWidth = Length.fromAny(properties.strokeWidth);
        } catch (e) {
          // swallow
        }
      }
    }
    const geometry = GeoJsonPolygon.toShape(object);
    return new GeographicArea(geometry, fill, stroke, strokeWidth);
  }

  /** @internal */
  static fromGeoJsonMultiPolygon(object: GeoJsonMultiPolygon, properties?: GeoJsonProperties): GeographicGroup<GeographicArea> {
    let fill: Color | null = null;
    let stroke: Color | null = null;
    let strokeWidth: Length | null = null;
    if (properties !== void 0) {
      if (typeof properties.fill === "string") {
        try {
          fill = Color.parse(properties.fill);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.stroke === "string") {
        try {
          stroke = Color.parse(properties.stroke);
        } catch (e) {
          // swallow
        }
      }
      if (typeof properties.strokeWidth === "number" || typeof properties.strokeWidth === "string") {
        try {
          strokeWidth = Length.fromAny(properties.strokeWidth);
        } catch (e) {
          // swallow
        }
      }
    }
    const geometry = GeoJsonMultiPolygon.toShape(object);
    const shapes = geometry.shapes;
    const n = shapes.length;
    const geographics = new Array<GeographicArea>(n);
    for (let i = 0; i < n; i += 1) {
      geographics[i] = new GeographicArea(shapes[i]!, fill, stroke, strokeWidth);
    }
    return new GeographicGroup(geographics, geometry);
  }

  /** @internal */
  static fromGeoJsonGeometryCollection(object: GeoJsonGeometryCollection, properties?: GeoJsonProperties): GeographicGroup {
    const geometries = object.geometries;
    const n = geometries.length;
    const geographics = new Array<Geographic>(n);
    for (let i = 0; i < n; i += 1) {
      geographics[i] = this.fromGeoJsonGeometry(geometries[i]!, properties);
    }
    return new GeographicGroup(geographics);
  }

  /** @internal */
  static fromGeoJsonFeature(object: GeoJsonFeature): Geographic | null {
    const geometry = object.geometry;
    if (geometry !== null) {
      const properties = object.properties;
      return this.fromGeoJsonGeometry(geometry, properties !== null ? properties : void 0);
    } else {
      return null;
    }
  }

  /** @internal */
  static fromGeoJsonFeatureCollection(object: GeoJsonFeatureCollection): GeographicGroup {
    const features = object.features;
    const geographics = new Array<Geographic>();
    for (let i = 0, n = features.length; i < n; i += 1) {
      const geographic = this.fromGeoJsonFeature(features[i]!);
      if (geographic !== null) {
        geographics.push(geographic);
      }
    }
    return new GeographicGroup(geographics);
  }
}
