// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Timing} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import {Look, Feel, MoodVector, ThemeMatrix} from "@swim/theme";
import {ViewRef} from "@swim/view";
import type {HtmlView} from "@swim/dom";
import {WidgetView} from "./WidgetView";

/** @public */
export class WidgetCard extends WidgetView {
  constructor(node: HTMLElement) {
    super(node);
    this.initWidget();
    this.initTheme();
  }

  protected initWidget(): void {
    this.addClass("widget");
    this.position.setState("relative", Affinity.Intrinsic);
    this.borderTopRightRadius.setState(4, Affinity.Intrinsic);
    this.borderBottomRightRadius.setState(4, Affinity.Intrinsic);
    this.borderBottomLeftRadius.setState(4, Affinity.Intrinsic);
    this.borderTopLeftRadius.setState(4, Affinity.Intrinsic);
    this.boxSizing.setState("border-box", Affinity.Intrinsic);
    this.overflowX.setState("hidden", Affinity.Intrinsic);
    this.overflowY.setState("hidden", Affinity.Intrinsic);
    this.pointerEvents.setState("auto", Affinity.Intrinsic);
  }

  protected initTheme(): void {
    this.modifyTheme(Feel.default, [[Feel.raised, 1]]);
  }

  @ViewRef<WidgetCard, HtmlView>({
    extends: true,
    initView(headerView: HtmlView): void {
      WidgetView.header.prototype.initView.call(this, headerView);
      headerView.display.setState("flex", Affinity.Intrinsic);
      headerView.justifyContent.setState("space-between", Affinity.Intrinsic);
      headerView.height.setState(36, Affinity.Intrinsic);
      headerView.paddingLeft.setState(18, Affinity.Intrinsic);
      headerView.paddingRight.setState(18, Affinity.Intrinsic);
    },
  })
  override readonly header!: ViewRef<this, HtmlView>;
  static override readonly header: MemberFastenerClass<WidgetCard, "header">;

  @ViewRef<WidgetCard, HtmlView>({
    extends: true,
    initView(titleView: HtmlView): void {
      WidgetView.headerTitle.prototype.initView.call(this, titleView);
      titleView.alignSelf.setState("center", Affinity.Intrinsic);
      titleView.color.setLook(Look.neutralColor, Affinity.Intrinsic);
    },
  })
  override readonly headerTitle!: ViewRef<this, HtmlView> & {create(value?: string): HtmlView};
  static override readonly headerTitle: MemberFastenerClass<WidgetCard, "headerTitle">;

  @ViewRef<WidgetCard, HtmlView>({
    extends: true,
    initView(subtitleView: HtmlView): void {
      WidgetView.headerSubtitle.prototype.initView.call(this, subtitleView);
      subtitleView.alignSelf.setState("center", Affinity.Intrinsic);
      subtitleView.color.setLook(Look.neutralColor, Affinity.Intrinsic);
    },
  })
  override readonly headerSubtitle!: ViewRef<this, HtmlView> & {create(value?: string): HtmlView};
  static override readonly headerSubtitle: MemberFastenerClass<WidgetCard, "headerSubtitle">;

  @ViewRef<WidgetCard, HtmlView>({
    extends: true,
    initView(footerView: HtmlView): void {
      WidgetView.footer.prototype.initView.call(this, footerView);
      footerView.display.setState("flex", Affinity.Intrinsic);
      footerView.justifyContent.setState("space-between", Affinity.Intrinsic);
      footerView.height.setState(12, Affinity.Intrinsic);
      footerView.paddingLeft.setState(18, Affinity.Intrinsic);
      footerView.paddingRight.setState(18, Affinity.Intrinsic);
    },
  })
  override readonly footer!: ViewRef<this, HtmlView>;
  static override readonly footer: MemberFastenerClass<WidgetCard, "footer">;

  protected override onApplyTheme(theme: ThemeMatrix, mood: MoodVector, timing: Timing | boolean): void {
    super.onApplyTheme(theme, mood, timing);
    if (this.backgroundColor.hasAffinity(Affinity.Intrinsic)) {
      let backgroundColor = theme.getOr(Look.backgroundColor, mood, null);
      if (backgroundColor !== null) {
        backgroundColor = backgroundColor.alpha(0.9);
      }
      this.backgroundColor.setState(backgroundColor, timing, Affinity.Intrinsic);
    }
  }
}
