// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import type {Model, Trait} from "@swim/model";
import {Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Controller, TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import type {GadgetController} from "../gadget/GadgetController";
import {WidgetView} from "./WidgetView";
import {WidgetCard} from "./WidgetCard";
import {WidgetTitle, WidgetSubtitle, WidgetTrait} from "./WidgetTrait";
import type {WidgetControllerObserver} from "./WidgetControllerObserver";

/** @public */
export interface WidgetControllerGadgetExt {
  attachGadgetTrait(gadgetTrait: Trait, gadgetController: GadgetController): void;
  detachGadgetTrait(gadgetTrait: Trait, gadgetController: GadgetController): void;
  attachGadgetView(gadgetView: HtmlView, gadgetController: GadgetController): void;
  detachGadgetView(gadgetView: HtmlView, gadgetController: GadgetController): void;
}

/** @public */
export abstract class WidgetController extends Controller {
  override readonly observerType?: Class<WidgetControllerObserver>;

  setTitle(title: WidgetTitle | null): void {
    const widgetTrait = this.widget.trait;
    if (widgetTrait !== null) {
      widgetTrait.title.setValue(title);
    }
  }

  setSubtitle(subtitle: WidgetSubtitle | null): void {
    const widgetTrait = this.widget.trait;
    if (widgetTrait !== null) {
      widgetTrait.subtitle.setValue(subtitle);
    }
  }

  @TraitViewRef<WidgetController, WidgetTrait, WidgetView>({
    traitType: WidgetTrait,
    observesTrait: true,
    willAttachTrait(widgetTrait: WidgetTrait): void {
      this.owner.callObservers("controllerWillAttachWidgetTrait", widgetTrait, this.owner);
    },
    didAttachTrait(widgetTrait: WidgetTrait): void {
      const widgetView = this.view;
      if (widgetView !== null) {
        this.owner.setHeaderTitleView(widgetTrait.title.value, widgetTrait);
        this.owner.setHeaderSubtitleView(widgetTrait.subtitle.value, widgetTrait);
      }

      const widgetModel = widgetTrait.model;
      if (widgetModel !== null) {
        let widgetChild = widgetModel.firstChild;
        while (widgetChild !== null) {
          widgetTrait.gadgets.bindModel(widgetChild, null);
          widgetChild = widgetChild.nextSibling;
        }
      }

      const gadgetTraits = widgetTrait.gadgets.traits;
      for (const traitId in gadgetTraits) {
        const gadgetTrait = gadgetTraits[traitId]!;
        this.owner.gadgets.addTraitController(gadgetTrait);
      }

      if (widgetTrait.mounted) {
        widgetTrait.consume(this);
      }
    },
    willDetachTrait(widgetTrait: WidgetTrait): void {
      if (widgetTrait.mounted) {
        widgetTrait.unconsume(this);
      }

      const gadgetTraits = widgetTrait.gadgets.traits;
      for (const traitId in gadgetTraits) {
        const gadgetTrait = gadgetTraits[traitId]!;
        this.owner.gadgets.deleteTraitController(gadgetTrait);
      }

      const widgetView = this.view;
      if (widgetView !== null) {
        this.owner.setHeaderTitleView(null, widgetTrait);
        this.owner.setHeaderSubtitleView(null, widgetTrait);
      }
    },
    didDetachTrait(widgetTrait: WidgetTrait): void {
      this.owner.callObservers("controllerDidDetachWidgetTrait", widgetTrait, this.owner);
    },
    traitDidMount(widgetTrait: WidgetTrait): void {
      widgetTrait.consume(this.owner);
    },
    traitWillUnmount(widgetTrait: WidgetTrait): void {
      widgetTrait.unconsume(this.owner);
    },
    traitDidSetTitle(newTitle: WidgetTitle | null, oldTitle: WidgetTitle | null, widgetTrait: WidgetTrait): void {
      this.owner.setHeaderTitleView(newTitle, widgetTrait);
    },
    traitDidSetSubtitle(newSubtitle: WidgetSubtitle | null, oldSubtitle: WidgetSubtitle | null, widgetTrait: WidgetTrait): void {
      this.owner.setHeaderSubtitleView(newSubtitle, widgetTrait);
    },
    traitWillAttachGadget(gadgetTrait: Trait, targetTrait: Trait): void {
      this.owner.gadgets.addTraitController(gadgetTrait, targetTrait);
    },
    traitDidDetachGadget(gadgetTrait: Trait): void {
      this.owner.gadgets.deleteTraitController(gadgetTrait);
    },
    detectGadgetModel(model: Model): Trait | null {
      return null;
    },
    viewType: WidgetView,
    observesView: true,
    initView(widgetView: WidgetView): void {
      widgetView.addClass("widget");
      widgetView.header.insertView();
      widgetView.footer.insertView();
      widgetView.modifyTheme(Feel.default, [[Feel.transparent, 1]], false);
    },
    willAttachView(widgetView: WidgetView): void {
      this.owner.callObservers("controllerWillAttachWidgetView", widgetView, this.owner);
    },
    didAttachView(widgetView: WidgetView): void {
      this.owner.header.setView(widgetView.header.view);
      this.owner.headerTitle.setView(widgetView.headerTitle.view);
      this.owner.headerSubtitle.setView(widgetView.headerSubtitle.view);
      this.owner.footer.setView(widgetView.footer.view);

      const widgetTrait = this.trait;
      if (widgetTrait !== null) {
        this.owner.setHeaderTitleView(widgetTrait.title.value, widgetTrait);
        this.owner.setHeaderSubtitleView(widgetTrait.subtitle.value, widgetTrait);
      }

      const gadgetControllers = this.owner.gadgets.controllers;
      for (const controllerId in gadgetControllers) {
        const gadgetController = gadgetControllers[controllerId]!;
        const gadgetView = gadgetController.gadget.view;
        if (gadgetView !== null && gadgetView.parent === null) {
          gadgetController.gadget.insertView(widgetView, void 0, widgetView.footer.view);
        }
      }
    },
    willDetachView(widgetView: WidgetView): void {
      this.owner.header.setView(null);
      this.owner.headerTitle.setView(null);
      this.owner.headerSubtitle.setView(null);
      this.owner.footer.setView(null);
    },
    didDetachView(widgetView: WidgetView): void {
      this.owner.callObservers("controllerDidDetachWidgetView", widgetView, this.owner);
    },
    viewWillAttachHeader(headerView: HtmlView): void {
      this.owner.header.setView(headerView);
    },
    viewDidDetachHeader(headerView: HtmlView): void {
      this.owner.header.setView(null);
    },
    viewWillAttachHeaderTitle(titleView: HtmlView): void {
      this.owner.header.setView(titleView);
    },
    viewDidDetachHeaderTitle(titleView: HtmlView): void {
      this.owner.header.setView(null);
    },
    viewWillAttachHeaderSubtitle(subtitleView: HtmlView): void {
      this.owner.header.setView(subtitleView);
    },
    viewDidDetachHeaderSubtitle(subtitleView: HtmlView): void {
      this.owner.header.setView(null);
    },
    viewWillAttachFooter(footerView: HtmlView): void {
      this.owner.footer.setView(footerView);
    },
    viewDidDetachFooter(footerView: HtmlView): void {
      this.owner.footer.setView(null);
    },
    createView(): WidgetView {
      return WidgetCard.create();
    },
  })
  readonly widget!: TraitViewRef<this, WidgetTrait, WidgetView>;
  static readonly widget: MemberFastenerClass<WidgetController, "widget">;

  @ViewRef<WidgetController, HtmlView>({
    type: HtmlView,
    initView(headerView: HtmlView): void {
      headerView.addClass("widget-header");
    },
    willAttachView(headerView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachHeaderView", headerView, this.owner);
    },
    didDetachView(headerView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachHeaderView", headerView, this.owner);
    },
  })
  readonly header!: ViewRef<this, HtmlView>;
  static readonly header: MemberFastenerClass<WidgetController, "header">;

  protected createHeaderTitleView(title: WidgetTitle, widgetTrait: WidgetTrait): HtmlView | string | null {
    if (typeof title === "function") {
      return title(widgetTrait);
    } else {
      return title;
    }
  }

  protected setHeaderTitleView(title: WidgetTitle | null, widgetTrait: WidgetTrait): void {
    const widgetView = this.widget.view;
    if (widgetView !== null) {
      const titleView = title !== null ? this.createHeaderTitleView(title, widgetTrait) : null;
      widgetView.headerTitle.setView(titleView);
    }
  }

  @ViewRef<WidgetController, HtmlView>({
    type: HtmlView,
    initView(titleView: HtmlView): void {
      titleView.addClass("widget-title");
    },
    willAttachView(titleView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachHeaderTitleView", titleView, this.owner);
    },
    didDetachView(titleView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachHeaderTitleView", titleView, this.owner);
    },
  })
  readonly headerTitle!: ViewRef<this, HtmlView>;
  static readonly headerTitle: MemberFastenerClass<WidgetController, "headerTitle">;

  protected createHeaderSubtitleView(subtitle: WidgetSubtitle, widgetTrait: WidgetTrait): HtmlView | string | null {
    if (typeof subtitle === "function") {
      return subtitle(widgetTrait);
    } else {
      return subtitle;
    }
  }

  protected setHeaderSubtitleView(subtitle: WidgetSubtitle | null, widgetTrait: WidgetTrait): void {
    const widgetView = this.widget.view;
    if (widgetView !== null) {
      const subtitleView = subtitle !== null ? this.createHeaderSubtitleView(subtitle, widgetTrait) : null;
      widgetView.headerSubtitle.setView(subtitleView);
    }
  }

  @ViewRef<WidgetController, HtmlView>({
    type: HtmlView,
    initView(subtitleView: HtmlView): void {
      subtitleView.addClass("widget-subtitle");
    },
    willAttachView(subtitleView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachHeaderSubtitleView", subtitleView, this.owner);
    },
    didDetachView(subtitleView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachHeaderSubtitleView", subtitleView, this.owner);
    },
  })
  readonly headerSubtitle!: ViewRef<this, HtmlView>;
  static readonly headerSubtitle: MemberFastenerClass<WidgetController, "headerSubtitle">;

  @ViewRef<WidgetController, HtmlView>({
    type: HtmlView,
    initView(footerView: HtmlView): void {
      footerView.addClass("widget-footer");
    },
    willAttachView(footerView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachFooterView", footerView, this.owner);
    },
    didDetachView(footerView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachFooterView", footerView, this.owner);
    },
  })
  readonly footer!: ViewRef<this, HtmlView>;
  static readonly footer: MemberFastenerClass<WidgetController, "footer">;

  @TraitViewControllerSet<WidgetController, Trait, HtmlView, GadgetController, WidgetControllerGadgetExt>({
    implements: true,
    binds: true,
    observes: true,
    get parentView(): HtmlView | null {
      return this.owner.widget.view;
    },
    getTraitViewRef(gadgetController: GadgetController): TraitViewRef<unknown, Trait, HtmlView> {
      return gadgetController.gadget;
    },
    willAttachController(gadgetController: GadgetController): void {
      this.owner.callObservers("controllerWillAttachGadget", gadgetController, this.owner);
    },
    didAttachController(gadgetController: GadgetController): void {
      const gadgetTrait = gadgetController.gadget.trait;
      if (gadgetTrait !== null) {
        this.attachGadgetTrait(gadgetTrait, gadgetController);
      }
      const gadgetView = gadgetController.gadget.view;
      if (gadgetView !== null) {
        this.attachGadgetView(gadgetView, gadgetController);
      }
    },
    willDetachController(gadgetController: GadgetController): void {
      const gadgetView = gadgetController.gadget.view;
      if (gadgetView !== null) {
        this.detachGadgetView(gadgetView, gadgetController);
      }
      const gadgetTrait = gadgetController.gadget.trait;
      if (gadgetTrait !== null) {
        this.detachGadgetTrait(gadgetTrait, gadgetController);
      }
    },
    didDetachController(gadgetController: GadgetController): void {
      this.owner.callObservers("controllerDidDetachGadget", gadgetController, this.owner);
    },
    controllerWillAttachGadgetTrait(gadgetTrait: Trait, gadgetController: GadgetController): void {
      this.owner.callObservers("controllerWillAttachGadgetTrait", gadgetTrait, gadgetController, this.owner);
      this.attachGadgetTrait(gadgetTrait, gadgetController);
    },
    controllerDidDetachGadgetTrait(gadgetTrait: Trait, gadgetController: GadgetController): void {
      this.detachGadgetTrait(gadgetTrait, gadgetController);
      this.owner.callObservers("controllerDidDetachGadgetTrait", gadgetTrait, gadgetController, this.owner);
    },
    attachGadgetTrait(gadgetTrait: Trait, gadgetController: GadgetController): void {
      // hook
    },
    detachGadgetTrait(gadgetTrait: Trait, gadgetController: GadgetController): void {
      // hook
    },
    controllerWillAttachGadgetView(gadgetView: HtmlView, gadgetController: GadgetController): void {
      this.owner.callObservers("controllerWillAttachGadgetView", gadgetView, gadgetController, this.owner);
      this.attachGadgetView(gadgetView, gadgetController);
    },
    controllerDidDetachGadgetView(gadgetView: HtmlView, gadgetController: GadgetController): void {
      this.detachGadgetView(gadgetView, gadgetController);
      this.owner.callObservers("controllerDidDetachGadgetView", gadgetView, gadgetController, this.owner);
    },
    attachGadgetView(gadgetView: HtmlView, gadgetController: GadgetController): void {
      gadgetView.addClass("gadget");
      gadgetView.position.setState("relative", Affinity.Intrinsic);
    },
    detachGadgetView(gadgetView: HtmlView, gadgetController: GadgetController): void {
      gadgetView.remove();
    },
    createController(gadgetTrait?: Trait): GadgetController {
      return TraitViewControllerSet.prototype.createController.call(this);
    },
  })
  readonly gadgets!: TraitViewControllerSet<this, Trait, HtmlView, GadgetController>;
  static readonly gadgets: MemberFastenerClass<WidgetController, "gadgets">;

  protected override didMount(): void {
    const widgetTrait = this.widget.trait;
    if (widgetTrait !== null) {
      widgetTrait.consume(this);
    }
    super.didMount();
  }

  protected override willUnmount(): void {
    super.willUnmount();
    const widgetTrait = this.widget.trait;
    if (widgetTrait !== null) {
      widgetTrait.unconsume(this);
    }
  }
}
