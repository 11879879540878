// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {AnyUri, Uri, UriScheme, UriAuthority, UriUser} from "@swim/uri";
import {Model, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, DomainTrait} from "@swim/domain";
import {DomainPlugin} from "@swim/prism";
import {PulseTrait} from "./PulseTrait";
import {PlaneEntity} from "./PlaneEntity";

/** @public */
export class FabricPlugin extends DomainPlugin {
  override get id(): string {
    return "fabric";
  }

  override get title(): string {
    return "Fabric";
  }

  override createDomain(hostUri: AnyUri): DomainTrait | null {
    hostUri = Uri.fromAny(hostUri);
    const nodeUri = Uri.parse("swim:meta:edge");
    const planeModel = new Model();

    const domainTrait = new DomainTrait();
    planeModel.setTrait("domain", domainTrait);

    const entityTrait = new PlaneEntity(hostUri as Uri);
    entityTrait.title.setValue((hostUri as Uri).authority.withUser(UriUser.undefined()).toString());
    planeModel.setTrait("entity", entityTrait);

    planeModel.setTrait("selectable", new SelectableTrait());
    planeModel.setTrait("status", new StatusTrait());
    planeModel.setTrait("indicated", new IndicatedTrait());

    const pulseTrait = new PulseTrait(nodeUri);
    pulseTrait.fabricIndicators = false;
    planeModel.setTrait("pulse", pulseTrait);

    return domainTrait;
  }

  override queryDomain(query: string): DomainTrait | null {
    try {
      let hostUri: Uri;
      try {
        const authority = UriAuthority.parse(query);
        hostUri = Uri.create(UriScheme.create("warps"), authority);
      } catch (e) {
        hostUri = Uri.parse(query);
      }
      return this.createDomain(hostUri);
    } catch (swallow) {
      // Ignore parse exceptions.
      return null;
    }
  }
}
