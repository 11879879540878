// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Class, AnyTiming, Timing} from "@swim/util";
import {Affinity, Property, Animator} from "@swim/component";
import {AnyLength, Length} from "@swim/math";
import {Look} from "@swim/theme";
import {PositionGestureInput, PositionGesture} from "@swim/view";
import {Graphics, Icon, FilledIcon, CircleIcon, PolygonIcon, EnclosedIcon} from "@swim/graphics";
import {GeoIconView} from "@swim/map";
import type {Geographic} from "./Geographic";
import {GeographicPoint} from "./GeographicPoint";
import type {GeographicView} from "./GeographicView";
import type {GeographicViewObserver} from "./GeographicViewObserver";

/** @public */
export class GeographicPointView extends GeoIconView implements GeographicView {
  override readonly observerType?: Class<GeographicViewObserver<GeographicPointView>>;

  get icon(): Icon | null {
    const graphics = this.graphics.state;
    if (graphics instanceof EnclosedIcon) {
      return graphics.inner;
    } else {
      return null;
    }
  }

  setIcon(inner: Graphics | null): void {
    if (inner instanceof FilledIcon) {
      inner = inner.withFillLook(Look.accentColor);
    }
    let icon = this.graphics.state;
    if (inner instanceof Icon) {
      if (icon instanceof EnclosedIcon) {
        icon = icon.withInner(inner);
      } else {
        icon = this.createIcon(inner);
      }
      this.graphics.setState(icon);
    } else {
      this.graphics.setState(inner);
    }
  }

  protected createOuterIcon(): FilledIcon {
    return PolygonIcon.create(6);
  }

  protected createIcon(inner: Icon | null): Icon {
    let outer: Icon | null;
    if (this.highlighted.value) {
      outer = this.createOuterIcon();
    } else {
      outer = null;
    }
    let icon: EnclosedIcon;
    if (this.highlighted.value) {
      icon = EnclosedIcon.embossed(outer, inner);
    } else {
      icon = EnclosedIcon.create(outer, inner);
    }
    icon = icon.withInnerScale(Math.sqrt(2) / 2);
    return icon;
  }

  @Animator<GeographicPointView, Length | null, AnyLength | null>({
    type: Length,
    value: null,
    didSetValue(width: Length | null): void {
      if (width !== null && this.owner.highlighted.value) {
        //width = width.times(GeographicPointView.SelectionScale);
        width = Length.px(48);
      }
      this.owner.iconWidth.setState(width, Affinity.Intrinsic);
    },
  })
  readonly width!: Animator<this, Length | null, AnyLength | null>;

  @Animator<GeographicPointView, Length | null, AnyLength | null>({
    type: Length,
    value: null,
    didSetValue(height: Length | null): void {
      if (height !== null && this.owner.highlighted.value) {
        //height = height.times(GeographicPointView.SelectionScale);
        height = Length.px(48);
      }
      this.owner.iconHeight.setState(height, Affinity.Intrinsic);
    },
  })
  readonly height!: Animator<this, Length | null, AnyLength | null>;

  @Property({type: Boolean, value: false})
  readonly highlighted!: Property<this, boolean>;

  highlight(timing?: AnyTiming | boolean): void {
    if (!this.highlighted.value) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.willHighlight(timing as Timing | boolean);
      this.highlighted.setValue(true);
      this.onHighlight(timing as Timing | boolean);
      this.didHighlight(timing as Timing | boolean);
    }
  }

  protected willHighlight(timing: Timing | boolean): void {
    this.callObservers("geographicWillHighlight", timing, this);
  }

  protected onHighlight(timing: Timing | boolean): void {
    const width = this.width.state;
    if (width !== null) {
      //this.iconWidth.setState(width.times(GeographicPointView.SelectionScale), timing, Affinity.Intrinsic);
      this.iconWidth.setState(48, timing, Affinity.Intrinsic);
    }
    const height = this.height.state;
    if (height !== null) {
      //this.iconHeight.setState(height.times(GeographicPointView.SelectionScale), timing, Affinity.Intrinsic);
      this.iconHeight.setState(48, timing, Affinity.Intrinsic);
    }

    const oldIcon = this.graphics.state;
    if (oldIcon instanceof EnclosedIcon) {
      const outerIconColor = this.getLook(Look.accentColor)!;
      let outer = this.createOuterIcon().withFillLook(null);
      outer = outer.withFillColor(outerIconColor.alpha(0));
      let newIcon = oldIcon.withOuter(outer);
      this.graphics.setState(newIcon);
      outer = outer.withFillColor(null).withFillLook(Look.accentColor);
      newIcon = newIcon.withOuter(outer).withInnerMoodModifier(EnclosedIcon.embossedMoodModifier);
      this.graphics.setState(newIcon, timing);
    }
  }

  protected didHighlight(timing: Timing | boolean): void {
    this.callObservers("geographicDidHighlight", timing, this);
  }

  unhighlight(timing?: AnyTiming | boolean): void {
    if (this.highlighted.value) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.willUnhighlight(timing as Timing | boolean);
      this.highlighted.setValue(false);
      this.onUnhighlight(timing as Timing | boolean);
      this.didUnhighlight(timing as Timing | boolean);
    }
  }

  protected willUnhighlight(timing: Timing | boolean): void {
    this.callObservers("geographicWillUnhighlight", timing, this);
  }

  protected onUnhighlight(timing: Timing | boolean): void {
    const width = this.width.state;
    if (width !== null) {
      this.iconWidth.setState(width, timing, Affinity.Intrinsic);
    }
    const height = this.height.state;
    if (height !== null) {
      this.iconHeight.setState(height, timing, Affinity.Intrinsic);
    }

    const oldIcon = this.graphics.state;
    if (oldIcon instanceof EnclosedIcon) {
      let outer = oldIcon.outer;
      if (outer instanceof FilledIcon) {
        const outerIconColor = outer.fillColor;
        if (outerIconColor !== null) {
          outer = outer.withFillColor(outerIconColor.alpha(0)).withFillLook(null);
        }
      }
      const newIcon = oldIcon.withOuter(outer)
                             .withInnerMoodModifier(null);
      this.graphics.setState(newIcon, timing);
    }
  }

  protected didUnhighlight(timing: Timing | boolean): void {
    this.callObservers("geographicDidUnhighlight", timing, this);
  }

  setState(geographic: Geographic, timing?: AnyTiming | boolean): void {
    if (geographic instanceof GeographicPoint) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.geoCenter.setState(geographic.geometry, timing);
      if (geographic.width !== null) {
        this.width.setState(geographic.width, timing);
      }
      if (geographic.height !== null) {
        this.height.setState(geographic.height, timing);
      }
      if (geographic.graphics !== null) {
        this.setIcon(geographic.graphics);
      }
      if (geographic.fill !== null) {
        let icon = this.graphics.state;
        if (icon instanceof EnclosedIcon) {
          let inner = icon.inner;
          if (inner instanceof FilledIcon) {
            inner = inner.withFillColor(geographic.fill)
                         .withFillLook(null);
            icon = icon.withInner(inner);
            this.graphics.setState(icon, timing);
          }
        }
      }
    }
  }

  @PositionGesture<GeographicPointView, GeographicPointView>({
    self: true,
    didMovePress(input: PositionGestureInput, event: Event | null): void {
      const dx = input.dx;
      const dy = input.dy;
      if (dx * dx + dy * dy >= 4 * 4) {
        input.preventDefault();
      }
    },
    didPress(input: PositionGestureInput, event: Event | null): void {
      if (!input.defaultPrevented) {
        this.owner.callObservers("geographicDidPress", input, event, this.owner);
      }
    },
    didLongPress(input: PositionGestureInput): void {
      if (!input.defaultPrevented) {
        this.owner.callObservers("geographicDidLongPress", input, this.owner);
      }
    },
  })
  readonly gesture!: PositionGesture<GeographicPointView, GeographicPointView>;

  /** @internal */
  static SelectionScale: number = 2;

  static fromGeographic(geographic: GeographicPoint): GeographicPointView {
    const view = new GeographicPointView();

    view.geoCenter.setState(geographic.geometry);

    let width = geographic.width;
    let height = geographic.height;
    if (width === null && height === null) {
      width = Length.px(10);
      height = width;
    } else if (width === null && height !== null) {
      width = height;
    } else if (width !== null && height === null) {
      height = width;
    }
    view.width.setState(width);
    view.height.setState(height);

    let graphics = geographic.graphics;
    if (graphics === null) {
      graphics = CircleIcon.create();
    }
    view.setIcon(graphics);

    if (geographic.fill !== null) {
      let icon = view.graphics.state;
      if (icon instanceof EnclosedIcon) {
        let inner = icon.inner;
        if (inner instanceof FilledIcon) {
          inner = inner.withFillColor(geographic.fill)
                       .withFillLook(null);
          icon = icon.withInner(inner);
          view.graphics.setState(icon);
        }
      }
    }

    return view;
  }
}
