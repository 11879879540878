// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Graphics, VectorIcon} from "@swim/graphics";
import {MirrorController, MirrorPlugin} from "@swim/prism";
import {PortalController} from "./PortalController";

/** @public */
export class PortalPlugin extends MirrorPlugin {
  override get id(): string {
    return "portal";
  }

  override get title(): string {
    return "Portal";
  }

  override get icon(): Graphics {
    return PortalPlugin.icon;
  }

  override createController(): MirrorController {
    return new PortalController();
  }

  @Lazy
  static get icon(): Graphics {
    return VectorIcon.create(24, 24, "M3,13L21,13L21,3L3,3L3,13ZM3,21L11,21L11,15L3,15L3,21ZM13,21L21,21L21,15L13,15L13,21Z");
  }
}
