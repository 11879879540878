// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import {Length} from "@swim/math";
import {Trait} from "@swim/model";
import {Look} from "@swim/theme";
import {View, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {GraphicsView, CanvasView} from "@swim/graphics";
import {TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {SliceTrait, SliceView, SliceController, PieView, PieTrait, PieControllerSliceExt, PieController} from "@swim/pie";
import type {GadgetController} from "../gadget/GadgetController";
import {PieGadgetSliceController} from "./PieGadgetSliceController";
import type {PieGadgetControllerObserver} from "./PieGadgetControllerObserver";

/** @public */
export class PieGadgetController extends PieController implements GadgetController {
  override readonly observerType?: Class<PieGadgetControllerObserver>;

  protected layoutPie(hasLegend?: boolean): void {
    const pieView = this.pie.view;
    if (pieView !== null) {
      if (hasLegend === void 0) {
        hasLegend = false;
        const sliceViews = pieView.slices.views;
        for (const viewId in sliceViews) {
          const sliceView = sliceViews[viewId]!;
          if (sliceView.legend.view !== null) {
            hasLegend = true;
            break;
          }
        }
      }

      const hasTitle = this.title.view !== null;

      const outerRadius = hasLegend ? 35 : 40;
      pieView.outerRadius.setState(Length.pct(outerRadius), Affinity.Intrinsic);
      if (hasTitle) {
        pieView.innerRadius.setState(Length.pct(20), Affinity.Intrinsic);
      } else {
        pieView.innerRadius.setState(Length.pct(5), Affinity.Intrinsic);
      }
    }
  }

  @TraitViewRef<PieGadgetController, PieTrait, PieView>({
    extends: true,
    initView(pieView: PieView): void {
      PieController.pie.prototype.initView.call(this, pieView);
      pieView.innerRadius.setState(Length.pct(5), Affinity.Intrinsic);
      pieView.outerRadius.setState(Length.pct(35), Affinity.Intrinsic);
      pieView.tickRadius.setState(Length.pct(40), Affinity.Intrinsic);
      pieView.font.setLook(Look.font, Affinity.Intrinsic);
      this.owner.layoutPie();
    },
  })
  override readonly pie!: TraitViewRef<this, PieTrait, PieView>;
  static override readonly pie: MemberFastenerClass<PieGadgetController, "pie">;

  @ViewRef<PieGadgetController, GraphicsView>({
    extends: true,
    didAttachView(titleView: GraphicsView, targetView: View | null): void {
      PieController.title.prototype.didAttachView.call(this, titleView, targetView);
      this.owner.layoutPie();
    },
    willDetachView(titleView: GraphicsView): void {
      PieController.title.prototype.willDetachView.call(this, titleView);
      this.owner.layoutPie();
    },
  })
  override readonly title!: ViewRef<this, GraphicsView>;
  static override readonly title: MemberFastenerClass<PieController, "title">;

  @TraitViewControllerSet<PieGadgetController, SliceTrait, SliceView, SliceController, PieControllerSliceExt>({
    extends: true,
    implements: true,
    type: PieGadgetSliceController,
    attachSliceView(sliceView: SliceView, sliceController: SliceController): void {
      PieController.slices.prototype.attachSliceView.call(this, sliceView, sliceController);
      this.owner.layoutPie();
    },
    detachSliceView(sliceView: SliceView, sliceController: SliceController): void {
      PieController.slices.prototype.detachSliceView.call(this, sliceView, sliceController);
      this.owner.layoutPie();
    },
    attachSliceLegendView(legendView: GraphicsView, sliceController: SliceController): void {
      PieController.slices.prototype.attachSliceLegendView.call(this, legendView, sliceController);
      this.owner.layoutPie(true);
    },
    detachSliceLegendView(legendView: GraphicsView, sliceController: SliceController): void {
      PieController.slices.prototype.detachSliceLegendView.call(this, legendView, sliceController);
      this.owner.layoutPie();
    },
  })
  override readonly slices!: TraitViewControllerSet<this, SliceTrait, SliceView, SliceController> & PieControllerSliceExt;
  static override readonly slices: MemberFastenerClass<PieGadgetController, "slices">;

  @ViewRef<PieGadgetController, CanvasView>({
    type: CanvasView,
    didAttachView(canvasView: CanvasView): void {
      this.owner.pie.insertView(canvasView);
    },
    willDetachView(canvasView: CanvasView): void {
      this.owner.pie.removeView();
    },
  })
  readonly canvas!: ViewRef<this, CanvasView>;
  static readonly canvas: MemberFastenerClass<PieGadgetController, "canvas">;

  @TraitViewRef<PieGadgetController, Trait, HtmlView>({
    traitType: Trait,
    willAttachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerWillAttachGadgetTrait", gadgetTrait, this.owner);
    },
    didAttachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof PieTrait) {
        this.owner.pie.setTrait(gadgetTrait);
      }
    },
    willDetachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof PieTrait) {
        this.owner.pie.setTrait(null);
      }
    },
    didDetachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerDidDetachGadgetTrait", gadgetTrait, this.owner);
    },
    viewType: HtmlView,
    initView(gadgetView: HtmlView): void {
      gadgetView.height.setState(140, Affinity.Intrinsic);
      gadgetView.marginLeft.setState(18, Affinity.Intrinsic);
      gadgetView.marginRight.setState(18, Affinity.Intrinsic);
    },
    willAttachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachGadgetView", gadgetView, this.owner);
    },
    didAttachView(gadgetView: HtmlView): void {
      this.owner.canvas.insertView(gadgetView);
    },
    willDetachView(gadgetView: HtmlView): void {
      this.owner.canvas.removeView();
    },
    didDetachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachGadgetView", gadgetView, this.owner);
    },
  })
  readonly gadget!: TraitViewRef<this, Trait, HtmlView>;
  static readonly gadget: MemberFastenerClass<PieGadgetController, "gadget">;
}
