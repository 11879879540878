// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Mutable, Arrays} from "@swim/util";
import {GeoShape, GeoGroup} from "@swim/geo";
import {Geographic} from "./Geographic";

/** @public */
export class GeographicGroup<G extends Geographic = Geographic> extends Geographic {
  constructor(geographics: ReadonlyArray<G>, geometry: GeoGroup | null = null) {
    super();
    this.geographics = geographics;
    this.geometries = geometry;
  }

  readonly geographics: ReadonlyArray<G>;

  /** @internal */
  readonly geometries: GeoGroup | null;

  override get geometry(): GeoGroup {
    let geometry = this.geometries;
    if (geometry === null) {
      const geographics = this.geographics;
      const n = geographics.length;
      const shapes = new Array<GeoShape>(n);
      for (let i = 0; i < n; i += 1) {
        shapes[i] = geographics[i]!.geometry;
      }
      geometry = new GeoGroup(shapes);
      (this as Mutable<this>).geometries = geometry;
    }
    return geometry;
  }

  override equals(that: unknown): boolean {
    if (this === that) {
      return true;
    } else if (that instanceof GeographicGroup) {
      return Arrays.equal(this.geographics, that.geographics);
    }
    return false;
  }
}
