// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {ProviderClass, Provider} from "@swim/component";
import type {Controller} from "@swim/controller";
import type {PrismPlugin} from "./PrismPlugin";
import {PrismService} from "./PrismService";

/** @public */
export interface PrismProvider<C extends Controller, S extends PrismService<C> | null | undefined = PrismService<C>> extends Provider<C, S> {
  get plugins(): ReadonlyArray<PrismPlugin>;

  createService(): S;
}

/** @public */
export const PrismProvider = (function (_super: typeof Provider) {
  const PrismProvider = _super.extend("PrismProvider") as ProviderClass<PrismProvider<any, any>>;

  Object.defineProperty(PrismProvider.prototype, "plugins", {
    get<C extends Controller, S extends PrismService<C> | null | undefined>(this: PrismProvider<C, S>): ReadonlyArray<PrismPlugin> {
      let service: PrismService<C> | null | undefined = this.service;
      if (service === void 0 || service === null) {
        service = PrismService.global();
      }
      return service.plugins;
    },
    configurable: true,
  });

  PrismProvider.prototype.createService = function <C extends Controller, S extends PrismService<C> | null | undefined>(this: PrismProvider<C, S>): S {
    return PrismService.global() as S;
  };

  return PrismProvider;
})(Provider);
