// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Class, AnyTiming, Timing} from "@swim/util";
import {Property} from "@swim/component";
import {Look} from "@swim/theme";
import {PositionGestureInput, PositionGesture} from "@swim/view";
import {GeoView} from "@swim/map";
import type {Geographic} from "./Geographic";
import type {GeographicGroup} from "./GeographicGroup";
import {GeographicView} from "./GeographicView";
import type {GeographicViewObserver} from "./GeographicViewObserver";

/** @public */
export class GeographicGroupView extends GeoView implements GeographicView {
  override readonly observerType?: Class<GeographicViewObserver<GeographicGroupView>>;

  @Property({type: Boolean, value: false})
  readonly highlighted!: Property<this, boolean>;

  highlight(timing?: AnyTiming | boolean): void {
    if (!this.highlighted.value) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.willHighlight(timing as Timing | boolean);
      this.highlighted.setValue(true);
      this.onHighlight(timing as Timing | boolean);
      this.highlightChildren(timing as Timing | boolean);
      this.didHighlight(timing as Timing | boolean);
    }
  }

  protected willHighlight(timing: Timing | boolean): void {
    this.callObservers("geographicWillHighlight", timing, this);
  }

  protected onHighlight(timing: Timing | boolean): void {
    // hook
  }

  protected didHighlight(timing: Timing | boolean): void {
    this.callObservers("geographicDidHighlight", timing, this);
  }

  protected highlightChildren(timing: Timing | boolean): void {
    let child = this.firstChild;
    while (child !== null) {
      if (GeographicView.is(child)) {
        child.highlight(timing);
      }
      child = child.nextSibling;
    }
  }

  unhighlight(timing?: AnyTiming | boolean): void {
    if (this.highlighted.value) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.willUnhighlight(timing as Timing | boolean);
      this.highlighted.setValue(false);
      this.onUnhighlight(timing as Timing | boolean);
      this.unhighlightChildren(timing as Timing | boolean);
      this.didUnhighlight(timing as Timing | boolean);
    }
  }

  protected willUnhighlight(timing: Timing | boolean): void {
    this.callObservers("geographicWillUnhighlight", timing, this);
  }

  protected onUnhighlight(timing: Timing | boolean): void {
    // hook
  }

  protected didUnhighlight(timing: Timing | boolean): void {
    this.callObservers("geographicDidUnhighlight", timing, this);
  }

  protected unhighlightChildren(timing: Timing | boolean): void {
    let child = this.firstChild;
    while (child !== null) {
      if (GeographicView.is(child)) {
        child.unhighlight(timing);
      }
      child = child.nextSibling;
    }
  }

  setState(geographic: Geographic, timing?: AnyTiming | boolean): void {
    // TODO
  }

  @PositionGesture<GeographicGroupView, GeographicGroupView>({
    self: true,
    didMovePress(input: PositionGestureInput, event: Event | null): void {
      const dx = input.dx;
      const dy = input.dy;
      if (dx * dx + dy * dy >= 4 * 4) {
        input.preventDefault();
      }
    },
    didPress(input: PositionGestureInput, event: Event | null): void {
      if (!input.defaultPrevented) {
        this.owner.callObservers("geographicDidPress", input, event, this.owner);
      }
    },
    didLongPress(input: PositionGestureInput): void {
      if (!input.defaultPrevented) {
        this.owner.callObservers("geographicDidLongPress", input, this.owner);
      }
    },
  })
  readonly gesture!: PositionGesture<GeographicGroupView, GeographicGroupView>;

  static fromGeographic(geographic: GeographicGroup): GeographicGroupView {
    const view = new GeographicGroupView();
    const geographics = geographic.geographics;
    for (let i = 0, n = geographics.length; i < n; i += 1) {
      const geographicView = GeographicView.fromGeographic(geographics[i]!);
      view.appendChild(geographicView);
    }
    return view;
  }
}
