// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {AnyValue, Value} from "@swim/structure";
import {Uri, UriPath} from "@swim/uri";
import {MapDownlinkFastener} from "@swim/client";
import {Model, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {MetaHostEntity} from "./MetaHostEntity";

/** @public */
export class MetaHostGroup extends EntityGroup {
  constructor(metaPartUri: Uri) {
    super();
    this.metaPartUri = metaPartUri;
  }

  readonly metaPartUri: Uri;

  protected createHost(hostKey: string, metaHostUri: Uri): Model | null {
    const hostModel = new Model();

    const entityTrait = new MetaHostEntity(metaHostUri);
    if (hostKey === "") {
      entityTrait.title.setValue("Local Host");
    } else {
      entityTrait.title.setValue(hostKey);
    }
    hostModel.setTrait("entity", entityTrait);

    hostModel.setTrait("selectable", new SelectableTrait());
    hostModel.setTrait("status", new StatusTrait());
    hostModel.setTrait("indicated", new IndicatedTrait());
    hostModel.setTrait("pulse", new PulseTrait(metaHostUri));

    return hostModel;
  }

  protected didUpdateHost(key: Value, value: Value): void {
    const hostKey = key.stringValue("");
    let hostModel = this.getChild(hostKey);
    if (hostModel === null) {
      const metaPartUri = this.metaPartUri;
      const pathBuilder = UriPath.builder();
      if (metaPartUri.path.toString() === "meta:part") {
        pathBuilder.addSegment("meta:host");
      } else {
        pathBuilder.addPath(metaPartUri.path);
        pathBuilder.addSegment("host");
      }
      if (hostKey !== "") {
        pathBuilder.addSegment(hostKey);
      }
      const metaHostUri = Uri.create(metaPartUri.scheme, metaPartUri.authority, pathBuilder.bind());
      hostModel = this.createHost(hostKey, metaHostUri);
      if (hostModel !== null) {
        this.appendChild(hostModel, hostKey);
      }
    }
  }

  protected didRemoveHost(key: Value): void {
    const hostKey = key.stringValue("");
    this.removeChild(hostKey);
  }

  @MapDownlinkFastener<MetaHostGroup, Value, Value, AnyValue, AnyValue>({
    consumed: true,
    nodeUri(): Uri {
      return this.owner.metaPartUri;
    },
    laneUri: "hosts",
    didUpdate(key: Value, value: Value): void {
      this.owner.didUpdateHost(key, value);
    },
    didRemove(key: Value): void {
      this.owner.didRemoveHost(key);
    },
  })
  readonly hosts!: MapDownlinkFastener<this, Value, Value, AnyValue, AnyValue>;
}
