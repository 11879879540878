// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Mutable, Class, Equals} from "@swim/util";
import type {GeoShape} from "@swim/geo";
import {Model, ModelRef, Trait} from "@swim/model";
import {StatusTrait, EntityGroup} from "@swim/domain";
import type {DistrictTraitObserver} from "./DistrictTraitObserver";

/** @public */
export class DistrictTrait extends Trait {
  constructor() {
    super();
    this.minZoom = -Infinity;
    this.maxZoom = Infinity;
    this.boundary = null;
  }

  override readonly observerType!: Class<DistrictTraitObserver>;

  readonly minZoom: number;

  readonly maxZoom: number;

  setZoomRange(minZoom: number, maxZoom: number): void {
    if (this.minZoom !== minZoom || this.maxZoom !== maxZoom) {
      this.willSetZoomRange(minZoom, maxZoom);
      (this as Mutable<this>).minZoom = minZoom;
      (this as Mutable<this>).maxZoom = maxZoom;
      this.onSetZoomRange(minZoom, maxZoom);
      this.didSetZoomRange(minZoom, maxZoom);
    }
  }

  protected willSetZoomRange(minZoom: number, maxZoom: number): void {
    this.callObservers("districtWillSetZoomRange", minZoom, maxZoom, this);
  }

  protected onSetZoomRange(minZoom: number, maxZoom: number): void {
    if (this.model !== null) {
      this.requireUpdate(Model.NeedsValidate);
    }
  }

  protected didSetZoomRange(minZoom: number, maxZoom: number): void {
    this.callObservers("districtDidSetZoomRange", minZoom, maxZoom, this);
  }

  readonly boundary: GeoShape | null;

  setBoundary(newBoundary: GeoShape | null): void {
    const oldBoundary = this.boundary;
    if (!Equals(newBoundary, oldBoundary)) {
      this.willSetBoundary(newBoundary, oldBoundary);
      (this as Mutable<this>).boundary = newBoundary;
      this.onSetBoundary(newBoundary, oldBoundary);
      this.didSetBoundary(newBoundary, oldBoundary);
    }
  }

  protected willSetBoundary(newBoundary: GeoShape | null, oldBoundary: GeoShape | null): void {
    this.callObservers("districtWillSetBoundary", newBoundary, oldBoundary, this);
  }

  protected onSetBoundary(newBoundary: GeoShape | null, oldBoundary: GeoShape | null): void {
    if (this.model !== null) {
      this.requireUpdate(Model.NeedsValidate);
    }
  }

  protected didSetBoundary(newBoundary: GeoShape | null, oldBoundary: GeoShape | null): void {
    this.callObservers("districtDidSetBoundary", newBoundary, oldBoundary, this);
  }

  @ModelRef<DistrictTrait, EntityGroup>({
    key: true,
    type: EntityGroup,
    binds: true,
    createModel(): EntityGroup {
      const entityGroup = new EntityGroup();
      entityGroup.setTrait("status", new StatusTrait());
      return entityGroup;
    },
  })
  readonly subdistricts!: ModelRef<this, EntityGroup>;
}
