// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity} from "@swim/component";
import {Model, ModelRef, Trait, TraitRef, SelectionOptions, SelectableTrait} from "@swim/model";
import {Look, Feel} from "@swim/theme";
import {PositionGestureInput, View, ViewRef} from "@swim/view";
import {Graphics, Icon, FilledIcon, PolygonIcon, EnclosedIcon} from "@swim/graphics";
import {Controller, ControllerRef} from "@swim/controller";
import {TextCellView, IconCellView, DisclosureCellView, LeafView, RowView} from "@swim/table";
import {
  Status,
  StatusVector,
  StatusTrait,
  IndicatorTrait,
  IndicatorGroup,
  IndicatedTrait,
  EntityTrait,
  EntityGroup,
} from "@swim/domain";
import {CatalogIndicatorCell} from "./CatalogIndicatorCell";
import {CatalogEntityTree} from "./"; // forward import

/** @public */
export class CatalogEntityLimb extends Controller {
  protected startConsumingSubentities(): void {
    const entityGroup = this.subentities.model;
    if (entityGroup !== null) {
      entityGroup.consume(this);
    }
  }

  protected stopConsumingSubentities(): void {
    const entityGroup = this.subentities.model;
    if (entityGroup !== null) {
      entityGroup.unconsume(this);
    }
  }

  protected startConsumingIndicators(): void {
    const indicatorGroup = this.indicators.model;
    if (indicatorGroup !== null) {
      indicatorGroup.consume(this);
    }
  }

  protected stopConsumingIndicators(): void {
    const indicatorGroup = this.indicators.model;
    if (indicatorGroup !== null) {
      indicatorGroup.unconsume(this);
    }
  }

  protected onMountLimb(rowView: RowView): void {
    if (rowView.disclosure.expanded) {
      this.startConsumingSubentities();
      const entityTree = this.subtree.controller;
      if (entityTree !== null) {
        entityTree.tree.insertView(rowView);
      }
    }
  }

  protected onUnmountLimb(rowView: RowView): void {
    this.stopConsumingSubentities();
    const entityTree = this.subtree.controller;
    if (entityTree !== null) {
      entityTree.tree.removeView();
    }
  }

  protected onExpandLimb(rowView: RowView): void {
    if (rowView.mounted) {
      this.startConsumingSubentities();
      const entityTree = this.subtree.controller;
      if (entityTree !== null) {
        entityTree.tree.insertView(rowView);
      }
    }
  }

  protected onCollapseLimb(rowView: RowView): void {
    this.stopConsumingSubentities();
    const entityTree = this.subtree.controller;
    if (entityTree !== null) {
      entityTree.tree.removeView();
    }
  }

  @ViewRef<CatalogEntityLimb, RowView>({
    type: RowView,
    observes: true,
    initView(rowView: RowView): void {
      this.owner.leaf.insertView(rowView);
      rowView.head.insertView();
      rowView.foot.insertView();

      const entityGroup = this.owner.subentities.model;
      if (entityGroup !== null) {
        const entityTree = this.owner.subtree.insertController();
        if (entityTree !== null) {
          if (rowView.disclosure.expanded) {
            entityTree.tree.insertView(rowView);
          }
          entityTree.entities.setModel(entityGroup);
        }
      }
    },
    didAttachView(rowView): void {
      if (rowView.mounted) {
        this.owner.onMountLimb(rowView);
      }
    },
    viewDidMount(rowView: RowView): void {
      this.owner.onMountLimb(rowView);
    },
    viewWillUnmount(rowView: RowView): void {
      this.owner.onUnmountLimb(rowView);
    },
    viewWillExpand(rowView: RowView): void {
      this.owner.onExpandLimb(rowView);
    },
    viewDidCollapse(rowView: RowView): void {
      this.owner.onCollapseLimb(rowView);
    },
  })
  readonly row!: ViewRef<this, RowView>;

  @ViewRef<CatalogEntityLimb, LeafView>({
    key: true,
    type: LeafView,
    observes: true,
    initView(treeLeaf: LeafView): void {
      this.owner.iconCell.insertView(treeLeaf);
      this.owner.titleCell.insertView(treeLeaf);
      let child = this.owner.firstChild;
      while (child !== null) {
        if (child instanceof CatalogIndicatorCell) {
          this.owner.onInsertIndicatorCell(child);
        }
        child = child.nextSibling;
      }
      this.owner.disclosureCell.insertView(treeLeaf);
      const entityGroup = this.owner.subentities.model;
      if (entityGroup === null) {
        const disclosureCell = this.owner.disclosureCell.view;
        if (disclosureCell !== null) {
          disclosureCell.display.setState("none");
        }
      }

      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        if (selectableTrait.selected) {
          treeLeaf.highlight.focus();
        } else {
          treeLeaf.highlight.unfocus();
        }
      }

      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        this.owner.applyStatus(statusTrait.statusVector);
      }
    },
    didAttachView(treeLeaf: LeafView): void {
      if (treeLeaf.mounted && !treeLeaf.culled) {
        this.owner.startConsumingIndicators();
      }
    },
    viewDidMount(treeLeaf: LeafView): void {
      const rowView = this.owner.row.view;
      if (rowView !== null && rowView.visibility.value !== "hidden" && !rowView.culled) {
        this.owner.startConsumingIndicators();
      }
    },
    viewWillUnmount(treeLeaf: LeafView): void {
      this.owner.stopConsumingIndicators();
    },
    viewDidCull(treeLeaf: LeafView): void {
      this.owner.stopConsumingIndicators();
    },
    viewWillUncull(treeLeaf: LeafView): void {
      this.owner.startConsumingIndicators();
    },
    viewDidPress(input: PositionGestureInput, event: Event | null): void {
      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        if (!selectableTrait.selected) {
          selectableTrait.select({multi: input.shiftKey});
        } else if (input.shiftKey) {
          selectableTrait.unselect();
        } else {
          selectableTrait.unselectAll();
        }
      }
    },
    viewDidLongPress(input: PositionGestureInput): void {
      input.preventDefault();
      const selectableTrait = this.owner.selectable.trait;
      if (selectableTrait !== null) {
        if (!selectableTrait.selected) {
          selectableTrait.select({multi: true});
        } else {
          selectableTrait.unselect();
        }
      }
    },
  })
  readonly leaf!: ViewRef<this, LeafView>;

  @ViewRef<CatalogEntityLimb, IconCellView>({
    key: "icon",
    type: IconCellView,
    initView(iconCell: IconCellView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        let icon = entityTrait.icon.value;
        if (icon instanceof Icon) {
          if (icon instanceof FilledIcon) {
            icon = icon.withFillLook(Look.accentColor);
          }
        } else {
          icon = null;
        }
        const cellIcon = iconCell.graphics.state;
        if (cellIcon instanceof EnclosedIcon) {
          iconCell.graphics.setState(cellIcon.withInner(icon as Icon | null));
        }
      }
    },
    createView(): IconCellView {
      const icon = EnclosedIcon.embossed(PolygonIcon.create(6), null)
                               .withInnerScale(Math.sqrt(2) / 2);
      const iconCell = IconCellView.create()
                                   .iconWidth(40)
                                   .iconHeight(40)
                                   .graphics(icon);
      return iconCell;
    },
  })
  readonly iconCell!: ViewRef<this, IconCellView>;

  @ViewRef<CatalogEntityLimb, TextCellView>({
    key: "title",
    type: TextCellView,
    initView(titleCell: TextCellView): void {
      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        let entityTitle = entityTrait.title.value;
        if (entityTitle === void 0) {
          entityTitle = "";
        }
        titleCell.content(entityTitle);
      }
    },
  })
  readonly titleCell!: ViewRef<this, TextCellView>;

  protected onInsertIndicatorCell(indicatorCell: CatalogIndicatorCell): void {
    const treeLeaf = this.leaf.view;
    if (treeLeaf !== null) {
      const treeCell = indicatorCell.cell.createView();
      if (treeCell !== null) {
        const targetLens = indicatorCell.nextSibling;
        const targetView = targetLens instanceof CatalogIndicatorCell ? targetLens.cell.view : null;
        const id = indicatorCell.indicator.trait!.indicatorType.key;
        treeLeaf.insertChild(treeCell, targetView, id);
        indicatorCell.cell.setView(treeCell);
      }
    }
  }

  protected onRemoveIndicatorCell(indicatorCell: CatalogIndicatorCell): void {
    const treeCell = indicatorCell.cell.view;
    if (treeCell !== null) {
      treeCell.remove();
    }
  }

  static IndicatorFastener = ControllerRef.define<CatalogEntityLimb, CatalogIndicatorCell>("IndicatorFastener", {
    type: CatalogIndicatorCell,
    didAttachController(indicatorCell: CatalogIndicatorCell): void {
      this.owner.onInsertIndicatorCell(indicatorCell);
    },
    willDetachController(indicatorCell: CatalogIndicatorCell): void {
      this.owner.onRemoveIndicatorCell(indicatorCell);
    },
  });

  @ViewRef<CatalogEntityLimb, DisclosureCellView>({
    key: "disclose",
    type: DisclosureCellView,
  })
  readonly disclosureCell!: ViewRef<this, DisclosureCellView>;

  @ControllerRef<CatalogEntityLimb, CatalogEntityTree>({
    // avoid cyclic static reference to type: CatalogEntityTree
    createController(): CatalogEntityTree {
      return new CatalogEntityTree();
    },
  })
  readonly subtree!: ControllerRef<this, CatalogEntityTree>;

  @TraitRef<CatalogEntityLimb, EntityTrait>({
    type: EntityTrait,
    observes: true,
    initTrait(entityTrait: EntityTrait): void {
      const titleCell = this.owner.titleCell.view;
      if (titleCell !== null) {
        let entityTitle = entityTrait.title.value;
        if (entityTitle === void 0) {
          entityTitle = "";
        }
        titleCell.content(entityTitle);
      }

      this.owner.selectable.setTrait(entityTrait.getTrait(SelectableTrait));
      this.owner.status.setTrait(entityTrait.getTrait(StatusTrait));
      this.owner.subentities.setModel(entityTrait.subentities.model);
      const indicatedTrait = entityTrait.getTrait(IndicatedTrait);
      if (indicatedTrait !== null) {
        this.owner.indicators.setModel(indicatedTrait.indicators.model);
      }
    },
    entityDidSetTitle(title: string | undefined): void {
      const titleCell = this.owner.titleCell.view;
      if (titleCell !== null) {
        titleCell.content(title !== void 0 ? title : "");
      }
    },
    entityDidSetIcon(icon: Graphics | null): void {
      const iconCell = this.owner.iconCell.view;
      if (iconCell !== null) {
        if (icon instanceof Icon) {
          if (icon instanceof FilledIcon) {
            icon = icon.withFillLook(Look.accentColor);
          }
        } else {
          icon = null;
        }
        const cellIcon = iconCell.graphics.state;
        if (cellIcon instanceof EnclosedIcon) {
          iconCell.graphics.setState(cellIcon.withInner(icon as Icon | null));
        }
      }
    },
    traitDidInsertChild(child: Model, targetModel: Model | null): void {
      if (child.key === "subentities" && child instanceof EntityGroup) {
        this.owner.subentities.setModel(child);
      }
    },
    traitDidRemoveChild(child: Model): void {
      if (child.key === "subentities" && child instanceof EntityGroup) {
        this.owner.subentities.removeModel();
      }
    },
    traitDidInsertTrait(memberTrait: Trait, targetTrait: Trait | null): void {
      if (memberTrait instanceof SelectableTrait) {
        this.owner.selectable.setTrait(memberTrait);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait>;

  @TraitRef<CatalogEntityLimb, SelectableTrait>({
    type: SelectableTrait,
    observes: true,
    initTrait(selectableTrait: SelectableTrait): void {
      const treeLeaf = this.owner.leaf.view;
      if (treeLeaf !== null) {
        if (selectableTrait.selected) {
          treeLeaf.highlight.focus();
        } else {
          treeLeaf.highlight.unfocus();
        }
      }
    },
    traitDidSelect(options: SelectionOptions | null): void {
      const treeLeaf = this.owner.leaf.view;
      if (treeLeaf !== null) {
        treeLeaf.highlight.focus();
      }
    },
    traitWillUnselect(): void {
      const treeLeaf = this.owner.leaf.view;
      if (treeLeaf !== null) {
        treeLeaf.highlight.unfocus();
      }
    },
  })
  readonly selectable!: TraitRef<this, SelectableTrait>;

  @TraitRef<CatalogEntityLimb, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      this.owner.applyStatus(statusTrait.statusVector);
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      this.owner.applyStatus(newStatusVector);
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;

  protected applyStatus(statusVector: StatusVector): void {
    const treeLeaf = this.leaf.view;
    if (treeLeaf !== null) {
      const alert = statusVector.get(Status.alert) || 0;
      const warning = statusVector.get(Status.warning) || 0;
      const inactive = statusVector.get(Status.inactive) || 0;
      if (alert !== 0 && warning !== 0) {
        treeLeaf.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
      } else if (alert !== 0) {
        treeLeaf.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
      } else if (warning !== 0) {
        treeLeaf.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
      } else {
        treeLeaf.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
      }
      if (inactive !== 0) {
        treeLeaf.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
      } else {
        treeLeaf.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
      }
    }
  }

  @ModelRef<CatalogEntityLimb, EntityGroup>({
    type: EntityGroup,
    didAttachModel(entityGroup: EntityGroup): void {
      const disclosureCell = this.owner.disclosureCell.view;
      if (disclosureCell !== null) {
        disclosureCell.display.setAffinity(Affinity.Intrinsic);
        const treeLeaf = this.owner.leaf.view;
        if (treeLeaf !== null) {
          treeLeaf.requireUpdate(View.NeedsResize | View.NeedsLayout);
        }
      }

      const rowView = this.owner.row.view;
      if (rowView !== null) {
        const entityTree = this.owner.subtree.insertController();
        if (entityTree !== null) {
          if (rowView.disclosure.expanded) {
            entityTree.tree.insertView(rowView);
          }
          entityTree.entities.setModel(entityGroup);
        }
      }
    },
    willDetachModel(entityGroup: EntityGroup): void {
      const disclosureCell = this.owner.disclosureCell.view;
      if (disclosureCell !== null) {
        disclosureCell.display.setState("none");
      }
    },
  })
  readonly subentities!: ModelRef<this, EntityGroup>;

  protected onInsertIndicator(childIndicator: IndicatorTrait, targetIndicator: IndicatorTrait | null): void {
    const key = childIndicator.indicatorType.key;
    let controllerRef = this.getFastener(key, ControllerRef) as ControllerRef<this, CatalogIndicatorCell> | null;
    if (controllerRef === null) {
      controllerRef = CatalogEntityLimb.IndicatorFastener.create(this) as ControllerRef<this, CatalogIndicatorCell>;
      Object.defineProperty(controllerRef, "key", {
        value: key,
        enumerable: true,
        configurable: true,
      });
      this.setFastener(key, controllerRef);
      const indicatorCell = new CatalogIndicatorCell();
      indicatorCell.indicator.setTrait(childIndicator);
      controllerRef.setController(indicatorCell);
      const targetController = targetIndicator !== null ? this.getChild(targetIndicator.indicatorType.key) : null;
      this.insertChild(indicatorCell, targetController, key);
    }
  }

  protected onRemoveIndicator(childIndicator: IndicatorTrait): void {
    const key = childIndicator.indicatorType.key;
    const controllerRef = this.getFastener(key, ControllerRef) as ControllerRef<this, CatalogIndicatorCell> | null;
    if (controllerRef !== null) {
      controllerRef.deleteController();
      this.setFastener(key, null);
    }
  }

  @ModelRef<CatalogEntityLimb, IndicatorGroup>({
    type: IndicatorGroup,
    observes: true,
    initModel(indicatorGroup: IndicatorGroup): void {
      let child = indicatorGroup.firstChild;
      while (child !== null) {
        const indicatorTrait = child.getTrait(IndicatorTrait);
        if (indicatorTrait !== null) {
          this.owner.onInsertIndicator(indicatorTrait, null);
        }
        child = child.nextSibling;
      }

      const treeLeaf = this.owner.leaf.view;
      if (treeLeaf !== null && treeLeaf.mounted && !treeLeaf.culled) {
        this.owner.startConsumingIndicators();
      }
    },
    modelDidInsertChild(child: Model, targetModel: Model | null): void {
      const childIndicator = child.getTrait(IndicatorTrait);
      if (childIndicator !== null) {
        const targetIndicator = targetModel !== null ? targetModel.getTrait(IndicatorTrait) : null;
        this.owner.onInsertIndicator(childIndicator, targetIndicator);
      }
    },
    modelWillRemoveChild(child: Model): void {
      const childIndicator = child.getTrait(IndicatorTrait);
      if (childIndicator !== null) {
        this.owner.onRemoveIndicator(childIndicator);
      }
    },
  })
  readonly indicators!: ModelRef<this, IndicatorGroup>;
}
