// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class, Initable} from "@swim/util";
import {Affinity, Animator} from "@swim/component";
import {AnyLength, Length} from "@swim/math";
import {AnyExpansion, Expansion} from "@swim/style";
import {Look, ThemeAnimator, ExpansionThemeAnimator} from "@swim/theme";
import {PositionGestureInput, ViewContextType, View, ViewRef} from "@swim/view";
import {AnyHtmlView, HtmlViewInit, HtmlView} from "@swim/dom";
import {Graphics, HtmlIconView} from "@swim/graphics";
import {ButtonMembrane} from "@swim/button";
import type {MagnifierHandleObserver} from "./MagnifierHandleObserver";

/** @public */
export class MagnifierHandle extends ButtonMembrane {
  constructor(node: HTMLElement) {
    super(node);
    this.onClick = this.onClick.bind(this);
    this.initHandle();
  }

  protected initHandle(): void {
    this.addClass("magnifier-handle");
    this.position.setState("relative", Affinity.Intrinsic);
    this.flexShrink.setState(0, Affinity.Intrinsic);
    this.marginTop.setState(8, Affinity.Intrinsic);
    this.marginRight.setState(8, Affinity.Intrinsic);
    this.marginBottom.setState(0, Affinity.Intrinsic);
    this.marginLeft.setState(8, Affinity.Intrinsic);
    this.overflowX.setState("hidden", Affinity.Intrinsic);
    this.overflowY.setState("hidden", Affinity.Intrinsic);
    this.backgroundColor.setLook(Look.accentColor, Affinity.Intrinsic);
    this.pointerEvents.setState("auto", Affinity.Intrinsic);
    this.userSelect.setState("none", Affinity.Intrinsic);
  }

  override readonly observerType?: Class<MagnifierHandleObserver>;

  @Animator({type: Length, inherits: true})
  readonly collapsedWidth!: Animator<this, Length | undefined, AnyLength | undefined>;

  @ThemeAnimator<MagnifierHandle, Expansion, AnyExpansion>({
    type: Expansion,
    inherits: true,
    value: Expansion.expanded(),
    didSetValue(newStretch: Expansion, oldStretch: Expansion): void {
      const labelView = this.owner.label.view;
      if (labelView !== null) {
        labelView.display.setState(newStretch.collapsed ? "none" : "block", Affinity.Intrinsic);
        labelView.opacity.setState(newStretch.phase, Affinity.Intrinsic);
      }
      const accessoryView = this.owner.accessory.view;
      if (accessoryView !== null) {
        accessoryView.display.setState(newStretch.collapsed ? "none" : "block", Affinity.Intrinsic);
        accessoryView.opacity.setState(newStretch.phase, Affinity.Intrinsic);
      }
    },
  })
  readonly stretch!: ExpansionThemeAnimator<this, Expansion, AnyExpansion>;

  @ViewRef<MagnifierHandle, HtmlView & Initable<HtmlViewInit | Graphics>>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(iconView: HtmlView): void {
      iconView.position.setState("absolute", Affinity.Intrinsic);
      iconView.top.setState(0, Affinity.Intrinsic);
      iconView.right.setState(0, Affinity.Intrinsic);
      iconView.width.setState(this.owner.height.state, Affinity.Intrinsic);
      iconView.height.setState(this.owner.height.state, Affinity.Intrinsic);
      if (iconView instanceof HtmlIconView) {
        iconView.iconWidth.setState(24, Affinity.Intrinsic);
        iconView.iconHeight.setState(24, Affinity.Intrinsic);
        iconView.iconColor.setLook(Look.backgroundColor, Affinity.Intrinsic);
      }
    },
    createView(): HtmlView {
      return HtmlIconView.create();
    },
    insertChild(parent: View, child: HtmlView, target: View | null, key: string | undefined): void {
      parent.appendChild(child, key);
    },
    fromAny(value: AnyHtmlView | Graphics): HtmlView {
      if (value instanceof HtmlView) {
        return value;
      } else {
        const iconView = this.createView() as HtmlIconView;
        iconView.graphics.setState(value as Graphics, Affinity.Intrinsic);
        return iconView;
      }
    },
  })
  readonly icon!: ViewRef<this, HtmlView & Initable<HtmlViewInit | Graphics>>;

  @ViewRef<MagnifierHandle, HtmlView & Initable<HtmlViewInit | string | undefined>>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(labelView: HtmlView): void {
      labelView.position.setState("absolute", Affinity.Intrinsic);
      labelView.top.setState(0, Affinity.Intrinsic);
      labelView.left.setState(this.owner.height.state, Affinity.Intrinsic);
      labelView.right.setState(this.owner.height.state, Affinity.Intrinsic);
      labelView.textAlign.setState("center", Affinity.Intrinsic);
      labelView.color.setLook(Look.backgroundColor, Affinity.Intrinsic);
    },
    createView(): HtmlView {
      const labelView = HtmlView.fromTag("span");
      labelView.display.setState("block", Affinity.Intrinsic);
      labelView.fontFamily.setState("system-ui, 'Open Sans', sans-serif", Affinity.Intrinsic);
      labelView.fontSize.setState(17, Affinity.Intrinsic);
      labelView.whiteSpace.setState("nowrap", Affinity.Intrinsic);
      labelView.textOverflow.setState("ellipsis", Affinity.Intrinsic);
      labelView.overflowX.setState("hidden", Affinity.Intrinsic);
      labelView.overflowY.setState("hidden", Affinity.Intrinsic);
      return labelView;
    },
    insertChild(parent: View, child: HtmlView, target: View | null, key: string | undefined): void {
      target = this.owner.icon.view;
      parent.insertChild(child, target, key);
    },
    fromAny(value: AnyHtmlView | string | undefined): HtmlView {
      if (value === void 0 || typeof value === "string") {
        const labelView = this.createView();
        labelView.text(value);
        return labelView;
      } else {
        return HtmlView.fromAny(value);
      }
    },
  })
  readonly label!: ViewRef<this, HtmlView & Initable<HtmlViewInit | string | undefined>>;

  @ViewRef<MagnifierHandle, HtmlView & Initable<HtmlViewInit | Graphics>>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(accessoryView: HtmlView): void {
      accessoryView.position.setState("absolute", Affinity.Intrinsic);
      accessoryView.top.setState(0, Affinity.Intrinsic);
      accessoryView.left.setState(0, Affinity.Intrinsic);
      accessoryView.width.setState(this.owner.height.state, Affinity.Intrinsic);
      accessoryView.height.setState(this.owner.height.state, Affinity.Intrinsic);
      if (accessoryView instanceof HtmlIconView) {
        accessoryView.iconWidth.setState(24, Affinity.Intrinsic);
        accessoryView.iconHeight.setState(24, Affinity.Intrinsic);
        accessoryView.iconColor.setLook(Look.backgroundColor, Affinity.Intrinsic);
      }
    },
    createView(): HtmlView {
      return HtmlIconView.create();
    },
    insertChild(parent: View, child: HtmlView, target: View | null, key: string | undefined): void {
      target = this.owner.label.view;
      if (target === null) {
        target = this.owner.icon.view;
      }
      parent.insertChild(child, target, key);
    },
    fromAny(value: AnyHtmlView | Graphics): HtmlView {
      if (value instanceof HtmlView) {
        return value;
      } else {
        const accessoryView = this.createView() as HtmlIconView;
        accessoryView.graphics.setState(value as Graphics, Affinity.Intrinsic);
        return accessoryView;
      }
    },
  })
  readonly accessory!: ViewRef<this, HtmlView & Initable<HtmlViewInit | Graphics>>;

  protected override onMount(): void {
    super.onMount();
    this.on("click", this.onClick);
  }

  protected override onUnmount(): void {
    this.off("click", this.onClick);
    super.onUnmount();
  }

  protected override onAnimate(viewContext: ViewContextType<this>): void {
    super.onAnimate(viewContext);
    this.lineHeight.setState(this.height.state, Affinity.Intrinsic);
  }

  protected override onLayout(viewContext: ViewContextType<this>): void {
    super.onLayout(viewContext);

    if (viewContext.viewportIdiom === "mobile") {
      this.height.setState(48, Affinity.Intrinsic);
    } else {
      this.height.setState(36, Affinity.Intrinsic);
    }
    this.lineHeight.setState(this.height.state, Affinity.Intrinsic);

    const iconView = this.icon.view;
    if (iconView !== null) {
      iconView.width.setState(this.height.state, Affinity.Intrinsic);
      iconView.height.setState(this.height.state, Affinity.Intrinsic);
    }

    const labelView = this.label.view;
    if (labelView !== null) {
      labelView.left.setState(this.height.state, Affinity.Intrinsic);
      labelView.right.setState(this.height.state, Affinity.Intrinsic);
    }

    const accessoryView = this.accessory.view;
    if (accessoryView !== null) {
      accessoryView.width.setState(this.height.state, Affinity.Intrinsic);
      accessoryView.height.setState(this.height.state, Affinity.Intrinsic);
    }
  }

  protected override glow(input: PositionGestureInput): void {
    if (this.stretch.collapsed) {
      super.glow(input);
    }
  }

  protected onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.callObservers("viewDidPressMagnifierHandle", this);
  }
}
