// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {AnyValue, Value} from "@swim/structure";
import type {Uri} from "@swim/uri";
import {MapDownlinkFastener} from "@swim/client";
import type {Model} from "@swim/model";
import {NodeGroup} from "./NodeGroup";

/** @public */
export class DownlinkNodeGroup extends NodeGroup {
  constructor(metaHostUri?: Uri) {
    super(metaHostUri);
  }

  protected getNodePath(key: Value, value: Value): string {
    return key.stringValue("");
  }

  protected updateNodeModel(nodeModel: Model, value: Value): void {
    // hook
  }

  protected didUpdateNode(key: Value, value: Value): void {
    const nodePath = this.getNodePath(key, value);
    const nodeModel = this.getOrCreateNodeModel(nodePath);
    this.updateNodeModel(nodeModel, value);
  }

  protected didRemoveNode(key: Value, value: Value): void {
    const nodePath = this.getNodePath(key, value);
    this.removeNodeModel(nodePath);
  }

  @MapDownlinkFastener<DownlinkNodeGroup, Value, Value, AnyValue, AnyValue>({
    consumed: true,
    didUpdate(key: Value, value: Value): void {
      if (this.owner.consuming) {
        this.owner.didUpdateNode(key, value);
      }
    },
    didRemove(key: Value, value: Value): void {
      if (this.owner.consuming) {
        this.owner.didRemoveNode(key, value);
      }
    },
  })
  readonly downlink!: MapDownlinkFastener<this, Value, Value, AnyValue, AnyValue>;
}
