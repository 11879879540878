// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Initable} from "@swim/util";
import {Provider} from "@swim/component";
import {ModelRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {
  HistoryState,
  HistoryService,
  HistoryProvider,
  AnyController,
  ControllerInit,
  Controller,
  ControllerRef,
  ControllerSet,
} from "@swim/controller";
import {TableView} from "@swim/table";
import {DrawerView} from "@swim/window";
import {DomainGroup} from "@swim/domain";
import {PrismPlugin, PrismService, PrismProvider, MirrorPlugin, SurfaceView} from "@swim/prism";
import {ReflectionController} from "./ReflectionController";

/** @public */
export interface ReflectorControllerReflectionsExt {
  getPluginController(pluginId: string): ReflectionController | null;
  createController(plugin?: MirrorPlugin): ReflectionController;
}

/** @public */
export class ReflectorController extends Controller {
  @ControllerRef<ReflectorController, ReflectionController>({
    type: ReflectionController,
    didAttachController(reflectionController: ReflectionController): void {
      const rowView = reflectionController.row.view;
      if (rowView !== null) {
        const leafView = rowView.leaf.view;
        if (leafView !== null) {
          leafView.highlight.focus(false);
        }
      }
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        reflectionController.surface.setView(surfaceView);
      }
      const toolbarView = this.owner.toolbar.view;
      if (toolbarView !== null) {
        reflectionController.toolbar.setView(toolbarView);
      }
      const drawerView = this.owner.drawer.view;
      if (drawerView !== null) {
        reflectionController.drawer.setView(drawerView);
      }
    },
    willDetachController(reflectionController: ReflectionController): void {
      reflectionController.drawer.setView(null);
      reflectionController.toolbar.setView(null);
      reflectionController.surface.setView(null);
      const rowView = reflectionController.row.view;
      if (rowView !== null) {
        const leafView = rowView.leaf.view;
        if (leafView !== null) {
          leafView.highlight.unfocus(true);
        }
      }
    },
  })
  readonly active!: ControllerRef<this, ReflectionController>;

  @ViewRef<ReflectorController, SurfaceView>({
    type: SurfaceView,
    initView(surfaceView: SurfaceView): void {
      const activeController = this.owner.active.controller;
      if (activeController !== null) {
        activeController.surface.setView(surfaceView);
      }
    },
  })
  readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<ReflectorController, HtmlView>({
    type: HtmlView,
    initView(toolbarView: HtmlView): void {
      const activeController = this.owner.active.controller;
      if (activeController !== null) {
        activeController.toolbar.setView(toolbarView);
      }
    },
  })
  readonly toolbar!: ViewRef<this, HtmlView>;

  @ViewRef<ReflectorController, DrawerView>({
    type: DrawerView,
    initView(drawerView: DrawerView): void {
      const activeController = this.owner.active.controller;
      if (activeController !== null) {
        activeController.drawer.setView(drawerView);
      }
    },
  })
  readonly drawer!: ViewRef<this, DrawerView>;

  protected insertPlugin(plugin: MirrorPlugin): void {
    let reflectionController = this.reflections.getPluginController(plugin.id);
    if (reflectionController === null) {
      reflectionController = this.reflections.addController(plugin);
      const fragment = this.historyProvider.historyState.fragment;
      if (this.active.controller === null && (fragment === void 0 || fragment === plugin.id)) {
        this.active.setController(reflectionController);
        if (fragment === void 0) {
          this.historyProvider.replaceHistory({fragment: reflectionController.plugin.id});
        }
      }
    }
  }

  protected removePlugin(plugin: MirrorPlugin): void {
    const reflectionController = this.reflections.getPluginController(plugin.id);
    if (reflectionController !== null) {
      this.reflections.deleteController(reflectionController);
    }
  }

  @ViewRef<ReflectorController, TableView>({
    type: TableView,
    initView(listView: TableView): void {
      const plugins = this.owner.prismProvider.plugins;
      for (let i = 0, n = plugins.length; i < n; i += 1) {
        const plugin = plugins[i];
        if (plugin instanceof MirrorPlugin) {
          this.owner.insertPlugin(plugin);
        }
      }
    },
  })
  readonly mirrorList!: ViewRef<this, TableView>;

  @ControllerSet<ReflectorController, ReflectionController & Initable<ControllerInit | MirrorPlugin>, ReflectorControllerReflectionsExt>({
    implements: true,
    type: ReflectionController,
    binds: true,
    observes: true,
    didAttachController(reflectionController: ReflectionController): void {
      const listView = this.owner.mirrorList.view;
      if (listView !== null) {
        reflectionController.row.insertView(listView);
      }
      const domainGroup = this.owner.domains.model;
      if (domainGroup !== null) {
        reflectionController.domains.setModel(domainGroup);
      }
    },
    willDetachController(reflectionController: ReflectionController): void {
      if (reflectionController === this.owner.active.controller) {
        this.owner.active.setController(null);
      }
      reflectionController.row.removeView();
    },
    controllerDidActivateReflection(reflectionController: ReflectionController): void {
      this.owner.historyProvider.pushHistory({fragment: reflectionController.plugin.id});
      this.owner.active.setController(reflectionController);
    },
    getPluginController(pluginId: string): ReflectionController | null {
      const controllers = this.controllers;
      for (const controllerId in controllers) {
        const controller = controllers[controllerId]!;
        if (controller.plugin.id === pluginId) {
          return controller;
        }
      }
      return null;
    },
    createController(plugin?: MirrorPlugin): ReflectionController {
      if (plugin !== void 0) {
        return new ReflectionController(plugin);
      } else {
        return ControllerSet.prototype.createController.call(this);
      }
    },
    fromAny(value: AnyController<ReflectionController> | MirrorPlugin): ReflectionController {
      if (value instanceof MirrorPlugin) {
        return this.createController(value)!;
      } else {
        return ReflectionController.fromAny(value);
      }
    },
  })
  readonly reflections!: ControllerSet<this, ReflectionController & Initable<ControllerInit | MirrorPlugin>> & ReflectorControllerReflectionsExt;

  @ModelRef<ReflectorController, DomainGroup>({
    type: DomainGroup,
    initModel(domainGroup: DomainGroup): void {
      const reflectionControllers = this.owner.reflections.controllers;
      for (const controllerId in reflectionControllers) {
        const reflectionController = reflectionControllers[controllerId]!;
        reflectionController.domains.setModel(domainGroup);
      }
    },
  })
  readonly domains!: ModelRef<this, DomainGroup>;

  @Provider<ReflectorController, PrismService>({
    extends: PrismProvider,
    type: PrismService,
    observes: true,
    service: PrismService.global(),
    serviceDidInsertPlugin(plugin: PrismPlugin, index: number): void {
      if (plugin instanceof MirrorPlugin) {
        this.owner.insertPlugin(plugin);
      }
    },
    serviceWillRemovePlugin(plugin: PrismPlugin): void {
      if (plugin instanceof MirrorPlugin) {
        this.owner.removePlugin(plugin);
      }
    },
  })
  readonly prismProvider!: PrismProvider<this>;

  @Provider<ReflectorController, HistoryService>({
    extends: HistoryProvider,
    type: HistoryService,
    observes: true,
    service: HistoryService.global(),
    serviceDidPopHistory(historyState: HistoryState): void {
      this.owner.updateHistoryState(historyState);
    },
  })
  override readonly historyProvider!: HistoryProvider<this>;

  protected override onMount(): void {
    super.onMount();
    this.updateHistoryState(this.historyProvider.historyState);
  }

  protected updateHistoryState(historyState: HistoryState): void {
    const fragment = historyState.fragment;
    if (fragment !== void 0) {
      const reflectionController = this.reflections.getPluginController(fragment);
      if (reflectionController !== null) {
        this.active.setController(reflectionController);
      }
    }
  }
}
