// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import type {Value} from "@swim/structure";
import {Recon} from "@swim/recon";
import {ModelRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {PolygonIcon} from "@swim/graphics";
import {Controller} from "@swim/controller";
import {TextCellView, IconCellView, LeafView, RowView} from "@swim/table";
import {LaneModel} from "./LaneModel";
import type {LaneControllerObserver} from "./LaneControllerObserver";

/** @public */
export class LaneController extends Controller {
  override readonly observerType?: Class<LaneControllerObserver>;

  select(): void {
    const leafView = this.leaf.view;
    if (leafView !== null) {
      leafView.highlight.focus();
    }
  }

  unselect(): void {
    const leafView = this.leaf.view;
    if (leafView !== null) {
      leafView.highlight.unfocus();
    }
  }

  @ViewRef<LaneController, RowView>({
    type: RowView,
    initView(rowView: RowView): void {
      this.owner.leaf.insertView(rowView);
    }
  })
  readonly row!: ViewRef<this, RowView>;

  @ViewRef<LaneController, LeafView>({
    key: true,
    type: LeafView,
    observes: true,
    initView(leafView: LeafView): void {
      this.owner.iconCell.insertView(leafView);
      this.owner.nameCell.insertView(leafView);
      this.owner.kindCell.insertView(leafView);
    },
    viewDidPress(): void {
      this.owner.callObservers("controllerDidActivateLane", this.owner);
    },
  })
  readonly leaf!: ViewRef<this, LeafView>;

  @ViewRef<LaneController, IconCellView>({
    key: "icon",
    type: IconCellView,
    createView(): IconCellView {
      const iconCellView = IconCellView.create()
        .iconWidth(36)
        .graphics(PolygonIcon.create(4, Math.PI / 4));
      return iconCellView;
    },
  })
  readonly iconCell!: ViewRef<this, IconCellView>;

  @ViewRef<LaneController, TextCellView>({
    key: "name",
    type: TextCellView,
    initView(nameCellView: TextCellView): void {
      const laneModel = this.owner.lane.model;
      if (laneModel !== null) {
        nameCellView.content(laneModel.ownLaneUri.toString());
      }
    },
  })
  readonly nameCell!: ViewRef<this, TextCellView>;

  @ViewRef<LaneController, TextCellView>({
    key: "kind",
    type: TextCellView,
    initView(kindCellView: TextCellView): void {
      const laneModel = this.owner.lane.model;
      if (laneModel !== null) {
        kindCellView.content(laneModel.info.get("laneType").stringValue(""));
      }
    },
  })
  readonly kindCell!: ViewRef<this, TextCellView>;

  @ViewRef<LaneController, HtmlView>({
    key: true,
    type: HtmlView,
    didAttachView(detailView: HtmlView | null): void {
      const laneModel = this.owner.lane.model;
      if (laneModel !== null) {
        laneModel.consume(this);
      }
    },
    willDetachView(detailView: HtmlView | null): void {
      const laneModel = this.owner.lane.model;
      if (laneModel !== null) {
        laneModel.unconsume(this);
      }
    },
  })
  readonly detail!: ViewRef<this, HtmlView>;

  @ModelRef<LaneController, LaneModel>({
    type: LaneModel,
    observes: true,
    initModel(laneModel: LaneModel): void {
      const nameCellView = this.owner.nameCell.view;
      if (nameCellView !== null) {
        nameCellView.content(laneModel.ownLaneUri.toString());
      }
      const kindCellView = this.owner.kindCell.view;
      if (kindCellView !== null) {
        kindCellView.content(laneModel.info.get("laneType").stringValue(""));
      }
    },
    onLaneEvent(value: Value, laneModel: LaneModel): void {
      const detailView = this.owner.detail.view;
      if (detailView !== null) {
        detailView.appendChild("p").text(Recon.toString(value));
      }
    },
  })
  readonly lane!: ModelRef<this, LaneModel>;
}
