// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import {Trait} from "@swim/model";
import {Look} from "@swim/theme";
import {ViewContextType, View, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {CanvasView} from "@swim/graphics";
import {TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {
  PlotView,
  PlotTrait,
  BubblePlotTrait,
  LinePlotTrait,
  AreaPlotTrait,
  PlotController,
  GraphControllerPlotExt,
  ChartView,
  ChartTrait,
  ChartController,
} from "@swim/chart";
import type {GadgetController} from "../gadget/GadgetController";
import {ChartGadgetBubblePlotController} from "./ChartGadgetBubblePlotController";
import {ChartGadgetLinePlotController} from "./ChartGadgetLinePlotController";
import {ChartGadgetAreaPlotController} from "./ChartGadgetAreaPlotController";
import type {ChartGadgetControllerObserver} from "./ChartGadgetControllerObserver";

/** @public */
export class ChartGadgetController<X = unknown, Y = unknown> extends ChartController<X, Y> implements GadgetController {
  override readonly observerType?: Class<ChartGadgetControllerObserver<X, Y>>;

  @TraitViewRef<ChartGadgetController<X, Y>, ChartTrait<X, Y>, ChartView<X, Y>>({
    extends: true,
    initView(chartView: ChartView<X, Y>): void {
      ChartController.chart.prototype.initView.call(this, chartView as ChartView);
      chartView.gutterLeft.setState(0, Affinity.Intrinsic);
      chartView.gutterRight.setState(0, Affinity.Intrinsic);
      chartView.borderWidth.setState(0, Affinity.Intrinsic);
      chartView.borderSerif.setState(0, Affinity.Intrinsic);
      chartView.tickMarkLength.setState(0, Affinity.Intrinsic);
      chartView.domainTracking(true);
      chartView.font.setLook(Look.font, Affinity.Intrinsic);
    },
  })
  override readonly chart!: TraitViewRef<this, ChartTrait<X, Y>, ChartView<X, Y>>;
  static override readonly chart: MemberFastenerClass<ChartGadgetController, "chart">;

  @TraitViewControllerSet<ChartGadgetController<X, Y>, PlotTrait<X, Y>, PlotView<X, Y>, PlotController<X, Y>>({
    extends: true,
    createController(plotTrait?: PlotTrait<X, Y>): PlotController<X, Y> {
      let plotController: PlotController<X, Y>;
      if (plotTrait instanceof BubblePlotTrait) {
        plotController = new ChartGadgetBubblePlotController<X, Y>();
      } else if (plotTrait instanceof LinePlotTrait) {
        plotController = new ChartGadgetLinePlotController<X, Y>();
      } else if (plotTrait instanceof AreaPlotTrait) {
        plotController = new ChartGadgetAreaPlotController<X, Y>();
      } else {
        plotController = ChartController.plots.prototype.createController.call(this, plotTrait as PlotTrait) as PlotController<X, Y>;
      }
      return plotController;
    },
  })
  override readonly plots!: TraitViewControllerSet<this, PlotTrait<X, Y>, PlotView<X, Y>, PlotController<X, Y>> & GraphControllerPlotExt<X, Y>;
  static override readonly plots: MemberFastenerClass<ChartGadgetController, "plots">;

  @ViewRef<ChartGadgetController<X, Y>, CanvasView>({
    type: CanvasView,
    didAttachView(canvasView: CanvasView): void {
      this.owner.chart.insertView(canvasView);
    },
    willDetachView(canvasView: CanvasView): void {
      this.owner.chart.removeView();
    },
  })
  readonly canvas!: ViewRef<this, CanvasView>;
  static readonly canvas: MemberFastenerClass<ChartGadgetController, "canvas">;

  @TraitViewRef<ChartGadgetController<X, Y>, Trait, HtmlView>({
    traitType: Trait,
    willAttachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerWillAttachGadgetTrait", gadgetTrait, this.owner);
    },
    didAttachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof ChartTrait) {
        this.owner.chart.setTrait(gadgetTrait);
      }
    },
    willDetachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof ChartTrait) {
        this.owner.chart.setTrait(null);
      }
    },
    didDetachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerDidDetachGadgetTrait", gadgetTrait, this.owner);
    },
    viewType: HtmlView,
    observesView: true,
    initView(gadgetView: HtmlView): void {
      gadgetView.marginLeft.setState(18, Affinity.Intrinsic);
      gadgetView.marginRight.setState(18, Affinity.Intrinsic);
    },
    willAttachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachGadgetView", gadgetView, this.owner);
    },
    didAttachView(gadgetView: HtmlView): void {
      this.owner.canvas.insertView(gadgetView);
      gadgetView.requireUpdate(View.NeedsResize);
    },
    willDetachView(gadgetView: HtmlView): void {
      this.owner.canvas.removeView();
    },
    didDetachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachGadgetView", gadgetView, this.owner);
    },
    viewWillResize(viewContext: ViewContextType<HtmlView>, gadgetView: HtmlView): void {
      const chartView = this.owner.chart.view;
      if (chartView !== null) {
        let gadgetHeight = 20;
        if (chartView.topAxis.view !== null) {
          gadgetHeight += 30;
          chartView.gutterTop.setState(30, Affinity.Intrinsic);
        } else {
          gadgetHeight += 20;
          chartView.gutterTop.setState(20, Affinity.Intrinsic);
        }
        if (chartView.bottomAxis.view !== null) {
          gadgetHeight += 30;
          chartView.gutterBottom.setState(30, Affinity.Intrinsic);
        } else {
          gadgetHeight += 20;
          chartView.gutterBottom.setState(20, Affinity.Intrinsic);
        }
        gadgetView.height.setState(gadgetHeight, Affinity.Intrinsic);
      }
    },
  })
  readonly gadget!: TraitViewRef<this, Trait, HtmlView>;
  static readonly gadget: MemberFastenerClass<ChartGadgetController, "gadget">;
}
