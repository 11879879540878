// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/** @public */
export class Status {
  constructor(name: string) {
    this.name = name;
  }

  readonly name: string;

  toString(): string {
    return "Status" + "." + this.name;
  }

  static normal: Status =  new Status("normal");
  static inactive: Status = new Status("inactive");
  static warning: Status = new Status("warning");
  static alert: Status = new Status("alert");
}
