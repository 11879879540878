// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {MemberFastenerClass, Property} from "@swim/component";
import type {Uri} from "@swim/uri";
import {
  ModelContextType,
  ModelFlags,
  Model,
  ModelRef,
  TraitContextType,
  TraitModelType,
  Trait,
} from "@swim/model";
import type {Graphics} from "@swim/graphics";
import type {StatusVector} from "../status/StatusVector";
import {StatusFactor} from "../status/StatusFactor";
import {StatusTrait} from "../status/StatusTrait";
import {IndicatorGroup} from "../indicator/IndicatorGroup";
import {IndicatedTrait} from "../indicator/IndicatedTrait";
import type {EntityTraitObserver} from "./EntityTraitObserver";
import {EntityGroup} from "./EntityGroup";
import {DomainTrait} from "../"; // forward import

/** @public */
export class EntityTrait extends Trait {
  constructor(uri: Uri) {
    super();
    this.uri = uri;
  }

  override readonly observerType?: Class<EntityTraitObserver>;

  readonly uri: Uri;

  @Property<EntityTrait, string | undefined>({
    type: String,
    willSetValue(newTitle: string | undefined, oldTitle: string | undefined): void {
      this.owner.callObservers("entityWillSetTitle", newTitle, this.owner);
    },
    didSetValue(newTitle: string | undefined, oldTitle: string | undefined): void {
      this.owner.callObservers("entityDidSetTitle", newTitle, this.owner);
    },
    equalValues(newTitle: string | undefined, oldTitle: string | undefined): boolean {
      return newTitle === oldTitle;
    },
  })
  readonly title!: Property<this, string | undefined>;

  @Property<EntityTrait, Graphics | null>({
    value: null,
    willSetValue(newIcon: Graphics | null, oldIcon: Graphics | null): void {
      this.owner.callObservers("entityWillSetIcon", newIcon, this.owner);
    },
    didSetValue(newIcon: Graphics | null, oldIcon: Graphics | null): void {
      this.owner.callObservers("entityDidSetIcon", newIcon, this.owner);
    },
    equalValues(newIcon: Graphics | null, oldIcon: Graphics | null): boolean {
      return newIcon === oldIcon;
    },
  })
  readonly icon!: Property<this, Graphics | null>;

  /** @internal */
  protected aggregateStatus(statusFactors: {[statusName: string]: StatusFactor | undefined}): void {
    const statusTrait = this.getTrait(StatusTrait);
    if (statusTrait !== null) {
      for (const statusName in statusFactors) {
        const statusFactor = statusFactors[statusName]!;
        if (statusFactor !== void 0) {
          statusTrait.setStatusFactor(statusName, statusFactor);
        } else {
          statusTrait.setStatusFactor(statusName, null);
        }
      }
    }
  }

  /** @internal */
  protected aggregateIndicatorsStatus(indicatorGroup: IndicatorGroup, statusVector: StatusVector,
                                      statusFactors: {[statusName: string]: StatusFactor | undefined}): void {
    if (statusVector.isDefined() && indicatorGroup.consuming) {
      statusFactors["indicators"] = StatusFactor.create("Indicators", statusVector);
    } else {
      statusFactors["indicators"] = void 0;
    }
  }

  /** @internal */
  protected aggregateSubentitiesStatus(entityGroup: EntityGroup, statusVector: StatusVector,
                                       statusFactors: {[statusName: string]: StatusFactor | undefined}): void {
    if (statusVector.isDefined() && entityGroup.consuming) {
      statusFactors["subentities"] = StatusFactor.create("Subentities", statusVector);
    } else {
      statusFactors["subentities"] = void 0;
    }
  }

  protected aggregatedChildModelStatus(child: Model, statusFactors: {[statusName: string]: StatusFactor | undefined}): void {
    const childStatusTrait = child.getTrait(StatusTrait);
    if (childStatusTrait !== null) {
      if (child instanceof IndicatorGroup) {
        const indicated = this.getTrait(IndicatedTrait);
        if (indicated !== null && child === indicated.indicators.model) {
          this.aggregateIndicatorsStatus(child, childStatusTrait.statusVector, statusFactors);
        }
      } else if (child instanceof EntityGroup) {
        this.aggregateSubentitiesStatus(child, childStatusTrait.statusVector, statusFactors);
      }
    }
  }

  /** @protected */
  override analyzeChildren(analyzeFlags: ModelFlags, modelContext: TraitContextType<this>,
                           analyzeChildModel: (this: TraitModelType<this>, child: Model, analyzeFlags: ModelFlags,
                                               modelContext: TraitContextType<this>) => void,
                           analyzeChildren: (this: TraitModelType<this>, analyzeFlags: ModelFlags, modelContext: TraitContextType<this>,
                                             analyzeChildModel: (this: TraitModelType<this>, child: Model, analyzeFlags: ModelFlags,
                                                                 modelContext: TraitContextType<this>) => void) => void): void {
    if ((analyzeFlags & Model.NeedsAggregate) !== 0) {
      this.aggregateChildStatuses(analyzeFlags, modelContext, analyzeChildModel, analyzeChildren);
    } else {
      super.analyzeChildren(analyzeFlags, modelContext, analyzeChildModel, analyzeChildren);
    }
  }

  protected aggregateChildStatuses(analyzeFlags: ModelFlags, modelContext: TraitContextType<this>,
                                   analyzeChildModel: (this: TraitModelType<this>, child: Model, analyzeFlags: ModelFlags,
                                                       modelContext: TraitContextType<this>) => void,
                                   analyzeChildren: (this: TraitModelType<this>, analyzeFlags: ModelFlags, modelContext: TraitContextType<this>,
                                                     analyzeChildModel: (this: TraitModelType<this>, child: Model, analyzeFlags: ModelFlags,
                                                                         modelContext: TraitContextType<this>) => void) => void): void {
    // Accumulate status factors when aggregating child models.
    const statusFactors = {} as {[statusName: string]: StatusFactor | undefined};

    type self = this;
    const self = this;
    function aggregateChildStatus(this: TraitModelType<self>, child: Model, analyzeFlags: ModelFlags,
                                  modelContext: ModelContextType<TraitModelType<self>>): void {
      analyzeChildModel.call(this, child, analyzeFlags, modelContext);
      self.aggregatedChildModelStatus(child, statusFactors);
    }
    analyzeChildren.call(this.model as TraitModelType<this>, analyzeFlags, modelContext, aggregateChildStatus);

    this.aggregateStatus(statusFactors);
  }

  @ModelRef<EntityTrait, EntityGroup>({
    key: true,
    type: EntityGroup,
    binds: true,
    createModel(): EntityGroup {
      const entityGroup = new EntityGroup();
      entityGroup.setTrait("status", new StatusTrait());
      return entityGroup;
    },
  })
  readonly subentities!: ModelRef<this, EntityGroup>;
  static readonly subentities: MemberFastenerClass<EntityTrait, "subentities">;

  protected override onMount(): void {
    super.onMount();
    const domain = this.getSuperTrait(DomainTrait);
    if (domain !== null) {
      domain.injectEntity(this);
    }
  }
}
