// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {AnyValue, Value, Form} from "@swim/structure";
import {AnyUri, Uri, UriPath} from "@swim/uri";
import {MapDownlinkFastener} from "@swim/client";
import {Model} from "@swim/model";
import type {LaneModel} from "./LaneModel";
import {EventLaneModel} from "./EventLaneModel";
import {ListLaneModel} from "./ListLaneModel";
import {MapLaneModel} from "./MapLaneModel";
import {ValueLaneModel} from "./ValueLaneModel";
import type {ManifestModelObserver} from "./ManifestModelObserver";

/** @public */
export class ManifestModel extends Model {
  /** @internal */
  metaNodeUri: Uri;
  /** @internal */
  ownNodeUri: Uri;

  constructor(metaNodeUri: Uri) {
    super();
    this.metaNodeUri = metaNodeUri;
    this.ownNodeUri = Uri.path(UriPath.parse(metaNodeUri.path.foot().head()));
  }

  override readonly observerType?: Class<ManifestModelObserver>;

  protected createLaneModel(nodeUri: Uri, laneUri: Uri, info: Value): LaneModel {
    const laneType = info.get("laneType").stringValue(void 0);
    if (laneType === "list") {
      return new ListLaneModel(nodeUri, laneUri, info);
    } else if (laneType === "map") {
      return new MapLaneModel(nodeUri, laneUri, info);
    } else if (laneType === "value") {
      return new ValueLaneModel(nodeUri, laneUri, info);
    } else {
      return new EventLaneModel(nodeUri, laneUri, info);
    }
  }

  protected didUpdateLane(laneUri: Uri, newInfo: Value, oldInfo: Value): void {
    const laneKey = laneUri.toString();
    let laneModel = this.getChild(laneKey) as LaneModel | null;
    if (laneModel === null) {
      laneModel = this.createLaneModel(this.ownNodeUri, laneUri, newInfo);
      this.appendChild(laneModel, laneKey);
    } else {
      laneModel.setInfo(newInfo);
    }
    this.callObservers("manifestDidUpdateLane", laneUri, newInfo, oldInfo, this);
  }

  protected didRemoveLane(laneUri: Uri, oldInfo: Value): void {
    const laneKey = laneUri.toString();
    this.removeChild(laneKey);
    this.callObservers("manifestDidRemoveLane", laneUri, oldInfo, this);
  }

  @MapDownlinkFastener<ManifestModel, Uri, Value, AnyUri, AnyValue>({
    consumed: true,
    nodeUri(): Uri {
      return this.owner.metaNodeUri;
    },
    laneUri: "lanes",
    keyForm: Uri.form(),
    valueForm: Form.forValue(),
    didUpdate(laneUri: Uri, newInfo: Value, oldInfo: Value): void {
      this.owner.didUpdateLane(laneUri, newInfo, oldInfo);
    },
    didRemove(laneUri: Uri, oldInfo: Value): void {
      this.owner.didRemoveLane(laneUri, oldInfo);
    },
  })
  readonly lanes!: MapDownlinkFastener<this, Uri, Value, AnyUri, AnyValue>;

  protected override onMount(): void {
    super.onMount();
    this.lanes.consume(this);
  }

  protected override onUnmount(): void {
    super.onUnmount();
    this.lanes.unconsume(this);
  }
}
