// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import {Model, Trait, TraitRef} from "@swim/model";
import type {HtmlView} from "@swim/dom";
import {Controller, TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {TableTrait} from "@swim/table";
import {GaugeTrait} from "@swim/gauge";
import {PieTrait} from "@swim/pie";
import {ChartTrait} from "@swim/chart";
import {EntityTrait} from "@swim/domain";
import {
  GadgetController,
  WidgetView,
  WidgetTrait,
  WidgetController,
  WidgetControllerGadgetExt,
  TableGadgetController,
  GaugeGadgetController,
  PieGadgetController,
  ChartGadgetController,
} from "@swim/widget";
import type {RefractionControllerObserver} from "./RefractionControllerObserver";

/** @public */
export class RefractionController extends WidgetController {
  override readonly observerType?: Class<RefractionControllerObserver>;

  @TraitViewRef<RefractionController, WidgetTrait, WidgetView>({
    extends: true,
    observesTrait: true,
    detectGadgetModel(model: Model): Trait | null {
      let gadgetTrait = null;
      if (gadgetTrait === null) {
        gadgetTrait = model.getTrait(TableTrait);
      }
      if (gadgetTrait === null) {
        gadgetTrait = model.getTrait(GaugeTrait);
      }
      if (gadgetTrait === null) {
        gadgetTrait = model.getTrait(PieTrait);
      }
      if (gadgetTrait === null) {
        gadgetTrait = model.getTrait(ChartTrait);
      }
      return gadgetTrait;
    },
    initView(widgetView: WidgetView): void {
      WidgetController.widget.prototype.initView.call(this, widgetView);
      widgetView.marginTop.setState(8, Affinity.Intrinsic);
      widgetView.marginRight.setState(8, Affinity.Intrinsic);
      widgetView.marginBottom.setState(8, Affinity.Intrinsic);
      widgetView.marginLeft.setState(8, Affinity.Intrinsic);
    },
  })
  override readonly widget!: TraitViewRef<this, WidgetTrait, WidgetView>;
  static override readonly widget: MemberFastenerClass<RefractionController, "widget">;

  @TraitViewControllerSet<RefractionController, Trait, HtmlView, GadgetController, WidgetControllerGadgetExt>({
    extends: true,
    implements: true,
    detectController(controller: Controller): GadgetController | null {
      return controller instanceof TableGadgetController
          || controller instanceof GaugeGadgetController
          || controller instanceof PieGadgetController
          || controller instanceof ChartGadgetController
           ? controller : null;
    },
    createController(gadgetTrait?: Trait): GadgetController {
      let gadgetController: GadgetController;
      if (gadgetTrait instanceof TableTrait) {
        gadgetController = new TableGadgetController();
      } else if (gadgetTrait instanceof GaugeTrait) {
        gadgetController = new GaugeGadgetController();
      } else if (gadgetTrait instanceof PieTrait) {
        gadgetController = new PieGadgetController();
      } else if (gadgetTrait instanceof ChartTrait) {
        gadgetController = new ChartGadgetController();
      } else {
        gadgetController = WidgetController.gadgets.prototype.createController.call(this, gadgetTrait);
      }
      return gadgetController;
    },
  })
  override readonly gadgets!: TraitViewControllerSet<this, Trait, HtmlView, GadgetController>;
  static override readonly gadgets: MemberFastenerClass<RefractionController, "gadgets">;

  @TraitRef<RefractionController, EntityTrait>({
    type: EntityTrait,
  })
  readonly entity!: TraitRef<this, EntityTrait>;

  protected override willUnmount(): void {
    super.willUnmount();
    this.widget.removeView();
  }
}
