// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {ConstraintProperty, ConstraintGroup} from "@swim/constraint";
import {View, ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {TableView} from "@swim/table";

/** @public */
export class InventoryView extends HtmlView {
  constructor(node: HTMLElement) {
    super(node);
    this.masterLayout = new ConstraintGroup(this).constrain();
    this.mobileLayout = new ConstraintGroup(this);
    this.desktopLayout = new ConstraintGroup(this);

    this.onMasterPaneScroll = this.onMasterPaneScroll.bind(this);

    this.initInventory();
    this.initMasterLayout(this.masterLayout);
    this.initMobileLayout(this.mobileLayout);
    this.initDesktopLayout(this.desktopLayout);
  }

  protected initInventory(): void {
    this.masterBar.insertView();
    this.masterPane.insertView();
    this.detail.insertView();
  }

  /** @internal */
  readonly masterLayout: ConstraintGroup;

  protected initMasterLayout(layout: ConstraintGroup): void {
    layout.constraint(this.barHeight.constrain(), "eq", InventoryView.BarHeight);
    layout.constraint(this.masterWidth.constrain(), "eq", InventoryView.MasterWidth);
  }

  /** @internal */
  readonly mobileLayout: ConstraintGroup;

  protected initMobileLayout(layout: ConstraintGroup): void {
    // hook
  }

  /** @internal */
  readonly desktopLayout: ConstraintGroup;

  protected initDesktopLayout(layout: ConstraintGroup): void {
    // hook
  }

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly barHeight!: ConstraintProperty<this, number>;

  @ConstraintProperty({type: Number, constrain: true, strength: "strong"})
  readonly masterWidth!: ConstraintProperty<this, number>;

  @ViewRef<InventoryView, HtmlView>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(masterBarView: HtmlView): void {
      masterBarView.position("absolute")
                   .display("flex")
                   .justifyContent("center")
                   .alignItems("center")
                   .top(0)
                   .left(0)
                   .userSelect("none")
                   .text("Lanes");

      this.owner.masterTree.constraint(masterBarView.width.constrain(), "eq", this.owner.masterWidth);
      this.owner.masterTree.constraint(masterBarView.height.constrain(), "eq", this.owner.barHeight);
    },
  })
  readonly masterBar!: ViewRef<this, HtmlView>;

  @ViewRef<InventoryView, HtmlView>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(masterPaneView: HtmlView): void {
      masterPaneView.position("absolute")
                    .bottom(0)
                    .left(0)
                    .overflowY("auto");

      this.owner.masterPane.constraint(masterPaneView.top.constrain(), "eq", this.owner.barHeight);
      this.owner.masterPane.constraint(masterPaneView.width.constrain(), "eq", this.owner.masterWidth);

      this.owner.masterTree.insertView(masterPaneView);

      masterPaneView.on("scroll", this.owner.onMasterPaneScroll);
    },
  })
  readonly masterPane!: ViewRef<this, HtmlView>;

  @ViewRef<InventoryView, TableView>({
    key: true,
    type: TableView,
  })
  readonly masterTree!: ViewRef<this, TableView>;

  @ViewRef<InventoryView, HtmlView>({
    key: true,
    type: HtmlView,
    binds: true,
    initView(detailView: HtmlView): void {
      detailView.position("absolute")
                .top(0)
                .right(0)
                .bottom(0)
                .padding(10)
                .overflowY("auto");

      this.owner.detail.constraint(detailView.left.constrain(), "eq", this.owner.masterWidth);
    },
  })
  readonly detail!: ViewRef<this, HtmlView>;

  protected onMasterPaneScroll(event: Event): void {
    const masterTreeView = this.masterTree.view;
    if (masterTreeView !== null) {
      masterTreeView.requireUpdate(View.NeedsScroll);
    }
  }

  static BarHeight: number = 44;
  static MasterWidth: number = 320;
}
