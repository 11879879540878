// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class, AnyTiming} from "@swim/util";
import {GeoView} from "@swim/map";
import type {Geographic} from "./Geographic";
import {GeographicPoint} from "./GeographicPoint";
import {GeographicLine} from "./GeographicLine";
import {GeographicArea} from "./GeographicArea";
import {GeographicGroup} from "./GeographicGroup";
import type {GeographicViewObserver} from "./GeographicViewObserver";
import {GeographicPointView} from "../"; // forward import
import {GeographicLineView} from "../"; // forward import
import {GeographicAreaView} from "../"; // forward import
import {GeographicGroupView} from "../"; // forward import

/** @public */
export interface GeographicView extends GeoView {
  readonly observerType?: Class<GeographicViewObserver>;

  highlight(timing?: AnyTiming | boolean): void;

  unhighlight(timing?: AnyTiming | boolean): void;

  setState(geographic: Geographic, timing?: AnyTiming | boolean): void;
}

/** @public */
export const GeographicView = (function () {
  const GeographicView = {} as {
    fromGeographic(geographic: Geographic): GeographicView;

    is(object: unknown): object is GeographicView;
  };

  GeographicView.fromGeographic = function (geographic: Geographic): GeographicView {
    if (geographic instanceof GeographicPoint) {
      return GeographicPointView.fromGeographic(geographic);
    } else if (geographic instanceof GeographicLine) {
      return GeographicLineView.fromGeographic(geographic);
    } else if (geographic instanceof GeographicArea) {
      return GeographicAreaView.fromGeographic(geographic);
    } else if (geographic instanceof GeographicGroup) {
      return GeographicGroupView.fromGeographic(geographic);
    } else {
      throw new TypeError("" + geographic);
    }
  };

  GeographicView.is = function (object: unknown): object is GeographicView {
    if (typeof object === "object" && object !== null) {
      const view = object as GeographicView;
      return view instanceof GeographicPoint
          || view instanceof GeographicLine
          || view instanceof GeographicArea
          || view instanceof GeographicGroup
          || view instanceof GeoView
          && "highlight" in view
          && "unhighlight" in view
          && "setState" in view;
    }
    return false;
  };

  return GeographicView;
})();
