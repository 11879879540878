// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Format} from "@swim/codec";
import type {AnyValue, Value} from "@swim/structure";
import type {Uri} from "@swim/uri";
import {ValueDownlinkFastener} from "@swim/client";
import {Model, ModelRef, TraitModelType, Trait, TraitRef} from "@swim/model";
import {
  Status,
  StatusFactor,
  StatusVector,
  StatusTrait,
  IndicatorType,
  ValueIndicatorTrait,
  IndicatorGroup,
} from "@swim/domain";

/** @public */
export class PulseTrait extends Trait {
  constructor(metaNodeUri: Uri) {
    super();
    this.metaNodeUri = metaNodeUri;
    this.fabricIndicators = true;
  }

  /** @protected */
  override didAttachModel(model: TraitModelType<this>): void {
    this.indicators.insertModel();
    super.didAttachModel(model);
  }

  readonly metaNodeUri: Uri;

  /** @internal */
  fabricIndicators: boolean;

  @ModelRef<PulseTrait, IndicatorGroup>({
    key: true,
    type: IndicatorGroup,
    observes: true,
    didAttachModel(indicatorGroup: IndicatorGroup): void {
      if (indicatorGroup.consuming) {
        this.owner.pulse.consume(this);
      }
    },
    willDetachModel(indicatorGroup: IndicatorGroup): void {
      if (indicatorGroup.consuming) {
        this.owner.pulse.unconsume(this);
      }
    },
    modelDidStartConsuming(indicatorGroup: IndicatorGroup): void {
      this.owner.pulse.consume(this);
    },
    modelWillStopConsuming(indicatorGroup: IndicatorGroup): void {
      this.owner.pulse.unconsume(this);
    },
    createModel(): IndicatorGroup {
      const indicatorGroup = new IndicatorGroup();
      indicatorGroup.setTrait("status", new StatusTrait());
      return indicatorGroup;
    },
  })
  readonly indicators!: ModelRef<this, IndicatorGroup>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    formatIndicator(value: number): string {
      return Format.prefix(value);
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.PartCountIndicatorType);
    },
  })
  readonly partCountIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    formatIndicator(value: number): string {
      return Format.prefix(value);
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.HostCountIndicatorType);
    },
  })
  readonly hostCountIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    formatIndicator(value: number): string {
      return Format.prefix(value);
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.NodeCountIndicatorType);
    },
  })
  readonly nodeCountIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    formatIndicator(value: number): string {
      return Format.prefix(value);
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.AgentCountIndicatorType);
    },
  })
  readonly agentCountIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    formatIndicator(value: number): string {
      return Format.prefix(value);
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.LinkCountIndicatorType);
    },
  })
  readonly linkCountIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    indicatorDidSetValue(newMessageRate: number, oldMessageRate: number, messageRateIndicator: ValueIndicatorTrait<number>): void {
      const statusTrait = messageRateIndicator.getTrait(StatusTrait);
      if (statusTrait !== null) {
        const nominalRate = 1000;
        const warningRate = 5000;
        const alertRate = 10000;
        if (newMessageRate < nominalRate) {
          statusTrait.setStatusFactor("message-rate", null);
        } else if (newMessageRate < warningRate) {
          const u = (newMessageRate - nominalRate) / (warningRate - nominalRate);
          statusTrait.setStatusFactor("message-rate", StatusFactor.create("Message Rate", StatusVector.of([Status.warning, 0.5 + 0.5 * u])));
        } else {
          const u = (Math.min(newMessageRate, alertRate) - warningRate) / (alertRate - warningRate);
          statusTrait.setStatusFactor("message-rate", StatusFactor.create("Message Rate", StatusVector.of([Status.alert, 0.5 + 0.5 * u])));
        }
      }
    },
    formatIndicator(value: number): string {
      return Format.prefix(value) + "/s";
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.MessageRateIndicatorType);
    },
  })
  readonly messageRateIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    indicatorDidSetValue(newExecRate: number, oldExecRate: number, execRateIndicator: ValueIndicatorTrait<number>): void {
      const statusTrait = execRateIndicator.getTrait(StatusTrait);
      if (statusTrait !== null) {
        const nominalRate = 0.001;
        const warningRate = 0.01;
        const alertRate = 0.1;
        if (newExecRate < nominalRate) {
          statusTrait.setStatusFactor("exec-rate", null);
        } else if (newExecRate < warningRate) {
          const u = (newExecRate - nominalRate) / (warningRate - nominalRate);
          statusTrait.setStatusFactor("exec-rate", StatusFactor.create("Exec Rate", StatusVector.of([Status.warning, 0.5 + 0.5 * u])));
        } else {
          const u = (Math.min(newExecRate, alertRate) - warningRate) / (alertRate - warningRate);
          statusTrait.setStatusFactor("exec-rate", StatusFactor.create("Exec Rate", StatusVector.of([Status.alert, 0.5 + 0.5 * u])));
        }
      }
    },
    formatIndicator(value: number): string {
      return Format.prefix(value, 0) + "s/s";
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.ExecRateIndicatorType);
    },
  })
  readonly execRateIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @TraitRef<PulseTrait, ValueIndicatorTrait<number>>({
    type: ValueIndicatorTrait,
    observes: true,
    formatIndicator(value: number): string {
      return Format.prefix(value) + "/s";
    },
    createTrait(): ValueIndicatorTrait<number> {
      return new ValueIndicatorTrait(PulseTrait.TimerRateIndicatorType);
    },
  })
  readonly timerRateIndicator!: TraitRef<this, ValueIndicatorTrait<number>>;

  @ValueDownlinkFastener<PulseTrait, Value, AnyValue>({
    nodeUri(): Uri {
      return this.owner.metaNodeUri;
    },
    laneUri: "pulse",
    didSet(value: Value): void {
      const indicatorGroup = this.owner.indicators.model;

      if (this.owner.fabricIndicators) {
        const partCount = value.get("partCount").numberValue(void 0);
        if (partCount !== void 0) {
          let partCountIndicator = this.owner.partCountIndicator.trait;
          if (partCountIndicator === null && indicatorGroup !== null && partCount !== 0) {
            const partCountModel = new Model();
            partCountIndicator = this.owner.partCountIndicator.insertTrait(partCountModel);
            partCountModel.setTrait("status", new StatusTrait());
            indicatorGroup.appendChild(partCountModel);
          }
          if (partCountIndicator !== null) {
            partCountIndicator.setValue(partCount);
          }
        }

        const hostCount = value.get("hostCount").numberValue(void 0);
        if (hostCount !== void 0) {
          let hostCountIndicator = this.owner.hostCountIndicator.trait;
          if (hostCountIndicator === null && indicatorGroup !== null && hostCount !== 0) {
            const hostCountModel = new Model();
            hostCountIndicator = this.owner.hostCountIndicator.insertTrait(hostCountModel);
            hostCountModel.setTrait("status", new StatusTrait());
            indicatorGroup.appendChild(hostCountModel);
          }
          if (hostCountIndicator !== null) {
            hostCountIndicator.setValue(hostCount);
          }
        }

        const nodeCount = value.get("nodeCount").numberValue(void 0);
        if (nodeCount !== void 0) {
          let nodeCountIndicator = this.owner.nodeCountIndicator.trait;
          if (nodeCountIndicator === null && indicatorGroup !== null && nodeCount !== 0) {
            const nodeCountModel = new Model();
            nodeCountIndicator = this.owner.nodeCountIndicator.insertTrait(nodeCountModel);
            nodeCountModel.setTrait("status", new StatusTrait());
            indicatorGroup.appendChild(nodeCountModel);
          }
          if (nodeCountIndicator !== null) {
            nodeCountIndicator.setValue(nodeCount);
          }
        }
      }

      const agentCount = value.get("agents").get("agentCount").numberValue(void 0);
      if (agentCount !== void 0) {
        let agentCountIndicator = this.owner.agentCountIndicator.trait;
        if (agentCountIndicator === null && indicatorGroup !== null && agentCount !== 0) {
          const agentCountModel = new Model();
          agentCountIndicator = this.owner.agentCountIndicator.insertTrait(agentCountModel);
          agentCountModel.setTrait("status", new StatusTrait());
          indicatorGroup.appendChild(agentCountModel);
        }
        if (agentCountIndicator !== null) {
          agentCountIndicator.setValue(agentCount);
        }
      }

      const downlinks = value.get("downlinks");
      const uplinks = value.get("uplinks");
      if (downlinks.isDefined() || uplinks.isDefined()) {
        const downlinkCount = downlinks.get("linkCount").numberValue(0);
        const uplinkCount = uplinks.get("linkCount").numberValue(0);
        const linkCount = downlinkCount + uplinkCount;
        let linkCountIndicator = this.owner.linkCountIndicator.trait;
        if (linkCountIndicator === null && indicatorGroup !== null && linkCount !== 0) {
          const linkCountModel = new Model();
          linkCountIndicator = this.owner.linkCountIndicator.insertTrait(linkCountModel);
          linkCountModel.setTrait("status", new StatusTrait());
          indicatorGroup.appendChild(linkCountModel);
        }
        if (linkCountIndicator !== null) {
          linkCountIndicator.setValue(linkCount);
        }

        const downlinkEventRate = downlinks.get("eventRate").numberValue(0);
        const uplinkEventRate = uplinks.get("eventRate").numberValue(0);
        const eventRate = downlinkEventRate + uplinkEventRate;
        const downlinkCommandRate = downlinks.get("commandRate").numberValue(0);
        const uplinkCommandRate = uplinks.get("commandRate").numberValue(0);
        const commandRate = downlinkCommandRate + uplinkCommandRate;
        const messageRate = eventRate + commandRate;
        let messageRateIndicator = this.owner.messageRateIndicator.trait;
        if (messageRateIndicator === null && indicatorGroup !== null && messageRate !== 0) {
          const messageRateModel = new Model();
          messageRateIndicator = this.owner.messageRateIndicator.insertTrait(messageRateModel);
          messageRateModel.setTrait("status", new StatusTrait());
          indicatorGroup.appendChild(messageRateModel);
        }
        if (messageRateIndicator !== null) {
          messageRateIndicator.setValue(messageRate);
        }
      }

      const agents = value.get("agents");
      if (agents.isDefined()) {
        const execRate = agents.get("execRate").numberValue(0) / 1000000000;
        let execRateIndicator = this.owner.execRateIndicator.trait;
        if (execRateIndicator === null && indicatorGroup !== null && execRate !== 0) {
          const execRateModel = new Model();
          execRateIndicator = this.owner.execRateIndicator.insertTrait(execRateModel);
          execRateModel.setTrait("status", new StatusTrait());
          indicatorGroup.appendChild(execRateModel);
        }
        if (execRateIndicator !== null) {
          execRateIndicator.setValue(execRate);
        }

        const timerRate = agents.get("timerEventRate").numberValue(0);
        let timerRateIndicator = this.owner.timerRateIndicator.trait;
        if (timerRateIndicator === null && indicatorGroup !== null && timerRate !== 0) {
          const timerRateModel = new Model();
          timerRateIndicator = this.owner.timerRateIndicator.insertTrait(timerRateModel);
          timerRateModel.setTrait("status", new StatusTrait());
          indicatorGroup.appendChild(timerRateModel);
        }
        if (timerRateIndicator !== null) {
          timerRateIndicator.setValue(timerRate);
        }
      }
    },
    didConnect(): void {
      const statusTrait = this.owner.getTrait(StatusTrait);
      if (statusTrait !== null) {
        statusTrait.setStatusFactor("disconnected", null);
        statusTrait.setStatusFactor("connected", StatusFactor.create("Connected", StatusVector.of([Status.normal, 1])));
      }
    },
    didDisconnect(): void {
      const statusTrait = this.owner.getTrait(StatusTrait);
      if (statusTrait !== null) {
        statusTrait.setStatusFactor("connected", null);
        statusTrait.setStatusFactor("disconnected", StatusFactor.create("Disconnected", StatusVector.of([Status.inactive, 1])));
      }
    },
  })
  readonly pulse!: ValueDownlinkFastener<this, Value, AnyValue>;

  /** @internal */
  static PartCountIndicatorType = new IndicatorType("partCount", "Parts");

  /** @internal */
  static HostCountIndicatorType = new IndicatorType("hostCount", "Hosts");

  /** @internal */
  static NodeCountIndicatorType = new IndicatorType("nodeCount", "Nodes");

  /** @internal */
  static AgentCountIndicatorType = new IndicatorType("agentCount", "Agents");

  /** @internal */
  static LinkCountIndicatorType = new IndicatorType("linkCount", "Links");

  /** @internal */
  static MessageRateIndicatorType = new IndicatorType("messageRate", "Messages");

  /** @internal */
  static ExecRateIndicatorType = new IndicatorType("execRate", "Compute");

  /** @internal */
  static TimerRateIndicatorType = new IndicatorType("timerRate", "Timers");
}
