// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity, MemberFastenerClass} from "@swim/component";
import {Trait, TraitRef} from "@swim/model";
import {Look, Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import {GraphicsView, TypesetView} from "@swim/graphics";
import {TraitViewRef} from "@swim/controller";
import {DialView, DialTrait, DialController} from "@swim/gauge";
import {Status, StatusVector, StatusTrait} from "@swim/domain";

/** @public */
export class GaugeGadgetDialController extends DialController {
  @TraitViewRef<GaugeGadgetDialController, DialTrait, DialView>({
    extends: true,
    didAttachTrait(dialTrait: DialTrait, targetTrait: Trait | null): void {
      this.owner.status.setTrait(dialTrait.getTrait(StatusTrait));
      DialController.dial.prototype.didAttachTrait.call(this, dialTrait, targetTrait);
    },
    willDetachTrait(dialTrait: DialTrait): void {
      DialController.dial.prototype.willDetachTrait.call(this, dialTrait);
      this.owner.status.setTrait(null);
    },
    initView(dialView: DialView): void {
      DialController.dial.prototype.initView.call(this, dialView);
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        dialView.modifyMood(Feel.default, [[Feel.opaque, 1], [Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, dialView);
      }
      dialView.dialColor.setLook(Look.neutralColor, Affinity.Intrinsic);
      dialView.meterColor.setLook(Look.accentColor, Affinity.Intrinsic);
      dialView.tickColor.setLook(Look.neutralColor, Affinity.Intrinsic);
    },
  })
  override readonly dial!: TraitViewRef<this, DialTrait, DialView>;
  static override readonly dial: MemberFastenerClass<GaugeGadgetDialController, "dial">;

  @ViewRef<GaugeGadgetDialController, GraphicsView>({
    extends: true,
    initView(labelView: GraphicsView): void {
      DialController.label.prototype.initView.call(this, labelView);
      if (TypesetView.is(labelView)) {
        labelView.textColor.setLook(Look.neutralColor, Affinity.Intrinsic);
      }
    },
  })
  override readonly label!: ViewRef<this, GraphicsView>;
  static override readonly label: MemberFastenerClass<GaugeGadgetDialController, "label">;

  @ViewRef<GaugeGadgetDialController, GraphicsView>({
    extends: true,
    initView(legendView: GraphicsView): void {
      DialController.legend.prototype.initView.call(this, legendView);
      if (TypesetView.is(legendView)) {
        legendView.textColor.setLook(Look.neutralColor, Affinity.Intrinsic);
      }
    },
  })
  override readonly legend!: ViewRef<this, GraphicsView>;
  static override readonly legend: MemberFastenerClass<GaugeGadgetDialController, "legend">;

  @TraitRef<GaugeGadgetDialController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      const dialView = this.owner.dial.view;
      if (dialView !== null) {
        dialView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, dialView);
      }
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      const dialView = this.owner.dial.view;
      if (dialView !== null) {
        this.owner.applyStatusVector(newStatusVector, dialView);
      }
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;
  static readonly status: MemberFastenerClass<GaugeGadgetDialController, "status">;

  protected applyStatusVector(statusVector: StatusVector, dialView: DialView): void {
    const alert = statusVector.get(Status.alert) || 0;
    const warning = statusVector.get(Status.warning) || 0;
    const inactive = statusVector.get(Status.inactive) || 0;
    if (alert !== 0 && warning !== 0) {
      dialView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
    } else if (alert !== 0) {
      dialView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
    } else if (warning !== 0) {
      dialView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
    } else {
      dialView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
    }
    if (inactive !== 0) {
      dialView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
    } else {
      dialView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
    }
  }
}
