// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Affinity, MemberFastenerClass} from "@swim/component";
import {Trait} from "@swim/model";
import {View} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {TraitViewRef, TraitViewControllerSet} from "@swim/controller";
import {RowView, RowTrait, RowController, TableView, TableTrait, TableController} from "@swim/table";
import type {GadgetController} from "../gadget/GadgetController";
import {TableGadgetRowController} from "./TableGadgetRowController";
import type {TableGadgetControllerObserver} from "./TableGadgetControllerObserver";

/** @public */
export class TableGadgetController extends TableController implements GadgetController {
  constructor() {
    super();
    this.onGadgetScroll = this.onGadgetScroll.bind(this);
  }

  override readonly observerType?: Class<TableGadgetControllerObserver>;

  @TraitViewRef<TableGadgetController, TableTrait, TableView>({
    extends: true,
    initView(tableView: TableView): void {
      TableController.table.prototype.initView.call(this, tableView);
      tableView.paddingLeft.setState(18, Affinity.Intrinsic);
      tableView.paddingRight.setState(18, Affinity.Intrinsic);
    }
  })
  override readonly table!: TraitViewRef<this, TableTrait, TableView>;
  static override readonly table: MemberFastenerClass<TableGadgetController, "table">;

  @TraitViewControllerSet<TableGadgetController, RowTrait, RowView, RowController>({
    extends: true,
    type: TableGadgetRowController,
  })
  override readonly rows!: TraitViewControllerSet<this, RowTrait, RowView, RowController>;
  static override readonly rows: MemberFastenerClass<TableGadgetController, "rows">;

  @TraitViewRef<TableGadgetController, Trait, HtmlView>({
    traitType: Trait,
    willAttachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerWillAttachGadgetTrait", gadgetTrait, this.owner);
    },
    didAttachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof TableTrait) {
        this.owner.table.setTrait(gadgetTrait);
      }
    },
    willDetachTrait(gadgetTrait: Trait): void {
      if (gadgetTrait instanceof TableTrait) {
        this.owner.table.setTrait(null);
      }
    },
    didDetachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("controllerDidDetachGadgetTrait", gadgetTrait, this.owner);
    },
    viewType: HtmlView,
    initView(gadgetView: HtmlView): void {
      gadgetView.position.setState("relative", Affinity.Intrinsic);
      //gadgetView.height.setState(160, Affinity.Intrinsic);
      gadgetView.overflowX.setState("hidden", Affinity.Intrinsic);
      gadgetView.overflowY.setState("auto", Affinity.Intrinsic);
    },
    willAttachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerWillAttachGadgetView", gadgetView, this.owner);
    },
    didAttachView(gadgetView: HtmlView): void {
      gadgetView.on("scroll", this.owner.onGadgetScroll);
      this.owner.table.insertView(gadgetView);
    },
    willDetachView(gadgetView: HtmlView): void {
      gadgetView.off("scroll", this.owner.onGadgetScroll);
    },
    didDetachView(gadgetView: HtmlView): void {
      this.owner.callObservers("controllerDidDetachGadgetView", gadgetView, this.owner);
    },
  })
  readonly gadget!: TraitViewRef<this, Trait, HtmlView>;
  static readonly gadget: MemberFastenerClass<TableGadgetController, "gadget">;

  protected onGadgetScroll(event: Event): void {
    const gadgetView = this.gadget.view;
    if (gadgetView !== null) {
      gadgetView.requireUpdate(View.NeedsScroll);
    }
  }
}
