// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Graphics, VectorIcon} from "@swim/graphics";
import {MirrorController, MirrorPlugin} from "@swim/prism";
import {CatalogController} from "./CatalogController";

/** @public */
export class CatalogPlugin extends MirrorPlugin {
  override get id(): string {
    return "catalog";
  }

  override get title(): string {
    return "Catalog";
  }

  override get icon(): Graphics {
    return CatalogPlugin.icon;
  }

  override createController(): MirrorController {
    return new CatalogController();
  }

  @Lazy
  static get icon(): Graphics {
    return VectorIcon.create(24, 24, "M3,13L5,13L5,11L3,11L3,13ZM3,19L5,19L5,17L3,17L3,19ZM3,7L5,7L5,5L3,5L3,7ZM7,13L21,13L21,11L7,11L7,13ZM7,19L21,19L21,17L7,17L7,19ZM7,5L7,7L21,7L21,5L7,5Z");
  }
}
