// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy} from "@swim/util";
import {Graphics, VectorIcon} from "@swim/graphics";
import {MirrorController, MirrorPlugin} from "@swim/prism";
import {DiagramController} from "./DiagramController";

/** @public */
export class DiagramPlugin extends MirrorPlugin {
  override get id(): string {
    return "diagram";
  }

  override get title(): string {
    return "Diagram";
  }

  override get icon(): Graphics {
    return DiagramPlugin.icon;
  }

  override createController(): MirrorController {
    return new DiagramController();
  }

  @Lazy
  static get icon(): Graphics {
    return VectorIcon.create(24, 24, "M19.9,2L22,5.5L19.9,9L15.7,9L14.7,7.3C14.3,7.5,14,7.8,13.6,8.1L13.4,8.3L12.9,8.7L11.8,9.6C11.1,10.3,10.5,10.8,9.9,11.2L10.4,12L9.9,12.8C10.2,13.1,10.6,13.3,11,13.7L11.3,13.9L11.8,14.4L12.9,15.3C13.6,15.9,14.1,16.4,14.7,16.7L15.7,15L19.9,15L22,18.5L19.9,22L15.7,22L13.6,18.5L14.1,17.7C13.5,17.2,12.8,16.6,11.9,15.9L11.1,15.2C10.4,14.6,9.9,14.2,9.3,13.8L8.3,15.5L4.1,15.5L2,12L4.1,8.5L8.3,8.5L9.3,10.2C9.9,9.8,10.6,9.3,11.4,8.6L11.9,8.1C12.8,7.4,13.5,6.8,14.1,6.3L13.6,5.5L15.7,2L19.9,2Z");
  }
}
