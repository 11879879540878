// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Class, Lazy} from "@swim/util";
import {Affinity, Property, Provider} from "@swim/component";
import {Model, TraitRef} from "@swim/model";
import {Feel} from "@swim/theme";
import {PositionGestureInput, ViewRef} from "@swim/view";
import {Graphics, VectorIcon} from "@swim/graphics";
import {Controller} from "@swim/controller";
import {TextCellView, IconCellView, RowView} from "@swim/table";
import {Status, StatusVector, StatusTrait, EntityTrait, DomainTrait} from "@swim/domain";
import {PrismService, PrismProvider, EntityPlugin} from "@swim/prism";
import type {CollectionControllerObserver} from "./CollectionControllerObserver";

/** @public */
export class CollectionController extends Controller {
  override readonly observerType?: Class<CollectionControllerObserver>;

  get domainIcon(): Graphics {
    return CollectionController.domainIcon;
  }

  protected injectEntities(model: Model, domainTrait: DomainTrait): void {
    const entityTrait = model.getTrait(EntityTrait);
    if (entityTrait !== null) {
      this.injectEntity(entityTrait, domainTrait);
    }
    type self = this;
    function injectEntity(this: self, childModel: Model): void {
      this.injectEntities(childModel, domainTrait);
    }
    model.forEachChild(injectEntity, this);
  }

  protected injectEntity(entityTrait: EntityTrait, domainTrait: DomainTrait): void {
    const plugins = this.prismProvider.plugins;
    for (let i = 0, n = plugins.length; i < n; i += 1) {
      const plugin = plugins[i];
      if (plugin instanceof EntityPlugin) {
        plugin.injectEntity(entityTrait, domainTrait);
      }
    }
  }

  protected activate(): void {
    const domainTrait = this.domain.trait;
    if (domainTrait !== null) {
      domainTrait.model!.remove();
    }
  }

  @Property({type: Boolean, value: false, inherits: true})
  readonly fullBleed!: Property<this, boolean>;

  @ViewRef<CollectionController, RowView>({
    type: RowView,
    observes: true,
    didAttachView(rowView: RowView): void {
      const iconCellView = rowView.getOrCreateCell("icon", IconCellView);
      iconCellView.iconWidth.setState(24, Affinity.Intrinsic);
      iconCellView.iconHeight.setState(24, Affinity.Intrinsic);

      const entityTrait = this.owner.entity.trait;
      if (entityTrait !== null) {
        let entityIcon = entityTrait.icon.value;
        if (entityIcon === null) {
          entityIcon = this.owner.domainIcon;
        }
        iconCellView.graphics.setState(entityIcon, Affinity.Intrinsic);
        let entityTitle = entityTrait.title.value;
        if (entityTitle === void 0) {
          entityTitle = "";
        }
        const titleCellView = rowView.getOrCreateCell("title", TextCellView);
        titleCellView.content.setView(entityTitle);
      }
    },
    viewDidPressLeaf(input: PositionGestureInput, event: Event | null): void {
      this.owner.activate();
    },
  })
  readonly row!: ViewRef<this, RowView>;

  @TraitRef<CollectionController, DomainTrait>({
    type: DomainTrait,
    observes: true,
    didAttachTrait(domainTrait: DomainTrait): void {
      this.owner.entity.setTrait(domainTrait.getTrait(EntityTrait));
      this.owner.status.setTrait(domainTrait.getTrait(StatusTrait));
      const model = domainTrait.model;
      if (model !== null) {
        this.owner.injectEntities(model, domainTrait);
      }
    },
    domainDidInjectEntity(entityTrait: EntityTrait, domainTrait: DomainTrait): void {
      this.owner.injectEntity(entityTrait, domainTrait);
    },
  })
  readonly domain!: TraitRef<this, DomainTrait>;

  @TraitRef<CollectionController, EntityTrait>({
    type: EntityTrait,
    observes: true,
    didAttachTrait(entityTrait: EntityTrait): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        const iconCellView = rowView.getOrCreateCell("icon", IconCellView);
        let entityIcon = entityTrait.icon.value;
        if (entityIcon === null) {
          entityIcon = this.owner.domainIcon;
        }
        iconCellView.graphics(entityIcon);

        const titleCellView = rowView.getOrCreateCell("title", TextCellView);
        let entityTitle = entityTrait.title.value;
        if (entityTitle === void 0) {
          entityTitle = "";
        }
        titleCellView.content(entityTitle);
      }
    },
    entityDidSetTitle(title: string | undefined, entityTrait: EntityTrait): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        const titleCellView = rowView.getOrCreateCell("title", TextCellView);
        titleCellView.content(title !== void 0 ? title : "");
      }
    },
    entityDidSetIcon(icon: Graphics | null, entityTrait: EntityTrait): void {
      const rowView = this.owner.row.view;
      if (rowView !== null) {
        const iconCellView = rowView.getOrCreateCell("icon", IconCellView);
        iconCellView.graphics(icon !== null ? icon : this.owner.domainIcon);
      }
    },
  })
  readonly entity!: TraitRef<this, EntityTrait>;

  @TraitRef<CollectionController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    willAttachTrait(statusTrait: StatusTrait): void {
      this.owner.applyStatus(statusTrait.statusVector);
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      this.owner.applyStatus(newStatusVector);
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;

  protected applyStatus(statusVector: StatusVector): void {
    const rowView = this.row.view;
    if (rowView !== null) {
      const alert = statusVector.get(Status.alert) || 0;
      const warning = statusVector.get(Status.warning) || 0;
      const inactive = statusVector.get(Status.inactive) || 0;
      if (alert !== 0 && warning !== 0) {
        rowView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
      } else if (alert !== 0) {
        rowView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
      } else if (warning !== 0) {
        rowView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
      } else {
        rowView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
      }
      if (inactive !== 0) {
        rowView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
      } else {
        rowView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
      }
    }
  }

  @Provider<CollectionController, PrismService>({
    extends: PrismProvider,
    type: PrismService,
    observes: false,
    service: PrismService.global(),
  })
  readonly prismProvider!: PrismProvider<this>;

  @Lazy
  static get domainIcon(): Graphics {
    return VectorIcon.create(24, 24, "M4,4L23,4L20,20L1,20Z");
  }
}
