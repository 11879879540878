// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity} from "@swim/component";
import {Look, Feel} from "@swim/theme";
import {PopoverView} from "@swim/window";

/** @public */
export class ActivityWindow extends PopoverView {
  constructor(node: HTMLElement) {
    super(node);
    this.initWindow();
    this.initTheme();
  }

  protected initWindow(): void {
    this.addClass("activity-window");
    this.backgroundColor.setLook(Look.backgroundColor, Affinity.Intrinsic);
  }

  protected initTheme(): void {
    this.modifyTheme(Feel.default, [[Feel.covered, 1]]);
  }
}
