// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Trait} from "@swim/model";
import type {EntityTrait} from "../entity/EntityTrait";
import type {DomainTraitObserver} from "./DomainTraitObserver";

/** @public */
export class DomainTrait extends Trait {
  override readonly observerType?: Class<DomainTraitObserver>;

  injectEntity(entityTrait: EntityTrait): void {
    this.willInjectEntity(entityTrait);
    this.onInjectEntity(entityTrait);
    this.didInjectEntity(entityTrait);
  }

  protected willInjectEntity(entityTrait: EntityTrait): void {
    this.callObservers("domainWillInjectEntity", entityTrait, this);
  }

  protected onInjectEntity(entityTrait: EntityTrait): void {
    // hook
  }

  protected didInjectEntity(entityTrait: EntityTrait): void {
    this.callObservers("domainDidInjectEntity", entityTrait, this);
  }
}
