// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Lazy, Mutable} from "@swim/util";
import {Model, ModelRef, TraitRef} from "@swim/model";
import {Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {Graphics, VectorIcon} from "@swim/graphics";
import {ControllerRef} from "@swim/controller";
import {IconButton} from "@swim/button";
import {ColLayout, TableLayout, ColView, HeaderView, TableView} from "@swim/table";
import {EntityTrait} from "@swim/domain";
import {ActivityController} from "@swim/prism";
import type {MetaNodeEntity} from "@swim/fabric";
import {LaneModel} from "./LaneModel";
import {ManifestModel} from "./ManifestModel";
import {LaneController} from "./LaneController";
import {InventoryView} from "./InventoryView";

/** @public */
export class InventoryController extends ActivityController {
  constructor() {
    super();
    this.selectedItem = null;
  }

  /** @internal */
  readonly selectedItem: LaneController | null;

  @ViewRef<InventoryController, InventoryView>({
    type: InventoryView,
    initView(rootView: InventoryView): void {
      rootView.position("absolute")
              .top(0)
              .right(0)
              .bottom(0)
              .left(0);

      this.owner.masterTree.setView(rootView.masterTree.view);
      this.owner.detail.setView(rootView.detail.view);
      this.owner.closeButton.insertView(rootView);
    },
  })
  readonly root!: ViewRef<this, InventoryView>;

  protected createMasterLayout(): TableLayout {
    return TableLayout.create([
      ColLayout.create("icon", 0, 0, 64),
      ColLayout.create("name", 1, 1, 200),
      ColLayout.create("kind", 0, 0, 64),
    ]);
  }

  protected updateMasterHeader(headerView: HeaderView): void {
    let nameColView = headerView.getChild("name") as ColView | null;
    if (nameColView === null) {
      nameColView = ColView.create().label("Name");
      headerView.appendChild(nameColView, "name");
    }
    let kindColView = headerView.getChild("kind") as ColView | null;
    if (kindColView === null) {
      kindColView = ColView.create().label("Kind");
      headerView.appendChild(kindColView, "kind");
    }
  }

  @ViewRef<InventoryController, TableView>({
    type: TableView,
    initView(masterTreeView: TableView): void {
      masterTreeView.modifyTheme(Feel.default, [[Feel.primary, 1], [Feel.transparent, 1], [Feel.unselected, 1]], false);
      masterTreeView.rowHeight.setState(44);
      masterTreeView.layout.setValue(this.owner.createMasterLayout());

      const headerView = HeaderView.create();
      masterTreeView.header.setView(headerView);
      this.owner.updateMasterHeader(headerView);

      let child = this.owner.firstChild;
      while (child !== null) {
        if (child instanceof LaneController) {
          this.owner.onInsertItem(child);
        }
        child = child.nextSibling;
      }
    },
  })
  readonly masterTree!: ViewRef<this, TableView>;

  @ViewRef<InventoryController, HtmlView>({
    type: HtmlView,
  })
  readonly detail!: ViewRef<this, HtmlView>;

  @ViewRef<InventoryController, IconButton>({
    type: IconButton,
    observes: true,
    initView(closeButton: IconButton): void {
      closeButton.position("absolute")
                 .top(0)
                 .left(0)
                 .width(InventoryView.BarHeight)
                 .height(InventoryView.BarHeight);
      closeButton.pushIcon(InventoryController.closeIcon);
    },
    buttonDidPress(closeButton: IconButton): void {
      const windowView = this.owner.window.view;
      if (windowView !== null) {
        windowView.modalProvider.dismissModals();
      }
    },
  })
  readonly closeButton!: ViewRef<this, IconButton>;

  protected onInsertItem(item: LaneController): void {
    const masterTreeView = this.masterTree.view;
    if (masterTreeView !== null) {
      const id = item.lane.model!.ownLaneUri.toString();
      const rowView = item.row.createView();
      if (rowView !== null) {
        const targetItem = item.nextSibling;
        const targetView = targetItem instanceof LaneController ? targetItem.row.view : null;
        masterTreeView.insertChild(rowView, targetView, id);
        item.row.setView(rowView);
      }
    }
  }

  protected onRemoveItem(item: LaneController): void {
    const rowView = item.row.view;
    if (rowView !== null) {
      rowView.remove();
    }
  }

  protected selectItem(newItem: LaneController): void {
    const oldItem = this.selectedItem;
    if (oldItem !== null) {
      oldItem.unselect();
      oldItem.detail.setView(null);
    }
    const detailView = this.detail.view;
    if (detailView !== null) {
      detailView.removeChildren();
      (this as Mutable<this>).selectedItem = newItem;
      newItem.select();
      newItem.detail.setView(this.detail.view);
    }
  }

  static ItemRef = ControllerRef.define<InventoryController, LaneController>("ItemRef", {
    type: LaneController,
    observes: true,
    didAttachController(item: LaneController): void {
      this.owner.onInsertItem(item);
    },
    willDetachController(item: LaneController): void {
      this.owner.onRemoveItem(item);
    },
    controllerDidActivateLane(item: LaneController): void {
      this.owner.selectItem(item);
    },
  });

  @ViewRef<InventoryController, HtmlView>({
    type: HtmlView,
    didAttachView(windowView: HtmlView): void {
      this.owner.root.insertView(windowView);
    },
    willDetachView(windowView: HtmlView): void {
      windowView.removeChildren();
      const manifestModel = this.owner.manifest.model;
      if (manifestModel !== null) {
        manifestModel.remove();
      }
    },
  })
  override readonly window!: ViewRef<this, HtmlView>;

  @TraitRef<InventoryController, EntityTrait>({
    type: EntityTrait,
    didAttachTrait(entityTrait: EntityTrait): void {
      const model = entityTrait.model;
      if (model !== null) {
        this.owner.manifest.insertModel(model);
      }
    },
    willDetachTrait(entityTrait: EntityTrait): void {
      this.owner.manifest.removeModel();
    },
  })
  override readonly entity!: TraitRef<this, EntityTrait>;

  protected onInsertLane(childEntity: LaneModel, targetEntity: LaneModel | null): void {
    const id = childEntity.ownLaneUri.toString();
    let controllerRef = this.getFastener(id, ControllerRef) as ControllerRef<this, LaneController> | null;
    if (controllerRef === null) {
      controllerRef = InventoryController.ItemRef.create(this) as ControllerRef<this, LaneController>;
      Object.defineProperty(controllerRef, "key", {
        value: id,
        enumerable: true,
        configurable: true,
      });
      this.setFastener(id, controllerRef);
      const itemController = new LaneController();
      itemController.lane.setModel(childEntity);
      controllerRef.setController(itemController);
      const targetController = targetEntity !== null ? this.getChild(targetEntity.ownLaneUri.toString()) : null;
      this.insertChild(itemController, targetController, id);
    }
  }

  protected onRemoveLane(childEntity: LaneModel): void {
    const id = childEntity.ownLaneUri.toString();
    const controllerRef = this.getFastener(id, ControllerRef) as ControllerRef<this, LaneController> | null;
    if (controllerRef !== null) {
      controllerRef.deleteController();
      this.setFastener(id, null);
    }
  }

  @ModelRef<InventoryController, ManifestModel>({
    type: ManifestModel,
    observes: true,
    modelDidInsertChild(child: Model, target: Model | null): void {
      if (child instanceof LaneModel) {
        this.owner.onInsertLane(child, target instanceof LaneModel ? target : null);
      }
    },
    modelWillRemoveChild(child: Model): void {
      if (child instanceof LaneModel) {
        this.owner.onRemoveLane(child);
      }
    },
    createModel(): ManifestModel {
      const entityTrait = this.owner.entity.trait! as MetaNodeEntity;
      return new ManifestModel(entityTrait.metaNodeUri);
    },
  })
  readonly manifest!: ModelRef<this, ManifestModel>;

  @Lazy
  static get closeIcon(): Graphics {
    return VectorIcon.create(24, 24, "M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4L10.6,12L5,17.6L6.4,19L12,13.4L17.6,19L19,17.6L13.4,12Z");
  }
}
