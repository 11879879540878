// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Value} from "@swim/structure";
import type {Uri} from "@swim/uri";
import {EventDownlink, EventDownlinkFastener} from "@swim/client";
import {LaneModel} from "./LaneModel";

/** @public */
export class EventLaneModel extends LaneModel {
  @EventDownlinkFastener<EventLaneModel>({
    consumed: true,
    nodeUri(): Uri {
      return this.owner.ownNodeUri;
    },
    laneUri(): Uri {
      return this.owner.ownLaneUri;
    },
    onEvent(value: Value): void {
      this.owner.onEvent(value);
    },
    initDownlink(downlink: EventDownlink): EventDownlink {
      return downlink.keepSynced(true);
    },
  })
  readonly data!: EventDownlinkFastener<this>;
}
