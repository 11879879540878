// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Affinity, MemberFastenerClass} from "@swim/component";
import {Trait, TraitRef} from "@swim/model";
import {Look, Feel} from "@swim/theme";
import {ViewRef} from "@swim/view";
import {GraphicsView, TypesetView} from "@swim/graphics";
import {TraitViewRef} from "@swim/controller";
import {SliceView, SliceTrait, SliceController} from "@swim/pie";
import {Status, StatusVector, StatusTrait} from "@swim/domain";

/** @public */
export class PieGadgetSliceController extends SliceController {
  @TraitViewRef<PieGadgetSliceController, SliceTrait, SliceView>({
    extends: true,
    didAttachTrait(sliceTrait: SliceTrait, targetTrait: Trait | null): void {
      this.owner.status.setTrait(sliceTrait.getTrait(StatusTrait));
      SliceController.slice.prototype.didAttachTrait.call(this, sliceTrait, targetTrait);
    },
    willDetachTrait(sliceTrait: SliceTrait): void {
      SliceController.slice.prototype.willDetachTrait.call(this, sliceTrait);
      this.owner.status.setTrait(null);
    },
    initView(sliceView: SliceView): void {
      SliceController.slice.prototype.initView.call(this, sliceView);
      const statusTrait = this.owner.status.trait;
      if (statusTrait !== null) {
        sliceView.modifyMood(Feel.default, [[Feel.opaque, 1], [Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, sliceView);
      }
      sliceView.sliceColor.setLook(Look.accentColor, Affinity.Intrinsic);
      sliceView.tickColor.setLook(Look.neutralColor, Affinity.Intrinsic);
    },
  })
  override readonly slice!: TraitViewRef<this, SliceTrait, SliceView>;
  static override readonly slice: MemberFastenerClass<PieGadgetSliceController, "slice">;

  @ViewRef<PieGadgetSliceController, GraphicsView>({
    extends: true,
    initView(labelView: GraphicsView): void {
      SliceController.label.prototype.initView.call(this, labelView);
      if (TypesetView.is(labelView)) {
        labelView.textColor.setLook(Look.backgroundColor, Affinity.Intrinsic);
      }
    },
  })
  override readonly label!: ViewRef<this, GraphicsView>;
  static override readonly label: MemberFastenerClass<PieGadgetSliceController, "label">;

  @ViewRef<PieGadgetSliceController, GraphicsView>({
    extends: true,
    initView(legendView: GraphicsView): void {
      SliceController.legend.prototype.initView.call(this, legendView);
      if (TypesetView.is(legendView)) {
        legendView.textColor.setLook(Look.neutralColor, Affinity.Intrinsic);
      }
    },
  })
  override readonly legend!: ViewRef<this, GraphicsView>;
  static override readonly legend: MemberFastenerClass<PieGadgetSliceController, "legend">;

  @TraitRef<PieGadgetSliceController, StatusTrait>({
    type: StatusTrait,
    observes: true,
    initTrait(statusTrait: StatusTrait): void {
      const sliceView = this.owner.slice.view;
      if (sliceView !== null) {
        sliceView.modifyMood(Feel.default, [[Feel.primary, 1]]);
        this.owner.applyStatusVector(statusTrait.statusVector, sliceView);
      }
    },
    traitDidSetStatusVector(newStatusVector: StatusVector, oldStatusVector: StatusVector): void {
      const sliceView = this.owner.slice.view;
      if (sliceView !== null) {
        this.owner.applyStatusVector(newStatusVector, sliceView);
      }
    },
  })
  readonly status!: TraitRef<this, StatusTrait>;
  static readonly status: MemberFastenerClass<PieGadgetSliceController, "status">;

  protected applyStatusVector(statusVector: StatusVector, sliceView: SliceView): void {
    const alert = statusVector.get(Status.alert) || 0;
    const warning = statusVector.get(Status.warning) || 0;
    const inactive = statusVector.get(Status.inactive) || 0;
    if (alert !== 0 && warning !== 0) {
      sliceView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, alert]]);
    } else if (alert !== 0) {
      sliceView.modifyMood(Feel.default, [[Feel.warning, 1], [Feel.alert, alert]]);
    } else if (warning !== 0) {
      sliceView.modifyMood(Feel.default, [[Feel.warning, warning], [Feel.alert, void 0]]);
    } else {
      sliceView.modifyMood(Feel.default, [[Feel.warning, void 0], [Feel.alert, void 0]]);
    }
    if (inactive !== 0) {
      sliceView.modifyMood(Feel.default, [[Feel.inactive, inactive]]);
    } else {
      sliceView.modifyMood(Feel.default, [[Feel.inactive, void 0]]);
    }
  }
}
