// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Class} from "@swim/util";
import {Property} from "@swim/component";
import {Model, Trait, TraitSet} from "@swim/model";
import type {HtmlView} from "@swim/dom";
import type {WidgetTraitObserver} from "./WidgetTraitObserver";

/** @public */
export type WidgetTitle = WidgetTitleFunction | string;
/** @public */
export type WidgetTitleFunction = (widgetTrait: WidgetTrait) => HtmlView | string | null;

/** @public */
export type WidgetSubtitle = WidgetSubtitleFunction | string;
/** @public */
export type WidgetSubtitleFunction = (widgetTrait: WidgetTrait) => HtmlView | string | null;

/** @public */
export class WidgetTrait extends Trait {
  override readonly observerType?: Class<WidgetTraitObserver>;

  @Property<WidgetTrait, WidgetTitle | null>({
    type: String,
    value: null,
    willSetValue(newTitle: WidgetTitle | null, oldTitle: WidgetTitle | null): void {
      this.owner.callObservers("traitWillSetTitle", newTitle, oldTitle, this.owner);
    },
    didSetValue(newTitle: WidgetTitle | null, oldTitle: WidgetTitle | null): void {
      this.owner.callObservers("traitDidSetTitle", newTitle, oldTitle, this.owner);
    },
    equalValues(newTitle: WidgetTitle | null, oldTitle: WidgetTitle | null): boolean {
      return newTitle === oldTitle;
    },
  })
  readonly title!: Property<this, WidgetTitle | null>;

  @Property<WidgetTrait, WidgetTitle | null>({
    type: String,
    value: null,
    willSetValue(newSubtitle: WidgetTitle | null, oldSubtitle: WidgetTitle | null): void {
      this.owner.callObservers("traitWillSetSubtitle", newSubtitle, oldSubtitle, this.owner);
    },
    didSetValue(newSubtitle: WidgetTitle | null, oldSubtitle: WidgetTitle | null): void {
      this.owner.callObservers("traitDidSetSubtitle", newSubtitle, oldSubtitle, this.owner);
    },
    equalValues(newSubtitle: WidgetTitle | null, oldSubtitle: WidgetTitle | null): boolean {
      return newSubtitle === oldSubtitle;
    },
  })
  readonly subtitle!: Property<this, WidgetTitle | null>;

  @TraitSet<WidgetTrait, Trait>({
    type: Trait,
    binds: true,
    willAttachTrait(gadgetTrait: Trait, targetTrait: Trait | null): void {
      this.owner.callObservers("traitWillAttachGadget", gadgetTrait, targetTrait, this.owner);
    },
    didAttachTrait(gadgetTrait: Trait, targetTrait: Trait | null): void {
      if (this.owner.consuming) {
        gadgetTrait.consume(this);
      }
    },
    willDetachTrait(gadgetTrait: Trait): void {
      if (this.owner.consuming) {
        gadgetTrait.unconsume(this);
      }
    },
    didDetachTrait(gadgetTrait: Trait): void {
      this.owner.callObservers("traitDidDetachGadget", gadgetTrait, this.owner);
    },
    detectModel(model: Model): Trait | null {
      const observers = this.owner.observers;
      for (let i = 0, n = observers.length; i < n; i += 1) {
        const observer = observers[i]!;
        if (observer.detectGadgetModel !== void 0) {
          const gadgetTrait = observer.detectGadgetModel(model, this.owner);
          if (gadgetTrait !== null) {
            return gadgetTrait;
          }
        }
      }
      return null;
    },
    detectTrait(trait: Trait): Trait | null {
      return null;
    },
  })
  readonly gadgets!: TraitSet<this, Trait>;

  /** @internal */
  protected startConsumingGadgets(): void {
    const gadgetTraits = this.gadgets.traits;
    for (const traitId in gadgetTraits) {
      const gadgetTrait = gadgetTraits[traitId]!;
      gadgetTrait.consume(this);
    }
  }

  /** @internal */
  protected stopConsumingGadgets(): void {
    const gadgetTraits = this.gadgets.traits;
    for (const traitId in gadgetTraits) {
      const gadgetTrait = gadgetTraits[traitId]!;
      gadgetTrait.unconsume(this);
    }
  }

  protected override onStartConsuming(): void {
    super.onStartConsuming();
    this.startConsumingGadgets();
  }

  protected override onStopConsuming(): void {
    super.onStopConsuming();
    this.stopConsumingGadgets();
  }
}
