// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {Equals} from "@swim/util";
import {Debug, Format, Output} from "@swim/codec";
import {AnyDateTime, DateTime} from "@swim/time";
import {AnyStatusVector, StatusVector} from "../status/StatusVector";

/** @public */
export type AnyStatusFactor = StatusFactor | StatusFactorInit;

/** @public */
export interface StatusFactorInit {
  name: string;
  vector: AnyStatusVector;
  weight?: number;
  since?: AnyDateTime;
}

/** @public */
export class StatusFactor implements Equals, Debug {
  constructor(name: string, vector: StatusVector, weight: number, since: DateTime) {
    this.name = name;
    this.vector = vector;
    this.weight = weight;
    this.since = since;
  }

  readonly name: string;

  readonly vector: StatusVector;

  readonly weight: number;

  readonly since: DateTime;

  equals(that: unknown): boolean {
    if (this === that) {
      return true;
    } else if (that instanceof StatusFactor) {
      return this.name === that.name
          && this.vector.equals(that.vector)
          && this.weight === that.weight
          && this.since.equals(that.since);
    }
    return false;
  }

  debug<T>(output: Output<T>): Output<T> {
    output = output.write("StatusFactor").write(46/*'.'*/).write("create").write(40/*'('*/)
                   .debug(this.name).write(", ").debug(this.vector).write(", ")
                   .debug(this.weight).write(", ").debug(this.since).write(41/*')'*/);
    return output;
  }

  toString(): string {
    return Format.debug(this);
  }

  static create(name: string, vector: AnyStatusVector, weight?: number, since?: AnyDateTime): StatusFactor {
    vector = StatusVector.fromAny(vector);
    if (weight === void 0) {
      weight = 1;
    }
    if (since === void 0) {
      since = DateTime.current();
    } else {
      since = DateTime.fromAny(since);
    }
    return new StatusFactor(name, vector, weight, since as DateTime);
  }

  static fromInit(init: StatusFactorInit): StatusFactor {
    const name = init.name;
    const vector = StatusVector.fromAny(init.vector);
    const weight = init.weight !== void 0 ? init.weight : 1;
    const since = init.since !== void 0 ? DateTime.fromAny(init.since) : DateTime.current();
    return new StatusFactor(name, vector, weight, since);
  }

  static fromAny(value: AnyStatusFactor): StatusFactor {
    if (value === void 0 || value === null || value instanceof StatusFactor) {
      return value;
    } else if (StatusFactor.isInit(value)) {
      return StatusFactor.fromInit(value);
    }
    throw new TypeError("" + value);
  }

  /** @internal */
  static isInit(value: unknown): value is StatusFactorInit {
    if (typeof value === "object" && value !== null) {
      const init = value as StatusFactorInit;
      return typeof init.name === "string"
          && init.vector !== void 0;
    }
    return false;
  }

  /** @internal */
  static isAny(value: unknown): value is AnyStatusFactor {
    return value instanceof StatusFactor
        || StatusFactor.isInit(value);
  }
}
