// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {AnyValue, Value} from "@swim/structure";
import {Uri, UriPath} from "@swim/uri";
import {MapDownlinkFastener} from "@swim/client";
import {Model, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {MetaMeshEntity} from "./MetaMeshEntity";

/** @public */
export class MetaMeshGroup extends EntityGroup {
  constructor(metaEdgeUri: Uri) {
    super();
    this.metaEdgeUri = metaEdgeUri;
  }

  readonly metaEdgeUri: Uri;

  protected createMesh(meshKey: string, metaMeshUri: Uri): Model | null {
    const meshModel = new Model();

    const entityTrait = new MetaMeshEntity(metaMeshUri);
    if (meshKey === "") {
      entityTrait.title.setValue("Default Mesh");
    } else {
      entityTrait.title.setValue(meshKey);
    }
    meshModel.setTrait("entity", entityTrait);

    meshModel.setTrait("selectable", new SelectableTrait());
    meshModel.setTrait("status", new StatusTrait());
    meshModel.setTrait("indicated", new IndicatedTrait());
    meshModel.setTrait("pulse", new PulseTrait(metaMeshUri));

    return meshModel;
  }

  protected didUpdateMesh(key: Value, value: Value): void {
    const meshKey = key.stringValue("");
    let meshModel = this.getChild(meshKey);
    if (meshModel === null) {
      const metaEdgeUri = this.metaEdgeUri;
      const pathBuilder = UriPath.builder();
      if (metaEdgeUri.path.toString() === "meta:edge") {
        pathBuilder.addSegment("meta:mesh");
      } else {
        pathBuilder.addPath(metaEdgeUri.path);
        pathBuilder.addSegment("mesh");
      }
      if (meshKey !== "") {
        pathBuilder.addSegment(meshKey);
      }
      const metaMeshUri = Uri.create(metaEdgeUri.scheme, metaEdgeUri.authority, pathBuilder.bind());
      meshModel = this.createMesh(meshKey, metaMeshUri);
      if (meshModel !== null) {
        this.appendChild(meshModel, meshKey);
      }
    }
  }

  protected didRemoveMesh(key: Value): void {
    const meshKey = key.stringValue("");
    this.removeChild(meshKey);
  }

  @MapDownlinkFastener<MetaMeshGroup, Value, Value, AnyValue, AnyValue>({
    consumed: true,
    nodeUri(): Uri {
      return this.owner.metaEdgeUri;
    },
    laneUri: "meshes",
    didUpdate(key: Value, value: Value): void {
      this.owner.didUpdateMesh(key, value);
    },
    didRemove(key: Value): void {
      this.owner.didRemoveMesh(key);
    },
  })
  readonly meshes!: MapDownlinkFastener<this, Value, Value, AnyValue, AnyValue>;
}
