// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import type {AnyValue, Value} from "@swim/structure";
import {Uri, UriPath, UriFragment} from "@swim/uri";
import {MapDownlinkFastener} from "@swim/client";
import {Model, SelectableTrait} from "@swim/model";
import {StatusTrait, IndicatedTrait, EntityTrait, EntityGroup} from "@swim/domain";
import {PulseTrait} from "./PulseTrait";
import {MetaNodeEntity} from "./MetaNodeEntity";

/** @public */
export class MetaNodeGroup extends EntityGroup {
  constructor(nodeUri: Uri, metaHostUri: Uri) {
    super();
    this.nodeUri = nodeUri;
    this.metaHostUri = metaHostUri;
  }

  readonly nodeUri: Uri;

  get nodePath(): UriPath {
    return this.nodeUri.path;
  }

  readonly metaHostUri: Uri;

  protected createNode(nodeKey: string, nodeUri: Uri, metaNodeUri: Uri): Model | null {
    const nodeModel = new Model();

    const entityTrait = new MetaNodeEntity(nodeUri, metaNodeUri, this.metaHostUri);
    entityTrait.title.setValue(UriPath.parse(nodeKey).foot().head());
    nodeModel.setTrait("entity", entityTrait);

    nodeModel.setTrait("selectable", new SelectableTrait());
    nodeModel.setTrait("status", new StatusTrait());
    nodeModel.setTrait("indicated", new IndicatedTrait());
    nodeModel.setTrait("pulse", new PulseTrait(metaNodeUri));

    return nodeModel;
  }

  protected didUpdateNode(key: Value, value: Value): void {
    const nodeKey = key.stringValue("");
    let nodeModel = this.getChild(nodeKey);
    if (nodeModel === null) {
      const metaHostUri = this.metaHostUri;
      const metaHostPath = metaHostUri.path;
      const pathBuilder = UriPath.builder();
      if (metaHostPath.toString() === "meta:mesh") {
        pathBuilder.addSegment("meta:node");
      } else if (metaHostPath.toString() === "meta:node") {
        pathBuilder.addSegment("meta:node");
      } else {
        pathBuilder.addPath(metaHostPath);
        pathBuilder.addSegment("node");
      }
      pathBuilder.addSegment(nodeKey);
      const nodeUri = Uri.parse(nodeKey);
      const metaNodeUri = Uri.create(metaHostUri.scheme, metaHostUri.authority, pathBuilder.bind());
      nodeModel = this.createNode(nodeKey, nodeUri, metaNodeUri);
      if (nodeModel !== null) {
        this.appendChild(nodeModel, nodeKey);
      }
    }
    if (nodeModel !== null) {
      const childCount = value.get("childCount").numberValue(0);
      const entityTrait = nodeModel.getTrait(EntityTrait);
      if (entityTrait !== null) {
        if (childCount !== 0) {
          if (entityTrait.subentities.model === null) {
            entityTrait.subentities.insertModel();
          }
        } else {
          entityTrait.subentities.removeModel();
        }
      }
    }
  }

  protected didRemoveNode(key: Value): void {
    const nodeKey = key.stringValue("");
    this.removeChild(nodeKey);
  }

  @MapDownlinkFastener<MetaNodeGroup, Value, Value, AnyValue, AnyValue>({
    consumed: true,
    nodeUri(): Uri {
      return this.owner.metaHostUri;
    },
    laneUri(): Uri {
      return Uri.create(void 0, void 0, UriPath.segment("nodes"), void 0,
                        UriFragment.create(this.owner.nodePath.toString()));
    },
    didUpdate(key: Value, value: Value): void {
      this.owner.didUpdateNode(key, value);
    },
    didRemove(key: Value): void {
      this.owner.didRemoveNode(key);
    },
  })
  readonly nodes!: MapDownlinkFastener<this, Value, Value, AnyValue, AnyValue>;
}
