// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {Class, AnyTiming, Timing} from "@swim/util";
import {Affinity, Property} from "@swim/component";
import {Look, MoodVector, ThemeMatrix} from "@swim/theme";
import {PositionGestureInput, PositionGesture} from "@swim/view";
import {GeoAreaView} from "@swim/map";
import type {Geographic} from "./Geographic";
import {GeographicArea} from "./GeographicArea";
import type {GeographicView} from "./GeographicView";
import type {GeographicViewObserver} from "./GeographicViewObserver";

/** @public */
export class GeographicAreaView extends GeoAreaView implements GeographicView {
  override readonly observerType?: Class<GeographicViewObserver<GeographicAreaView>>;

  @Property({type: Boolean, value: false})
  readonly highlighted!: Property<this, boolean>;

  highlight(timing?: AnyTiming | boolean): void {
    if (!this.highlighted.value) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.willHighlight(timing as Timing | boolean);
      this.highlighted.setValue(true);
      this.onHighlight(timing as Timing | boolean);
      this.didHighlight(timing as Timing | boolean);
    }
  }

  protected willHighlight(timing: Timing | boolean): void {
    this.callObservers("geographicWillHighlight", timing, this);
  }

  protected onHighlight(timing: Timing | boolean): void {
    const accentColor = this.getLook(Look.accentColor);
    if (accentColor !== void 0) {
      if (this.fill.hasAffinity(Affinity.Intrinsic)) {
        const opacity = GeographicAreaView.HighlightedFillOpacity;
        this.fill.setState(accentColor.alpha(opacity), timing, Affinity.Intrinsic);
      }
      if (this.stroke.hasAffinity(Affinity.Intrinsic)) {
        const opacity = GeographicAreaView.HighlightedStrokeOpacity;
        this.stroke.setState(accentColor.alpha(opacity), timing, Affinity.Intrinsic);
      }
    }
  }

  protected didHighlight(timing: Timing | boolean): void {
    this.callObservers("geographicDidHighlight", timing, this);
  }

  unhighlight(timing?: AnyTiming | boolean): void {
    if (this.highlighted.value) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.willUnhighlight(timing as Timing | boolean);
      this.highlighted.setValue(false);
      this.onUnhighlight(timing as Timing | boolean);
      this.didUnhighlight(timing as Timing | boolean);
    }
  }

  protected willUnhighlight(timing: Timing | boolean): void {
    this.callObservers("geographicWillUnhighlight", timing, this);
  }

  protected onUnhighlight(timing: Timing | boolean): void {
    const accentColor = this.getLook(Look.accentColor);
    if (accentColor !== void 0) {
      if (this.fill.hasAffinity(Affinity.Intrinsic)) {
        const opacity = GeographicAreaView.FillOpacity;
        this.fill.setState(accentColor.alpha(opacity), timing, Affinity.Intrinsic);
      }
      if (this.stroke.hasAffinity(Affinity.Intrinsic)) {
        const opacity = GeographicAreaView.StrokeOpacity;
        this.stroke.setState(accentColor.alpha(opacity), timing, Affinity.Intrinsic);
      }
    }
  }

  protected didUnhighlight(timing: Timing | boolean): void {
    this.callObservers("geographicDidUnhighlight", timing, this);
  }

  setState(geographic: Geographic, timing?: AnyTiming | boolean): void {
    if (geographic instanceof GeographicArea) {
      if (timing === void 0 || timing === true) {
        timing = this.getLookOr(Look.timing, false);
      } else {
        timing = Timing.fromAny(timing);
      }
      this.geoPath.setState(geographic.geometry, Affinity.Extrinsic);
      if (geographic.fill !== null) {
        this.fill.setState(geographic.fill, timing, Affinity.Extrinsic);
      } else {
        this.fill.setAffinity(Affinity.Intrinsic);
      }
      if (geographic.stroke !== null) {
        this.stroke.setState(geographic.stroke, timing, Affinity.Extrinsic);
      } else {
        this.stroke.setAffinity(Affinity.Intrinsic);
      }
      if (geographic.strokeWidth !== null) {
        this.strokeWidth.setState(geographic.strokeWidth, timing, Affinity.Extrinsic);
      } else {
        this.strokeWidth.setAffinity(Affinity.Intrinsic);
      }
    }
  }

  protected override onApplyTheme(theme: ThemeMatrix, mood: MoodVector, timing: Timing | boolean): void {
    super.onApplyTheme(theme, mood, timing);
    const accentColor = theme.getOr(Look.accentColor, mood, null);
    if (accentColor !== null) {
      if (this.fill.hasAffinity(Affinity.Intrinsic)) {
        const opacity = this.highlighted.value
                      ? GeographicAreaView.HighlightedFillOpacity
                      : GeographicAreaView.FillOpacity;
        this.fill.setState(accentColor.alpha(opacity), timing, Affinity.Intrinsic);
      }
      if (this.stroke.hasAffinity(Affinity.Intrinsic)) {
        const opacity = this.highlighted.value
                      ? GeographicAreaView.HighlightedStrokeOpacity
                      : GeographicAreaView.StrokeOpacity;
        this.stroke.setState(accentColor.alpha(opacity), timing, Affinity.Intrinsic);
      }
    }
    this.strokeWidth.setState(1, timing, Affinity.Intrinsic);
  }

  @PositionGesture<GeographicAreaView, GeographicAreaView>({
    self: true,
    didMovePress(input: PositionGestureInput, event: Event | null): void {
      const dx = input.dx;
      const dy = input.dy;
      if (dx * dx + dy * dy >= 4 * 4) {
        input.preventDefault();
      }
    },
    didPress(input: PositionGestureInput, event: Event | null): void {
      if (!input.defaultPrevented) {
        this.owner.callObservers("geographicDidPress", input, event, this.owner);
      }
    },
    didLongPress(input: PositionGestureInput): void {
      if (!input.defaultPrevented) {
        this.owner.callObservers("geographicDidLongPress", input, this.owner);
      }
    },
  })
  readonly gesture!: PositionGesture<GeographicAreaView, GeographicAreaView>;

  static fromGeographic(geographic: GeographicArea): GeographicAreaView {
    const view = new GeographicAreaView();
    view.geoPath.setState(geographic.geometry);
    if (geographic.fill !== null) {
      view.fill.setState(geographic.fill);
    }
    if (geographic.stroke !== null) {
      view.stroke.setState(geographic.stroke);
    }
    if (geographic.strokeWidth !== null) {
      view.strokeWidth.setState(geographic.strokeWidth);
    }
    return view;
  }

  /** @internal */
  static FillOpacity: number = 0.1;
  /** @internal */
  static StrokeOpacity: number = 0.2;
  /** @internal */
  static HighlightedFillOpacity: number = 0.25;
  /** @internal */
  static HighlightedStrokeOpacity: number = 0.5;
}
