// Copyright 2015-2021 Swim Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {ModelRef} from "@swim/model";
import {ViewRef} from "@swim/view";
import {HtmlView} from "@swim/dom";
import {ControllerRef} from "@swim/controller";
import type {TableView} from "@swim/table";
import {DrawerView} from "@swim/window";
import {DomainGroup} from "@swim/domain";
import {SurfaceView, MirrorController} from "@swim/prism";
import {CatalogEntityTree} from "./CatalogEntityTree";

/** @public */
export class CatalogController extends MirrorController {
  override isFullBleed(): boolean {
    return false;
  }

  protected initTree(treeView: TableView): void {
    treeView.marginLeft(8)
            .marginRight(8)
            .userSelect("none");
  }

  @ControllerRef<CatalogController, CatalogEntityTree>({
    type: CatalogEntityTree,
  })
  readonly root!: ControllerRef<this, CatalogEntityTree>;

  @ViewRef<CatalogController, SurfaceView>({
    type: SurfaceView,
    didAttachView(surfaceView: SurfaceView): void {
      const domainGroup = this.owner.domains.model;
      if (domainGroup !== null) {
        const entityTree = this.owner.root.insertController();
        if (entityTree !== null) {
          const treeView = entityTree.tree.insertView(surfaceView);
          if (treeView !== null) {
            this.owner.initTree(treeView);
          }
          entityTree.entities.setModel(domainGroup);
        }
      }
    },
    willDetachView(surfaceView: SurfaceView): void {
      const entityTree = this.owner.root.controller;
      if (entityTree !== null) {
        entityTree.tree.removeView();
      }
    },
  })
  override readonly surface!: ViewRef<this, SurfaceView>;

  @ViewRef<CatalogController, HtmlView>({
    type: HtmlView,
  })
  override readonly toolbar!: ViewRef<this, HtmlView>;

  @ViewRef<CatalogController, DrawerView>({
    type: DrawerView,
  })
  override readonly drawer!: ViewRef<this, DrawerView>;

  @ModelRef<CatalogController, DomainGroup>({
    type: DomainGroup,
    didAttachModel(domainGroup: DomainGroup): void {
      const surfaceView = this.owner.surface.view;
      if (surfaceView !== null) {
        const entityTree = this.owner.root.insertController();
        if (entityTree !== null) {
          const treeView = entityTree.tree.insertView(surfaceView);
          if (treeView !== null) {
            this.owner.initTree(treeView);
          }
          entityTree.entities.setModel(domainGroup);
        }
      }
    },
  })
  override readonly domains!: ModelRef<this, DomainGroup>;
}
